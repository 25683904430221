import { Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { UseFormTextField } from "@shared/components/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import {
  createUrl,
  getProgramType,
  isSalesRole,
  onSubmitError,
} from "@shared/helpers/global.helper";
import { systemInfoSchema } from "@shared/schema/request";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import NumberFormat from "@shared/components/NumberFormat";
import { UseFormRadio } from "@shared/components/UserFormRadio";
import { JointApplication } from "@shared/enums/JointApplication";
import { appUserSelector } from "@store/slices/app.slice";
import {
  getTypesAction,
  issueLoanDocTypeSelector,
} from "@store/slices/commonData.slice";
import { toasterService } from "@services/toaster.service";
import { loanService } from "@services/loan.service";
import { RequestDetailsTabs } from "../request-detail/RequestDetail";
import { MESSAGES, QUERY_PARAMS_KEYS } from "@shared/constants/app.constant";
import IssueLoanDocsGuard from "@shared/components/IssueLoanDocsGuard";
import {
  IIssueLoanDocsRequestPayload,
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import SystemInfoText from "@shared/components/SystemInfoText";
import useSungageUtilities from "@hooks/useSungageUtilities";
import { sungageService } from "@services/sungage.service";
import { ISungageContractUploadPayload } from "@shared/interfaces/SungageTypes";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import useSungageEquipment from "@hooks/useSungageEquipment";

const IssueDocs = () => {
  const issueLoanDocType = useAppSelector(issueLoanDocTypeSelector);
  const user = useAppSelector(appUserSelector);
  const dispatch = useAppDispatch();
  const [application, setApplication] = useState<IPortalApplication | null>(
    null
  );
  const [isJoinInvalid, setisJoinInvalid] = useState(false);
  const [contractFile, setContractFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const utilities = useSungageUtilities(application?.installationAddress.state || '');
  const sungageEquipment = useSungageEquipment();

  const history = useHistory();
  let { id } = useParams<{ id: string }>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
    setValue
  } = useForm({
    resolver: yupResolver(systemInfoSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      const data = response?.data?.data;
      setApplication(data);
      setValue("loanType", data.programTypeCode)
      setValue("loanAmount", data.loanOffer.amountFinanced)
      setValue("moduleType", data.systemInfo?.moduleType)
      setValue("panelQuantity", data.systemInfo?.panelQuantity)
      setValue("panelCost", data.loanOffer?.solarCost)
      setValue("batteryCost", data.loanOffer?.batteryCost)
    };
    getRequestData(id);
  }, [id]);

  const panelQuantity = watch("panelQuantity");
  const moduleType = watch("moduleType");
  const batteryQuantity = watch("batteryQuantity");
  const batteryType = watch("batteryType");
  const batteryExpansionQuantity = watch("batteryExpansionQuantity");
  const batteryExpansionType = watch("batteryExpansionType");
  const [panelCost, batteryCost, inverterType, solarRebate, downPayment] =
    watch([
      "panelCost",
      "batteryCost",
      "inverterType",
      "solarRebate",
      "downPayment",
    ]);
  
  const loanAmount = application?.loanOffer.amountFinanced 

  const isSungageProduct = application?.los === ProductLos.SUNGAGE

  useEffect(() => {
    if (moduleType && Number(moduleType) === 0) {
      setValue("panelQuantity", 0);
    }

    if (inverterType && Number(inverterType) === 0) {
      setValue("inverterQuantity", 0)
    }
    
    if (batteryType && Number(batteryType) === 0) {
      setValue("batteryQuantity", 0);
    }
    
    if (batteryExpansionType && Number(batteryExpansionType) === 0) {
      setValue("batteryExpansionQuantity", 0);
    }
  }, [
    setValue,
    inverterType,
    moduleType,
    panelQuantity,
    batteryQuantity,
    batteryType,
    batteryExpansionQuantity,
    batteryExpansionType,
  ]);

  const {
    totalBatterySize,
    totalSolarSystemSize,
    moduleSize,
    batterySize,
    batteryExpansionSize,
  } = useMemo(() => {
    const moduleSize =
      issueLoanDocType?.moduleType.find((i) => i.value === Number(moduleType))
        ?.size || 0;
    const batterySize =
      issueLoanDocType?.batteryType.find((i) => i.value === Number(batteryType))
        ?.size || 0;

    const batteryExpansionSize =
      issueLoanDocType?.batteryExpansionType.find(
        (i) => i.value === Number(batteryExpansionType)
      )?.size || 0;
    
      const totalSolarSystemSize = Number(panelQuantity) * (Number(moduleSize) / 1000);
      const totalBatterySize =
        Number(batteryQuantity) * Number(batterySize) +
        Number(batteryExpansionQuantity) * Number(batteryExpansionSize);

    return {
      totalSolarSystemSize: isNaN(totalSolarSystemSize) ? 0 : totalSolarSystemSize,
      totalBatterySize: isNaN(totalBatterySize) ? 0 : totalBatterySize,
      moduleSize,
      batterySize,
      batteryExpansionSize,
    };
  }, [
    issueLoanDocType?.moduleType,
    issueLoanDocType?.batteryType,
    issueLoanDocType?.batteryExpansionType,
    panelQuantity,
    batteryQuantity,
    batteryExpansionQuantity,
    moduleType,
    batteryType,
    batteryExpansionType,
  ]);

  const selectedModule =
  moduleType && sungageEquipment?.modules !== undefined
    ? sungageEquipment?.modules.find(
        (item) => item.value === Number(moduleType)
      )
    : undefined;

const selectedBattery =
  batteryType && sungageEquipment?.batteries !== undefined
    ? sungageEquipment?.batteries.find(
        (item) => item.value === Number(batteryType)
      )
    : undefined;

const selectedBatteryExtension =
batteryExpansionType && sungageEquipment?.batteryExtensions !== undefined
    ? sungageEquipment?.batteryExtensions.find(
        (item) => item.value === Number(batteryExpansionType)
      )
      : undefined;
  
const selectedInverter =
inverterType && issueLoanDocType?.inverterType !== undefined
    ? issueLoanDocType?.inverterType.find(
        (item) => item.value === Number(inverterType)
      )
    : undefined;

const isPanelsFieldsDisabled =
  totalSolarSystemSize === 0 &&
  moduleSize === 0 &&
  selectedModule?.text === "None";

const isBatteryQuantityDisabled =
  batterySize === 0 &&
  selectedBattery !== undefined &&
  selectedBattery?.text === "None";

const isBatteryExtensionQuantityDisabled =
  selectedBatteryExtension?.text === "None";

const isBatteryCostDisabled =
  totalBatterySize === 0 &&
  isBatteryQuantityDisabled &&
    isBatteryExtensionQuantityDisabled;
  
const isInverterQuantityDisabled = selectedInverter?.label === "_None";

  useEffect(() => {
    if (
      !issueLoanDocType?.batteryType.length ||
      !issueLoanDocType?.inverterType?.length ||
      !issueLoanDocType?.moduleType?.length ||
      !issueLoanDocType?.batteryExpansionType?.length
    ) {
      dispatch(getTypesAction());
    }
  }, [dispatch, issueLoanDocType]);

  // calculate solar cost value
  useEffect(() => {
    if (
      isSungageProduct
    ) {
      const loanAmountValue = Number(loanAmount) || 0
      const solarRebateValue = Number(solarRebate) || 0
      const downPaymentValue = Number(downPayment) || 0
      const batteryCostValue = Number(batteryCost) || 0

      const calculatedSolarCost = loanAmountValue + solarRebateValue + downPaymentValue - batteryCostValue

      setValue("panelCost", calculatedSolarCost, { shouldValidate: true });
    }
  }, [loanAmount, batteryCost, solarRebate, downPayment, isSungageProduct, setValue]);

  useEffect(() => {
    setValue("totalBatterySize", totalBatterySize, { shouldValidate: true });
  }, [totalBatterySize, setValue]);

  const isJoint = () => {
    return (
      application?.installationAddress?.state === "VA" &&
      application?.coApplicant &&
      application?.primaryApplicant
    );
  };

  const onSubmit = async (event: any) => {
    try {
      if (isSungageProduct) {
        if (!contractFile) throw new Error("Please select contract file")
        
        const contractInfo: ISungageContractUploadPayload = {
          moduleType: Number(event.moduleType),
          panelQuantity: event?.panelQuantity ? Number(event.panelQuantity) : null,
          panelCost: Number(event.panelCost),
          panelSize: totalSolarSystemSize,
          batteryType: Number(event.batteryType),
          batteryQuantity: event.batteryQuantity ? Number(event.batteryQuantity) : null,
          batteryCost: event?.batteryCost ? Number(event.batteryCost) : null,
          batterySize: totalBatterySize,
          batteryExpansionType: Number(event.batteryExpansionType),
          batteryExpansionQuantity: event?.batteryExpansionQuantity ? Number(event.batteryExpansionQuantity) : null,
          inverterType: Number(event.inverterType),
          inverterQuantity: event?.inverterQuantity ? Number(event.inverterQuantity) : null,
          estimatedYear1Production: Number(event.estimatedYear1Production),
          utilityCompany: event.utilityCompany,
          downPayment: Number(event.downPayment),
          certifyIndustrialBestPractice: event.certifyIndustrialBestPractice,
          certifyShareWithHomeOwner: event.certifyIndustrialBestPractice,
        };

        const payload = new FormData();
        payload.append("contract", contractFile!);
        payload.append("contractInfo", JSON.stringify(contractInfo));

        const contractRes = await sungageService.uploadContract(id, payload);

        if (contractRes) {
          toasterService.success("Contract uploaded successfully");

          history.push(
            createUrl(
              `/request-detail/${id}?${QUERY_PARAMS_KEYS.REQUEST_DETAILS_TAB}=${
                isSalesRole(user?.role)
                  ? RequestDetailsTabs.noticeToProceed
                  : RequestDetailsTabs.documents
              }`
            )
          );
        }
      } else {
        let xVaSurvivorshipElection = 0;
        if (isJoint()) {
          if (!event.xVaSurvivorshipElection) {
            setisJoinInvalid(true);
            return;
          }
          setisJoinInvalid(false);
          xVaSurvivorshipElection =
            event.xVaSurvivorshipElection ===
            JointApplication.JointWithSurvivorship
              ? 1
              : 2;
        }
        // TODO: Remove this after testing on Demo
        // const model = {
        //   content: {
        //     xmoduleType: event.moduleType,
        //     xpanelQuantity: event.moduleType ? Number(event.panelQuantity) : 0,
        //     xinverterType: event.inverterType,
        //     xInverterQuantity: event.inverterType
        //       ? Number(event.inverterQuantity)
        //       : event.inverterType,
        //     xbatteryType: event.batteryType,
        //     xbatteryQuantity: event.batteryType
        //       ? Number(event.batteryQuantity)
        //       : 0,
        //     xBatteryExpansionType: event.batteryExpansionType,
        //     xBatteryExpansionQuantity: event.batteryExpansionType
        //       ? Number(event.batteryExpansionQuantity)
        //       : 0,
        //     xOtherText: event.other,
        //     xQuantityofOtherItems: event.other ? Number(event.otherQuantity) : 0,

        //     xTotalSystemSizeDecimal: totalSolarSystemSize,
        //     xmoduleWattageDecimal: moduleSize,
        //     xbatterySize: batterySize,
        //     xBatteryExpansionSize: batteryExpansionSize,
        //     xTotalBatterySize: totalBatterySize,
        //     xVaSurvivorshipElection,
        //   },
        //   transformName: "updateContractPrep",
        // };
        const model: IIssueLoanDocsRequestPayload = {
          moduleType: Number(event.moduleType),
          panelQuantity: event.moduleType ? Number(event.panelQuantity) : 0,
          inverterType: Number(event.inverterType),
          inverterQuantity: event.inverterType
            ? Number(event.inverterQuantity)
            : event.inverterType,
          batteryType: Number(event.batteryType),
          batteryQuantity: event.batteryType
            ? Number(event.batteryQuantity)
            : 0,
          batteryExpansionType: Number(event.batteryExpansionType),
          batteryExpansionQuantity: event.batteryExpansionType
            ? Number(event.batteryExpansionQuantity)
            : 0,
          otherText: event.other,
          quantityofOtherItems: event.other ? Number(event.otherQuantity) : 0,

          totalSolarSystemSize,
          moduleSize,
          batterySize,
          batteryExpansionSize,
          totalBatterySize,
          vaSurvivorshipElection: xVaSurvivorshipElection,
        };

        const response = await loanService.issueLoanDocs(id, model);
        if (response) {
          history.push(
            createUrl(
              `/request-detail/${id}?${QUERY_PARAMS_KEYS.REQUEST_DETAILS_TAB}=${
                isSalesRole(user?.role)
                  ? RequestDetailsTabs.noticeToProceed
                  : RequestDetailsTabs.documents
              }`
            )
          );
        }
      }
    } catch (error: any) {
      console.error(error);
      toasterService.error(error?.message || MESSAGES.DEFAULT_ERROR);
    }
  };

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {

    const file =
      event.target.files instanceof FileList ? event.target.files[0] : null;

    if (!file) return;

    setContractFile(file);
  };

  return (
    <Col xs={12} md={10} lg={7} className="mx-auto preQual-request">
      <IssueLoanDocsGuard application={application} />

      <div className="mt-3">
        <h3 className="h3 text-primary-dark">
          {isSungageProduct ? "Contract Details" : "Issue Loan Documents"}
        </h3>
        <hr className="bg-primary-dark height-2px opacity-1" />
        <div className="mt-4 loan-info-docs">
          {isSungageProduct && (
            <p className="mb-4">
              Lender requires all of the details provided at contract submittal.
              Ensure all information below matches the details of the contract
              exactly.
            </p>
          )}
          <h4 className="font-bold mb-4">
            {isSungageProduct
              ? "Loan Information"
              : "Loan Information for Docs"}
          </h4>
          <p>
            Loan Product:{" "}
            <span className="text-primary">
              {application?.programTypeCode
                ? getProgramType(application?.programTypeCode)
                : null}
            </span>
          </p>
          <p>
            Loan Amount:{" "}
            <span className="text-primary">
              {" "}
              <NumberFormat
                amount={application?.loanOffer?.amountFinanced}
              />{" "}
            </span>
          </p>
        </div>
      </div>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onSubmitError())}
      >
        <div className="mt-5">
          <h3 className="h3 text-primary-dark">System Information</h3>
          <hr className="bg-primary-dark height-2px opacity-1" />
          <Row className="mt-4">
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Module Type</h4>
              <UseFormSelect
                list={
                  isSungageProduct
                    ? sungageEquipment?.modules
                    : issueLoanDocType?.moduleType
                }
                control={control}
                name="moduleType"
                placeholder="Module Type"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Panel Quantity</h4>
              <UseFormTextField
                placeholder="Panel Quantity"
                type="number"
                control={control}
                name="panelQuantity"
                disabled={isPanelsFieldsDisabled}
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Inverter Type</h4>
              <UseFormSelect
                list={issueLoanDocType?.inverterType}
                control={control}
                name="inverterType"
                placeholder="Inverter Model Number"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Inverter Quantity</h4>
              <UseFormTextField
                placeholder="Inverter Quantity"
                type="number"
                control={control}
                name="inverterQuantity"
                disabled={isInverterQuantityDisabled}
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Battery Type</h4>
              <UseFormSelect
                list={
                  isSungageProduct
                    ? sungageEquipment?.batteries
                    : issueLoanDocType?.batteryType
                }
                control={control}
                name="batteryType"
                placeholder="Battery Model Number"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Battery Quantity</h4>
              <UseFormTextField
                placeholder="Battery Quantity"
                type="number"
                control={control}
                name="batteryQuantity"
                disabled={isBatteryQuantityDisabled}
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Battery Expansion Type</h4>
              <UseFormSelect
                list={
                  isSungageProduct
                    ? sungageEquipment?.batteryExtensions
                    : issueLoanDocType?.batteryExpansionType
                }
                control={control}
                name="batteryExpansionType"
                placeholder="Battery Expansion Type "
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <h4 className="font-bold">Battery Expansion Quantity </h4>
              <UseFormTextField
                placeholder="Battery Expansion Quantity"
                type="number"
                control={control}
                name="batteryExpansionQuantity"
                disabled={isBatteryExtensionQuantityDisabled}
              />
            </Col>
            {!isSungageProduct && (
              <>
                <Col xs={12} md={6} className="mt-3">
                  <h4 className="font-bold">Other</h4>
                  <UseFormTextField
                    placeholder="other"
                    control={control}
                    name="other"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <h4 className="font-bold">Other Quantity </h4>
                  <UseFormTextField
                    placeholder="Other Quantity"
                    type="number"
                    control={control}
                    name="otherQuantity"
                  />
                </Col>
              </>
            )}
          </Row>

          <Row className="system-size mt-4">
            <Col xs={12}>
              <h4 className="font-bold">System Size </h4>
            </Col>
            <Col xs={12} md={6} className="">
              <p className="">
                Total Solar System Size:{" "}
                <NumberFormat
                  amount={totalSolarSystemSize}
                  isCurrency={false}
                  symbol=" kW"
                  minimumFractionDigits={0}
                />
              </p>
            </Col>
            <Col xs={12} md={6} className="">
              <p className="">
                Module Wattage:{" "}
                <NumberFormat
                  amount={moduleSize}
                  isCurrency={false}
                  symbol=" W"
                  minimumFractionDigits={0}
                />
              </p>
            </Col>
            <Col xs={12} md={6} className="">
              <p className="">
                Total Battery Size:{" "}
                <NumberFormat
                  amount={totalBatterySize}
                  isCurrency={false}
                  symbol=" kWh"
                  minimumFractionDigits={0}
                />
                <UseFormTextField
                  control={control}
                  name="totalBatterySize"
                  type="hidden"
                  controlledValue={totalBatterySize}
                />
              </p>
            </Col>
            <Col xs={12} md={6} className="">
              <p className="">
                Unit Battery Size:{" "}
                <NumberFormat
                  amount={batterySize}
                  isCurrency={false}
                  symbol=" kWh"
                  minimumFractionDigits={0}
                />
              </p>
            </Col>
          </Row>

          {isSungageProduct && (
            <>
              <Row className="mt-4">
                <h4 className="font-bold">Estimated Year 1 Production</h4>
                <Col xs={12} md={6}>
                  <UserFormCurrencyField
                    control={control}
                    name="estimatedYear1Production"
                    type="number"
                    placeholder="Estimated Year 1 Production"
                    disableAbbreviations={true}
                    suffix=" kWh"
                    prefix=""
                    disableGroupSeparators={true}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <h3 className="h3 text-primary-dark">Additional Information</h3>
                <hr className="bg-primary-dark height-2px opacity-1" />
                <Row className="mt-4">
                  <h4 className="font-bold">System Cost</h4>
                  <Col xs={12}>
                    <Row className="align-items-center">
                      <Col xs={12} md={6}>
                        <Row className="align-items-center">
                          <Col md={6} className="mt-3 mt-md-0">
                            <label className="form-check-label">
                              Battery Cost:
                            </label>
                          </Col>
                          <Col>
                            <UserFormCurrencyField
                              placeholder="Battery Cost"
                              control={control}
                              name="batteryCost"
                              type="number"
                              disabled={isBatteryCostDisabled}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mt-3 mt-md-0">
                        <SystemInfoText
                          label="Solar Cost"
                          value={`$${panelCost}`}
                        />
                        <UseFormTextField
                          control={control}
                          name="panelCost"
                          type="hidden"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Col xs={12} md={6} className="mt-3">
                  <h4 className="font-bold">Down Payment</h4>
                  <UserFormCurrencyField
                    placeholder="Down Payment"
                    type="number"
                    control={control}
                    name="downPayment"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <h4 className="font-bold">Utility Company</h4>
                  <UseFormSelect
                    list={utilities.map((u) => ({ value: u, text: u }))}
                    control={control}
                    name="utilityCompany"
                    placeholder="Select Utility Company"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <h4 className="font-bold">Certification</h4>
                  <UseFormCheck
                    control={control}
                    controlName="certifyIndustrialBestPractice"
                    content="I certify that this production estimate adheres to industry best practices."
                  />
                  <div className="mt-2">
                    <UseFormCheck
                      control={control}
                      controlName="certifyShareWithHomeOwner"
                      content="I certify that this production estimate has been shared with the homeowner."
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <div>
                  <h3 className="h3 text-primary-dark mt-4">Attach Document</h3>
                  <hr className="bg-primary-dark opacity-1 height-2px" />
                  <div className="d-flex justify-content-between gap-3 align-items-center mt-3">
                    <div>
                      <h4 className="font-bold-800 mb-2">
                        Upload Solar Contract
                      </h4>
                      {contractFile && (
                        <p className="font-14 my-1 break-word">{`${contractFile?.name.substring(0,20)}...`}</p>
                      )}
                      <p
                        className={`${
                          contractFile ? "text-success" : "text-warning"
                        } font-14 my-0`}
                      >
                        {contractFile ? "Ready" : "Required"}
                      </p>
                    </div>
                    <div className="d-flex align-self-center text-center">
                      <button
                        className="btn btn-light width-110px"
                        onClick={() => {
                          fileInputRef?.current?.click();
                        }}
                        type="button"
                      >
                        Upload File
                      </button>
                      <input
                        accept=".pdf"
                        className="d-none"
                        id={`upload-sungage-solar-contract`}
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
              </Row>
            </>
          )}

          {isJoint() && (
            <div className="mt-5">
              <h3 className="h3 text-primary-dark">New Account Elections</h3>
              <hr className="bg-primary-dark height-2px opacity-1" />
              <Row className="mt-4">
                <Col xs={12}>
                  <h4 className="font-bold">
                    Credit Union Share Survivorship Election
                  </h4>
                  <p>
                    As part of the loan origination process, a share savings
                    account will be opened with the credit union originating the
                    borrowers’ solar loan. As Virigina residents, they may elect
                    to have this account opened as joint with survivorship or
                    joint with no survivorship. Please specify the borrowers’
                    election:
                  </p>
                </Col>
                <Col>
                  <div className="d-flex align-items-center">
                    <div className="ml-3 d-flex flex-column">
                      <UseFormRadio
                        control={control}
                        elementName="xVaSurvivorshipElection-group"
                        controlName="xVaSurvivorshipElection"
                        content={JointApplication.JointWithSurvivorship}
                        checked={false}
                      />
                      <UseFormRadio
                        control={control}
                        elementName="xVaSurvivorshipElection-group"
                        controlName="xVaSurvivorshipElection"
                        content={JointApplication.JointWithNoSurvivorship}
                        checked={false}
                      />
                      {isJoinInvalid && (
                        <p className="text-danger">Field is required</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <div className="mt-5 d-flex justify-content-center mb-5">
            <Link to={`/request-detail/${id}`}>
              <Button variant="outline-primary" type="button" className="me-4">
                Cancel
              </Button>
            </Link>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSungageProduct ? 'Submit Contract' : 'Issue Loan Documents'}
            </Button>
          </div>
        </div>
      </form>
    </Col>
  );
};

export default IssueDocs;
