import {
  ISungageDisclosures,
  ISungageEquipmentResponse,
} from "@shared/interfaces/SungageTypes";
import { networkService } from "./network-service";

class SungageService {
  async getSungageDisclosures() {
    const { data } = await networkService.get<{
      data: ISungageDisclosures;
      errors: any;
      message: string;
    }>("application/sungage-disclosures");

    return data;
  }

  async getSungageEquipment() {
    const { data } = await networkService.get<{
      data: ISungageEquipmentResponse;
      errors: any;
      message: string;
    }>("application/sungage-equipment");

    return data;
  }

  async getSungageUtilities(state:string) {
    const { data } = await networkService.get<{
      data: string[];
      errors: any;
      message: string;
    }>(`application/sungage-utilities?state=${state}`);

    return data;
  }

  async uploadContract(id: string, payload: any) {
    const { data } = await networkService.post<{
      data: any;
      errors: any;
      message: string;
    }>(`application/${id}/upload-sungage-contract`, payload);

    return data;
  }
}

export const sungageService = new SungageService();
