import { Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useHistory, useParams } from "react-router-dom";
import { getProductsList, onSubmitError } from "@shared/helpers/global.helper";
import {
  modifyLoanAction,
  prequalModifyLoanAction,
} from "@store/slices/loan.slice";
import { useEffect, useState } from "react";
import { modifyLoanSchema } from "@shared/schema/request";
import { loanService } from "@services/loan.service";
import {
  HOME_OWNERSHIP_LIST,
  INSTALLATION_RESIDENCY,
  PROPERTY_TYPE_LIST,
} from "@shared/constants/app.constant";
import ApplicationGuard from "@shared/components/ApplicationGuard";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import { UseFormTextField } from "@shared/components/TextInput";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import { appUserSelector } from "@store/slices/app.slice";
import { useAuthUserContext } from "@contexts/AuthContext";
import {
  getXPrequalProducts,
  isPrequalApplication,
  isSungageLoanProduct,
} from "@shared/helpers/application.helper";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import {
  IModifyPortalFullApplication,
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import useInstallationAddress from "@hooks/useInstallationAddress";

const ModifyLoan = () => {
  const dispatch = useAppDispatch();
  const [application, setApplication] = useState<IPortalApplication | null>(
    null
  );
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { authUserStatus } = useAuthUserContext();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(modifyLoanSchema),
  });
  const user = useAppSelector(appUserSelector);

  const isFullApplication = watch("isFull");

  const { isInstallationAddressUpdated, setInitialInstAddressFields } =
    useInstallationAddress({
      currentAddressFields: {
        addressLine: watch("xInstallationAddressLine"),
        city: watch("xInstallationCity"),
        state: watch("xInstallationState"),
        zipCode: watch("xInstallationZipCode"),
      },
    });

  const onSubmit = (event: any) => {
    if (!application || !application?.losAppNumber) {
      return;
    }

    const path = isFullApplication
      ? "/new-application/confirmation"
      : "/pre-qualification/confirmation";

    const { xPrequalProductsSelected, xPrequalProductsAll } =
      getXPrequalProducts(user, event.loanType, !!!isFullApplication);
    const xprogramtype = Number(event?.loanType);

    if (isFullApplication) {
      const modifyLoanPortalAppPayload: IModifyPortalFullApplication = {
        productLos: isSungageProduct ? ProductLos.SUNGAGE : ProductLos.DL4,
        xprogramtype: Number(event?.loanType),
        loanAmount: parseInt(event.loanAmount),
        isInstallationAddressDifferent:
          !!application.installationAddress.isInstallationAddressDifferent,
        installationAddress: {
          addressLine: event.xInstallationAddressLine,
          city: event.xInstallationCity,
          state: event.xInstallationState,
          zipCode: event.xInstallationZipCode,
          installationHomeOwner: Number(event.installationHomeOwner), // [xStatedInstallationHomeOwner]
          installationResidency: Number(event.installationResidency) || 1,
          reverseMortgage: isSungageProduct
            ? event.reverseMortgage === "Yes"
            : null,
          mortgagePayment: isSungageProduct
            ? Number(event.mortgagePayment)
            : null,
          propertyType: Number(event.propertyType),
        },
        isInstallationAddressUpdated,
      };

      dispatch(
        modifyLoanAction({
          model: modifyLoanPortalAppPayload,
          id,
          history,
          path,
          isInstallationAddressUpdated,
        })
      );
    } else {
      const isMultiPrequal = event.isMultiPrequal;

      dispatch(
        prequalModifyLoanAction({
          appNumber: id,
          history,
          loanInfo: {
            losAppNumber: application.losAppNumber,
            programTypeCode: application.programTypeCode,
            productLos: ProductLos.DL4,
            prequalProducts: xPrequalProductsSelected,
          },
          path,
          isMultiPrequal,
          xprogramtype,
          xPrequalProducts: xPrequalProductsAll,
        })
      );
    }
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);
      const data = response?.data?.data;
      setApplication(data);
      reset({
        isFull: !isPrequalApplication(data),
        loanAmount: data?.loanOffer?.amountFinanced,
        loanType: data?.programTypeCode,
        installationHomeOwner:
          data?.installationAddress?.installationHomeOwner || undefined,
        propertyType: data?.installationAddress?.propertyType || undefined,
        xInstallationAddressLine:
          data?.installationAddress?.addressLine || undefined,
        xInstallationCity: data?.installationAddress?.city || undefined,
        xInstallationState: data?.installationAddress?.state || undefined,
        xInstallationZipCode: data?.installationAddress?.zipCode || undefined,
        installationResidency:
          data?.installationAddress?.installationResidency ||
          INSTALLATION_RESIDENCY[0].value,
      });
      setInitialInstAddressFields({
        xInstallationAddressLine: data?.installationAddress?.addressLine || "",
        xInstallationCity: data?.installationAddress?.city || "",
        xInstallationState: data?.installationAddress?.state || "",
        xInstallationZipCode: data?.installationAddress?.zipCode || "",
      });
    };
    getRequestData(id);
  }, [reset, id]);

  const loanType = watch("loanType");

  const isSungageProduct = isSungageLoanProduct(loanType);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onSubmitError())}
    >
      <ApplicationGuard
        application={application}
        useCase={"modifyApplication"}
      />
      <Col xs={12} md={10} lg={7} className="mx-auto mt-3 fullapp-request">
        <h3 className="h3 text-primary">Modify Loan Information</h3>
        <hr className="text-primary height-2px" />

        <Row className="mt-4">
          <Col xs={12}>
            <h4 className="font-bold">Loan Information</h4>
          </Col>
          <Col xs={12} md={6} className="mt-3 ">
            <UseFormSelect
              list={getProductsList(
                authUserStatus,
                user?.role,
                user?.userDetails?.accessManagement,
                "modify",
                !!!isFullApplication,
                application?.programTypeCode
              )}
              control={control}
              name="loanType"
              placeholder="Select Loan Product"
              disabled={isFullApplication}
            />
          </Col>
          {isFullApplication && (
            <Col xs={12} md={6} className="mt-3 ">
              <UserFormCurrencyField
                placeholder="Loan Amount"
                control={control}
                name="loanAmount"
                type="number"
              />
            </Col>
          )}
        </Row>
        {isFullApplication === false && (
          <Row className="mt-4">
            <Col>
              <UseFormCheck
                control={control}
                controlName="isMultiPrequal"
                content="If I am not prequalified for the above product, try to prequalify me for other products."
              />
            </Col>
          </Row>
        )}
        {isFullApplication && (
          <>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Home Ownership</h4>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="">
                <UseFormSelect
                  list={HOME_OWNERSHIP_LIST}
                  control={control}
                  name="installationHomeOwner"
                  placeholder="Who owns the property?"
                />
              </Col>
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <UseFormSelect
                  list={PROPERTY_TYPE_LIST}
                  control={control}
                  name="propertyType"
                  placeholder="What type of property is it?"
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12} className="mt-3">
                <h4 className="font-bold">Installation Address</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <GoogleAutoComplete
                  placeholder="Street"
                  control={control}
                  name="xInstallationAddressLine"
                  onSelect={(address) => {
                    setValue("xInstallationAddressLine", address.street);
                    setValue("xInstallationCity", address.city);
                    setValue("xInstallationState", address.state);
                    setValue("xInstallationZipCode", address.zipCode);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UseFormTextField
                  placeholder="City"
                  control={control}
                  name="xInstallationCity"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="State"
                  control={control}
                  name="xInstallationState"
                />
              </Col>
              <Col xs={6} md={3} className="mt-3">
                <UseFormTextField
                  placeholder="Zip Code"
                  control={control}
                  name="xInstallationZipCode"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold">Installation Address Residency</h4>
              </Col>
              <Col xs={12} className="mt-3">
                <UseFormSelect
                  list={INSTALLATION_RESIDENCY}
                  control={control}
                  name="installationResidency"
                  defaultValue={INSTALLATION_RESIDENCY[0].value}
                  placeholderValue={0}
                  placeholder="What is the residency type of the installation address?"
                />
              </Col>
            </Row>
          </>
        )}
        <div className="mt-5 d-flex justify-content-center mb-5">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </Col>
    </form>
  );
};

export default ModifyLoan;
