import { ChangeEvent } from "react";
import { DownloadDocumentPayload } from "@store/slices/loan.slice";
import { StipulationCard } from "./StipulationCard";
import {
  IDocumentStipulation,
  IPortalApplication,
} from "@shared/interfaces/PortalApplicationTypes";

export const Stipulations = ({
  requestDetail,
  stipulations,
  uploadingDocType,
  errorMessage,
  docId,
  onDocumentInputFileChange,
  downloadDocument,
  canBeModified,
}: {
  requestDetail: IPortalApplication | undefined;
  stipulations: Array<IDocumentStipulation>;
  uploadingDocType: string;
  errorMessage: string;
  docId: string;
  onDocumentInputFileChange: (
    event: ChangeEvent<HTMLInputElement>,
    docId: string,
    callback?: () => void
  ) => Promise<void>;
  downloadDocument: (value: DownloadDocumentPayload) => void;
  canBeModified: boolean;
}) => {
  return (
    <div>
      <h3 className="h3 text-primary-dark">Stipulations</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      <div>
        {stipulations.length > 0 ? (
          <h4 className="font-bold-800 mt-4">
            Please provide following stipulations:
          </h4>
        ) : (
          <h4 className="font-bold-800 mt-4">
            There are no stipulations required for this loan
          </h4>
        )}
        {stipulations.map((item, i) => (
          <StipulationCard
            key={i}
            requestDetail={requestDetail}
            stipulation={item}
            onDocumentInputFileChange={onDocumentInputFileChange}
            docId={docId}
            errorMessage={errorMessage}
            uploadingDocType={uploadingDocType}
            downloadDocument={downloadDocument}
            canBeModified={canBeModified}
          />
        ))}
      </div>
    </div>
  );
};
