import React from "react";
import { ConditionalRender } from "@shared/components/ConditionalRender";
import NumberFormat from "@shared/components/NumberFormat";
import { Link } from "react-router-dom";
import { NA } from "@shared/constants/app.constant";
import { getValueIfNotNull, maskMobile } from "@shared/helpers/global.helper";
import { Button, Col, Row } from "react-bootstrap";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export const CoBorrowerData = ({
  data,
  id,
  isLoading,
  isPrequal,
}: {
  data: IPortalApplication | undefined;
  id: string;
  isLoading: boolean;
  isPrequal: boolean;
}) => {
  const coBorrowerData = data?.coApplicant;
  const coBorrowerAddress = data?.coApplicant?.currentAddress;
  const primaryData = data?.primaryApplicant;

  return (
    <>
      <h3 className="h3 text-primary-dark">CoBorrower Information</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      {coBorrowerData ? (
        <>
          <Row className="mt-3">
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Demographics</h4>
              <p>{coBorrowerData?.fullName}</p>
              <p>
                Citizenship:&nbsp;
                <ConditionalRender
                  value={primaryData?.isUSCitizen ? "Yes" : "No"}
                />
              </p>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Contact</h4>
              <p>
                Mobile:&nbsp;
                <ConditionalRender
                  value={maskMobile(
                    getValueIfNotNull(coBorrowerData?.mobilePhone)
                  )}
                />
              </p>
              <p>
                Email: <ConditionalRender value={coBorrowerData?.email} />
              </p>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Income and Employment</h4>
              <p>
                Gross Annual Income:&nbsp;
                <NumberFormat
                  amount={coBorrowerData?.employment?.grossIncome}
                />
              </p>
              <p>
                Employer:&nbsp;
                <ConditionalRender
                  value={coBorrowerData?.employment?.employerName}
                />
              </p>
              <p>
                Time On Job:&nbsp;
                {coBorrowerData?.employment?.yearsEmployed
                  ? coBorrowerData?.employment?.yearsEmployed + " Years"
                  : NA}
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} sm={6} md={4}>
              <h4 className="font-bold-800">Current Address</h4>
              {coBorrowerAddress ? (
                <p>
                  {coBorrowerAddress?.addressLine + ", "}
                  {coBorrowerAddress?.city + ", "}
                  {coBorrowerAddress?.state + " "}
                  {coBorrowerAddress?.postalCode}
                </p>
              ) : (
                <p>{NA}</p>
              )}
            </Col>
          </Row>
          {id !== "new" &&
            !isLoading &&
            !!data?.portalAccessibility.modifyBorrower && (
              <Row>
                <Col xs={12} className="d-flex justify-content-end">
                  <Link
                    to={
                      isPrequal
                        ? `/pre-qualification/co-borrower/${id}?type=edit`
                        : `/new-application/co-borrower/${id}?type=edit`
                    }
                  >
                    <Button variant="outline-primary-dark">
                      Modify Borrower
                    </Button>
                  </Link>
                </Col>
              </Row>
            )}
        </>
      ) : (
        <>
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-center mt-4 ">
            <div>
              <p className="">There is no borrower associated with loan</p>
            </div>
            {id !== "new" &&
              !isLoading &&
              !!data?.portalAccessibility.addBorrower && (
                <div>
                  <Link
                    to={
                      isPrequal
                        ? `/pre-qualification/co-borrower/${id}`
                        : `/new-application/co-borrower/${id}`
                    }
                  >
                    <Button variant="outline-primary-dark">Add Borrower</Button>
                  </Link>
                </div>
              )}
          </div>
        </>
      )}
    </>
  );
};
