import { useAppDispatch, useAppSelector } from "@store/hooks";
import { DownloadDocumentPayload } from "@store/slices/loan.slice";
import { DocumentCard } from "./DocumentCard";
import { useEffect } from "react";
import {
  appLendersSelector,
  appUserSelector,
  getLendersAction,
} from "@store/slices/app.slice";
import { UserRole } from "@shared/interfaces/User";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export const AdditionalDocumentation = ({
  requestDetail,
  downloadDocument,
}: {
  requestDetail: IPortalApplication | undefined;
  downloadDocument: (value: DownloadDocumentPayload) => void;
}) => {
  const dispatch = useAppDispatch();
  const lendersData = useAppSelector(appLendersSelector);
  const user = useAppSelector(appUserSelector);

  useEffect(() => {
    if (!lendersData.length && user?.role === UserRole.financialInstitution) {
      // To fetch if the Lenders data is not fetched
      dispatch(getLendersAction(null));
    }
  }, [dispatch, lendersData.length, user?.role]);

  const filteredAdditionalDocumentation =
    requestDetail?.documentsMetadata?.additionalDocuments || [];

  return (
    <div>
      <h3 className="h3 text-primary-dark">Additional Documentation</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      {filteredAdditionalDocumentation &&
      filteredAdditionalDocumentation.length > 0 ? (
        <h4 className="font-bold-800 mt-4">
          Credit Reports and Other Supporting Documents Include:
        </h4>
      ) : (
        <h4 className="font-bold-800 mt-4">
          There are no additional documents for the application.
        </h4>
      )}

      <div>
        {filteredAdditionalDocumentation.map((item, i) => (
          <DocumentCard
            documentMetadata={item}
            key={i}
            requestDetail={requestDetail}
            downloadDocument={downloadDocument}
          />
        ))}
      </div>
    </div>
  );
};
