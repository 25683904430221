import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { StatusSquare } from "@shared/components/StatusSquare";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { RequestApplication } from "./components/RequestApplication";
import NoticeToProceed from "./components/Documents";
import ReviwerDocuments from "./components/ReviwerDocuments";
import Funding from "./components/Funding";
import Inspections from "./components/Inspections";
import { Loan } from "./components/Loan";
import { appSelector, appUserSelector } from "@store/slices/app.slice";
import { loanService } from "@services/loan.service";
import {
  fileSizeValid,
  getPortalApplicationStatusLabel,
  getStatusBackground,
  isReviewerRole,
  isSalesRole,
  useQuery,
} from "@shared/helpers/global.helper";
import {
  DownloadDocumentPayload,
  documentDownloadingSelector,
  downloadDocumentAction,
  setDraftDetail,
  setIsDocumentUploading,
} from "@store/slices/loan.slice";
import {
  getIdentificationStip,
  getInstallationPhotosStip,
  getPtoDocumentStip,
  getRequiredStipulations,
  getSolarContractStip,
  isIdentificationUploaded,
  isInstallationPhotosUploaded,
  isIssueDocsUploaded,
  isPtoDocUploaded,
  isSolarContractUploaded,
  isStipulationUploaded,
} from "@shared/helpers/documents.helper";
import {
  APPLICATION_STEPS,
  MESSAGES,
  QUERY_PARAMS_KEYS,
} from "@shared/constants/app.constant";
import { IStipulation } from "@shared/interfaces/Application";
import { UserRole } from "@shared/interfaces/User";
import { toasterService } from "@services/toaster.service";
import { Decision } from "./components/Decision";
import { LoadingSpinner } from "@shared/components/Loader";
import {
  getTypesAction,
  issueLoanDocTypeSelector,
} from "@store/slices/commonData.slice";
import clsx from "clsx";
import { QcPassFail } from "./components/general/QcPassFail";
import { isPrequalApplication } from "@shared/helpers/application.helper";
import { InstallerInfo } from "./components/application/InstallerInfo";
import { UpdateBookingConfirmation } from "./components/general/UpdateBookingConfirmation";
import {
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import { useAuthUserContext } from "@contexts/AuthContext";
import { PortalDecisionStatusLabel } from "@shared/enums/ApplicationStatus";
import TaskItem from "@shared/components/TaskItem";

export enum RequestDetailsTabs {
  application = "application",
  noticeToProceed = "noticeToProceed",
  documents = "documents",
  loan = "loan",
  decision = "decision",
  funding = "funding",
  inspections = "inspections",
}

const RequestDetail = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { authUserStatus } = useAuthUserContext();
  const issueLoanDocType = useAppSelector(issueLoanDocTypeSelector);
  const [requestDetail, setRequestDetail] = useState<IPortalApplication>();
  const [stipulationUploaded, setStipulationUploaded] = useState(false);
  const [identificationDocUploaded, setIdentificationDocUploaded] =
    useState(false);
  const [solarContractUploaded, setSolarContractUploaded] = useState(false);
  const [issueDocsUploaded, setIssueDocsUploaded] = useState(false);
  const [installationPhotosUploaded, setInstallationPhotosUploaded] =
    useState(false);
  const [ptoDocumentUploaded, setPtoDocumentUploaded] = useState(false);
  const [activeTab, setActiveTab] = useState<RequestDetailsTabs>(
    RequestDetailsTabs.application
  );
  // const [stipulations, setStipulations] = useState<Array<IStipulation>>([]);
  // const [uploadIdStip, setUploadIdStip] = useState<Array<IStipulation>>([]);
  // const [solarContractStip, setSolarContractStip] = useState<IStipulation>();
  // const [installationPhotoStip, setInstallationPhotoStip] =
  //   useState<IStipulation>();
  // const [ptoDocumentUploadedStip, setPtoDocumentUploadedStip] =
  //   useState<IStipulation>();
  const [docId, setDocId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadingDocType, setUploadingDocType] = useState("");
  const user = useAppSelector(appUserSelector);

  let query = useQuery();
  const appSlice = useAppSelector(appSelector);
  let { id } = useParams<{ id: string }>();
  const loanSlice = useAppSelector((state) => state.loan);
  const isDocumentDownloading = useAppSelector(documentDownloadingSelector);
  const isSungageProduct = requestDetail?.los === ProductLos.SUNGAGE;

  const reload = async (data: any) => {
    setStipulationUploaded(
      isStipulationUploaded(data?.appStipulations, !isPrequalApplication(data))
    );
    setIdentificationDocUploaded(
      isIdentificationUploaded(
        data?.appStipulations,
        !isPrequalApplication(data)
      )
    );
    setSolarContractUploaded(isSolarContractUploaded(data?.appStipulations));
    setInstallationPhotosUploaded(
      isInstallationPhotosUploaded(data?.appStipulations)
    );
    setPtoDocumentUploaded(isPtoDocUploaded(data?.appStipulations));
    setIssueDocsUploaded(isIssueDocsUploaded(data?.appStipulations));
  };

  const getDetail = async () => {
    if (id === "new") return;
    const response = await loanService.getRequestById(id);
    const application = response?.data?.data;
    const param = query.get(
      QUERY_PARAMS_KEYS.REQUEST_DETAILS_TAB
    ) as RequestDetailsTabs;
    if (param && Object.values(RequestDetailsTabs).includes(param)) {
      switch (param) {
        case RequestDetailsTabs.noticeToProceed: {
          if (application?.portalAccessibility?.enableNtpTab)
            setActiveTab(param);
          break;
        }
        case RequestDetailsTabs.funding: {
          if (application?.portalAccessibility?.enableFundingTab)
            setActiveTab(param);
          break;
        }
        case RequestDetailsTabs.inspections: {
          if (application?.portalAccessibility?.enableInspectionsTab)
            setActiveTab(param);
          break;
        }
        case RequestDetailsTabs.loan: {
          if (application?.portalAccessibility?.enableLoanTab)
            setActiveTab(param);
          break;
        }
        default:
          setActiveTab(param);
      }
    }
    setRequestDetail(application);

    reload(application);
  };
  const goBack = () => {
    dispatch(setDraftDetail(null));
    history.push(`/pipelines`);
  };

  const downloadDocument = (
    downloadDocumentPayload: DownloadDocumentPayload
  ) => {
    dispatch(downloadDocumentAction(downloadDocumentPayload));
  };

  // const canBeModified =
  //   !!requestDetail && !isNonModifiable(requestDetail, user?.role);

  // const canBeCoverted =
  //   !!requestDetail &&
  //   !isApplicationNotConvertable(requestDetail, user?.role, authUserStatus);

  const isReviewerRoleType = isReviewerRole(user?.role);
  const isSalesRoleType = isSalesRole(user?.role);

  useLayoutEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    if (!requestDetail) return;
    // setStipulations(
    //   getRequiredStipulations(requestDetail?.appStipulations || [])
    // );
    // setSolarContractStip(getSolarContractStip(requestDetail));
    // setUploadIdStip(getIdentificationStip(requestDetail));
    // setInstallationPhotoStip(getInstallationPhotosStip(requestDetail));
    // setPtoDocumentUploadedStip(getPtoDocumentStip(requestDetail));
  }, [requestDetail]);

  useEffect(() => {
    if (
      !issueLoanDocType?.batteryType.length ||
      !issueLoanDocType?.inverterType?.length ||
      !issueLoanDocType?.moduleType?.length
    ) {
      dispatch(getTypesAction());
    }
  }, [dispatch, issueLoanDocType]);

  let token: any = query.get("token");
  const primaryData = requestDetail?.primaryApplicant;

  const onDocumentInputFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
    docId: string,
    callback?: () => void
  ) => {
    setDocId(docId);
    setErrorMessage("");
    const file =
      event.target.files instanceof FileList ? event.target.files[0] : null;
    if (!file) return;

    if (!fileSizeValid(file)) {
      setErrorMessage("File size must be be less than 60mb");
      return;
    }
    const data = new FormData();
    data.append("file", file);
    data.append("stipulationNames", docId);
    setUploadingDocType(docId);
    dispatch(setIsDocumentUploading(true));
    try {
      const isSuccess = await loanService.uoloadDocumentProcess(id, data);
      if (isSuccess) {
        toasterService.success("Document has been uploaded successfully");
      } else {
        setErrorMessage(MESSAGES.UPLOAD_DOC_FAILED);
      }
      getDetail();
      dispatch(setIsDocumentUploading(false));
    } catch (error) {
      setErrorMessage(MESSAGES.UPLOAD_DOC_FAILED);
      dispatch(setIsDocumentUploading(false));
    }
    callback?.();
  };

  return (
    <div>
      {isDocumentDownloading && (
        <div className="loader">
          <LoadingSpinner />
        </div>
      )}
      <div className="status">
        <Container fluid>
          <Row className="g-0">
            <Col xs={12} lg={3} className="border-design pb-3 p-md-3">
              <Row>
                <Col
                  md={6}
                  lg={12}
                  className="d-none d-md-block order-2 order-lg-1 text-end text-lg-start"
                >
                  <Button
                    variant="outline-primary-dark"
                    onClick={() => goBack()}
                  >
                    Return to Pipeline
                  </Button>
                </Col>

                {id === "new" && (
                  <Col xs={12} md={6} lg={12} className="order-1 order-lg-2">
                    <div className="application-status">
                      <>
                        <p className="mb-2">
                          {" "}
                          <span>
                            {loanSlice?.draftDetail?.application_type}{" "}
                            Application
                          </span>{" "}
                          - <span>New</span>{" "}
                        </p>
                      </>
                    </div>
                  </Col>
                )}

                {id !== "new" && (
                  <Col xs={12} md={6} lg={12} className="order-1 order-lg-2">
                    <div className="application-status">
                      {requestDetail && (
                        <>
                          <p className="mb-2">
                            {requestDetail?.losAppNumber} |{" "}
                            {primaryData?.fullName}
                          </p>
                          <p className="mb-2 d-flex align-items-center">
                            <span>
                              {getPortalApplicationStatusLabel(
                                requestDetail?.status?.applicationStatus
                              )}{" "}
                            </span>
                            <span className="ms-2">-</span>
                            <span
                              className={clsx(
                                requestDetail?.status?.decisionStatus &&
                                  getStatusBackground(
                                    requestDetail?.status?.decisionStatus
                                  ),
                                "ms-2"
                              )}
                            >
                              {requestDetail?.status?.decisionStatus &&
                                PortalDecisionStatusLabel[
                                  requestDetail?.status?.decisionStatus
                                ]}
                            </span>
                            {!!requestDetail?.portalAccessibility?.qcReview &&
                              requestDetail?.status?.qcReviewStatus ===
                                "Failed" && (
                                <span className="ms-2 bg-warning badge-status text-light radius-16 mb-0 ">
                                  QC Failed
                                </span>
                              )}
                          </p>
                        </>
                      )}
                    </div>
                    <QcPassFail data={requestDetail} reload={getDetail} />

                    <UpdateBookingConfirmation data={requestDetail} />
                  </Col>
                )}
              </Row>
              <div className="requirements d-none d-lg-block">
                <h4 className="m-0">Requirements</h4>
                <hr className="bg-primary-dark height-2px opacity-1" />

                <div className="pt-2 pb-2" />
                {isSungageProduct ? (
                  requestDetail?.portalTasks?.map(({ name, state }) => (
                    <TaskItem key={name} state={state} name={name} />
                  ))
                ) : (
                  <>
                    {id === "new" &&
                      APPLICATION_STEPS.map((item) => (
                        <StatusSquare
                          key={item}
                          status={"inactive"}
                          statusName={item}
                        />
                      ))}
                    {id !== "new" &&
                      requestDetail?.portalTasks?.map((task) => (
                        <StatusSquare
                          status={task.state}
                          statusName={task.name}
                        />
                      ))}
                  </>
                )}
              </div>

              {/* TODO: Remove this after the verifying all cases for Task lists */}
              {/*<>
             {!isRecordProcessed() && (
               <>
                 <StatusSquare
                   status={"completed"}
                   statusName={ApplicationSteps.PreQual}
                   // statusClass={"inactive"}
                 />
                 <StatusSquare
                   status={
                     !isPrequalApplication(requestDetail)
                       ? "completed"
                       : "inactive"
                   }
                   statusName={ApplicationSteps.FullApplication}
                   // statusClass={
                   //   !isPrequalApplication(requestDetail)
                   //     ? "inactive"
                   //     : "active"
                   // }
                 />
                 <StatusSquare
                   status={
                     stipulationUploaded ? "completed" : "inactive"
                   }
                   statusName={ApplicationSteps.Stipulations}
                   // statusClass={
                   //   stipulationUploaded ? "inactive" : "active"
                   // }
                 />
                 <StatusSquare
                   status={
                     identificationDocUploaded
                       ? "completed"
                       : "inactive"
                   }
                   statusName={ApplicationSteps.UploadID}
                   // statusClass={
                   //   identificationDocUploaded
                   //     ? "inactive"
                   //     : "active"
                   // }
                 />
                 <StatusSquare
                   status={
                     solarContractUploaded ? "completed" : "inactive"
                   }
                   statusName={ApplicationSteps.UploadContract}
                   // statusClass={
                   //   solarContractUploaded ? "inactive" : "active"
                   // }
                 />

                 <StatusSquare
                   status={
                     issueDocsUploaded ? "completed" : "inactive"
                   }
                   statusName={ApplicationSteps.IssueDocs}
                   // statusClass={
                   //   issueDocsUploaded ? "inactive" : "active"
                   // }
                 />
               </>
             )}
             {isRecordProcessed() &&
               APPLICATION_STEPS.map((item) => (
                 <StatusSquare
                   status={"completed"}
                   statusName={item}
                   key={item}
                   // statusClass={"inactive"}
                 />
               ))}
           </> */}

              <div className="d-none d-lg-block">
                <InstallerInfo data={requestDetail} id={id} />
              </div>
            </Col>
            <Col xs={12} lg={9} className="pt-3 p-md-3 ps-lg-5">
              <Tabs
                id="application-tab"
                activeKey={activeTab}
                onSelect={(k: any) => setActiveTab(k)}
                className="text-primary main-tab pt-0"
                fill
              >
                <Tab
                  eventKey={RequestDetailsTabs.application}
                  title="Application"
                >
                  <RequestApplication data={requestDetail} id={id} />
                </Tab>
                {isSalesRoleType && (
                  <Tab
                    eventKey={RequestDetailsTabs.noticeToProceed}
                    title="Notice to Proceed"
                    disabled={
                      !requestDetail?.portalAccessibility?.enableNtpTab ||
                      appSlice.loading
                    }
                  >
                    <NoticeToProceed
                      requestDetail={requestDetail}
                      onDocumentInputFileChange={onDocumentInputFileChange}
                      docId={docId}
                      errorMessage={errorMessage}
                      uploadingDocType={uploadingDocType}
                      solarContractStip={
                        requestDetail?.documents?.solarContract || null
                      }
                      stipulations={
                        requestDetail?.documents?.stipulations || []
                      }
                      uploadIdStip={
                        requestDetail?.documents?.applicantsIdentification || []
                      }
                      downloadDocument={downloadDocument}
                      canBeModified={
                        !!requestDetail?.portalAccessibility?.enableNtpTab
                      }
                    />
                  </Tab>
                )}

                {isSalesRoleType && (
                  <Tab
                    eventKey={RequestDetailsTabs.funding}
                    title="Funding"
                    disabled={
                      !requestDetail?.portalAccessibility?.enableFundingTab ||
                      appSlice.loading
                    }
                  >
                    <Funding
                      requestDetail={requestDetail}
                      onDocumentInputFileChange={onDocumentInputFileChange}
                      docId={docId}
                      errorMessage={errorMessage}
                      uploadingDocType={uploadingDocType}
                      downloadDocument={downloadDocument}
                      canBeModified={
                        !!requestDetail?.portalAccessibility?.enableFundingTab
                      }
                      installationPhotoStip={
                        requestDetail?.documents?.installationPhotos || null
                      }
                    />
                  </Tab>
                )}
                {isSalesRoleType && (
                  <Tab
                    eventKey={RequestDetailsTabs.inspections}
                    title="Inspections"
                    disabled={
                      !requestDetail?.portalAccessibility
                        ?.enableInspectionsTab || appSlice.loading
                    }
                  >
                    <Inspections
                      requestDetail={requestDetail}
                      onDocumentInputFileChange={onDocumentInputFileChange}
                      docId={docId}
                      errorMessage={errorMessage}
                      uploadingDocType={uploadingDocType}
                      downloadDocument={downloadDocument}
                      canBeModified={
                        !!requestDetail?.portalAccessibility
                          ?.enableInspectionsTab
                      }
                      ptoDocumentUploadedStip={
                        requestDetail?.documents?.permissionToOperate || null
                      }
                    />
                  </Tab>
                )}
                {isReviewerRoleType && (
                  <Tab
                    eventKey={RequestDetailsTabs.documents}
                    title="Documents"
                  >
                    <ReviwerDocuments
                      canBeModified={user?.role === UserRole.providerAdmin}
                      requestDetail={requestDetail}
                      onDocumentInputFileChange={onDocumentInputFileChange}
                      docId={docId}
                      errorMessage={errorMessage}
                      uploadingDocType={uploadingDocType}
                      solarContractStip={
                        requestDetail?.documents?.solarContract || null
                      }
                      installationPhotoStip={
                        requestDetail?.documents?.installationPhotos || null
                      }
                      ptoDocumentUploadedStip={
                        requestDetail?.documents?.permissionToOperate || null
                      }
                      stipulations={
                        requestDetail?.documents?.stipulations || []
                      }
                      uploadIdStip={
                        requestDetail?.documents?.applicantsIdentification || []
                      }
                      downloadDocument={downloadDocument}
                    />
                  </Tab>
                )}
                {isReviewerRoleType && (
                  <Tab
                    eventKey={RequestDetailsTabs.loan}
                    title="Loan"
                    disabled={
                      !requestDetail?.portalAccessibility?.enableLoanTab ||
                      appSlice.loading
                    }
                  >
                    <Loan data={requestDetail} id={id} />
                  </Tab>
                )}
                {isReviewerRoleType && (
                  <Tab eventKey={RequestDetailsTabs.decision} title="Decision">
                    <Decision data={requestDetail} />
                  </Tab>
                )}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RequestDetail;
