import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { networkService } from "@services/network-service";
import { sungageService } from "@services/sungage.service";
import { userService } from "@services/user.service";
import { UserAuthStatus } from "@shared/enums/UserState";
import { allowloadingStateUpdateForActions } from "@shared/helpers/api.helper";
import { ISungageDisclosures } from "@shared/interfaces/SungageTypes";
import { UserRole } from "@shared/interfaces/User";
import { RootState } from "@store/store";
import { LoginMethod } from "@store/types";
import { RouteComponentProps } from "react-router-dom";

interface IDealer {
  internalClientId: number;
  name: string;
}

export interface ILender {
  label: string;
  value: number;
}

export interface AccessManagement {
  // id of accessible products in productAccess
  productAccess: string[];
}

export interface IAuthUserDetails {
  dealer: string | null;
  xEmployeeID: string | null;
  employeeId: string | null;
  sublenderId: string;
  accessManagement: AccessManagement | null;
  lenderId: number | null;
}

export interface IAuthData {
  token: {
    accessToken: string;
    idToken: string;
    refreshToken: string;
  };
  email: string;
  exp: number;
  uid: string;
  auth_time: number;
  token_use: string;
  username: string;
  role: UserRole;
  userDetails: IAuthUserDetails;
  loginMethod: LoginMethod;
  createdAt: Date;
}

export interface IAuthAppMfaSetupData {
  mfaSetupSecretCode: string | null;
}

export interface ISmsMfaSetupData {
  phoneNumber: string;
  status: "GetPhoneNumber" | "SmsCodeSent";
}

export interface ILoginProcessData {
  challengeName: string;
  session: string;
  email: string;
  password: string;
}

interface IApp {
  loading?: boolean;
  isProcessing?: boolean;
  alert: AppError;
  user: IAuthData | null;
  accessToken: string | null;
  dealers: IDealer[];
  lenders: ILender[];
  authAppMfaSetupData: IAuthAppMfaSetupData;
  smsMfaSetupData: ISmsMfaSetupData | null;
  loginProcessData: ILoginProcessData | null;
  sungageDisclosures: ISungageDisclosures;
  sungageUtilities: string[];
}

export interface AppError {
  message?: string;
  type?: any | undefined;
  open: boolean;
}

export enum MessageType {
  error = "error",
  success = "success",
  warn = "warning",
  info = "info",
}

const initialState: IApp = {
  loading: false,
  isProcessing: false,
  alert: { message: "", type: MessageType.info, open: false },
  user: null,
  accessToken: null,
  dealers: [],
  lenders: [],
  authAppMfaSetupData: {
    mfaSetupSecretCode: null,
  },
  smsMfaSetupData: null,
  loginProcessData: null,
  sungageDisclosures: {} as ISungageDisclosures,
  sungageUtilities: [],
};

const isPending = (action: AnyAction) =>
  action.type.endsWith("/pending") &&
  !allowloadingStateUpdateForActions(action.type); // Do not want to update the loading state on slice actions.
const isRejected = (action: AnyAction) => action.type.endsWith("/rejected");

const hasPrefix = (action: AnyAction, prefix: string) =>
  action.type.startsWith(prefix);

const isPendingAction =
  (prefix: string) =>
  (action: AnyAction): action is AnyAction => {
    // Note: this cast to AnyAction could also be `any` or whatever fits your case best
    return hasPrefix(action, prefix) && isPending(action);
  };

const isRejectedAction =
  (prefix: string) =>
  (action: AnyAction): action is AnyAction => {
    // Note: this cast to AnyAction could also be `any` or whatever fits your case best - like if you had standardized errors and used `rejectWithValue`
    return hasPrefix(action, prefix) && isRejected(action);
  };

export const signOutAction = createAsyncThunk(
  "app/signout",
  async (
    history: RouteComponentProps["history"],
    { rejectWithValue, dispatch }
  ) => {
    try {
      await networkService.delete(`/signout`);
      dispatch(setLoggedInUser(null));
      // dispatch(resetApp())
      history.push(`/auth/login`);
      return;
    } catch (error) {
      dispatch(setLoggedInUser(null));
      history.push(`/auth/login`);
      return rejectWithValue(error);
    }
  }
);

export const getAuthDetailsAction = createAsyncThunk(
  "app/get-auth-details",
  async (
    {
      setAuthUserStatus,
    }: {
      setAuthUserStatus: (value: UserAuthStatus) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await networkService.get<{
        data: IAuthUserDetails;
      }>(`/auth/details`);

      dispatch(setAuthUserDetails(data.data));

      setAuthUserStatus(UserAuthStatus.Authorized);
      return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDealersAction = createAsyncThunk(
  "app/dealers",
  async (args: null, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await networkService.get<
        any,
        { data: { data: IDealer[] } }
      >(`dealers`);
      dispatch(setDealers(data?.data || []));
      return data?.data || [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLendersAction = createAsyncThunk(
  "app/lenders",
  async (args: null, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await networkService.get<
        any,
        { data: { data: ILender[] } }
      >(`lenders`);
      dispatch(setLenders(data?.data || []));
      return data?.data || [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSungageDisclosuresAction = createAsyncThunk(
  "app/disclosures",
  async (args: undefined, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await sungageService.getSungageDisclosures();
      dispatch(setSungageDisclosures(data));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSungageUtilitiesAction = createAsyncThunk(
  "app/sungageUtilities",
  async (state: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await sungageService.getSungageUtilities(state);
      dispatch(setSungageUtilities(data));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTotpQrCode = createAsyncThunk(
  "auth/getMfaSecritCode",
  async (args: undefined, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await userService.getMfaQrCode();
      // Fetch QR code
      dispatch(
        setMfaSetupData({
          mfaSetupSecretCode: data.data.SecretCode,
        })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    resetApp(state) {
      Object.assign(state, initialState);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setMessage: (state: IApp, { payload }: PayloadAction<AppError>) => {
      state.alert.open = payload.open === false ? false : true;
      state.alert.message = payload.message;
      state.alert.type = payload.type ? payload.type : undefined;
    },
    setLoggedInUser: (
      state: IApp,
      { payload }: PayloadAction<IAuthData | null>
    ) => {
      state.user = payload;
    },
    setAuthUserDetails: (
      state: IApp,
      { payload }: PayloadAction<IAuthUserDetails>
    ) => {
      if (state.user) {
        state.user = {
          ...state.user,
          userDetails: payload,
        };
      }
    },
    setMfaSetupData: (
      state: IApp,
      { payload }: PayloadAction<IAuthAppMfaSetupData>
    ) => {
      if (state.user) {
        state.authAppMfaSetupData = payload;
      }
    },
    setSmsMfaSetupData: (
      state: IApp,
      { payload }: PayloadAction<ISmsMfaSetupData | null>
    ) => {
      return {
        ...state,
        smsMfaSetupData: payload,
      };
    },
    setAccessToken: (state: IApp, { payload }: PayloadAction<any>) => {
      state.accessToken = payload;
    },
    setDealers: (state: IApp, { payload }: PayloadAction<IDealer[]>) => {
      return {
        ...state,
        dealers: payload,
      };
    },
    setLenders: (state: IApp, { payload }: PayloadAction<ILender[]>) => {
      return {
        ...state,
        lenders: payload,
      };
    },
    setLoginProcessData: (
      state: IApp,
      { payload }: PayloadAction<ILoginProcessData | null>
    ) => {
      return {
        ...state,
        loginProcessData: payload,
      };
    },
    setLoginMethod: (state: IApp, { payload }: PayloadAction<LoginMethod>) => {
      if (state.user) {
        state.user = {
          ...state.user,
          loginMethod: payload,
        };
      }
    },
    setSungageDisclosures: (
      state: any,
      { payload }: PayloadAction<ISungageDisclosures>
    ) => {
      return {
        ...state,
        sungageDisclosures: payload,
      };
    },
    setSungageUtilities: (
      state: any,
      { payload }: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        sungageUtilities: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      signOutAction.fulfilled,
      (state: IApp, { payload }: PayloadAction<any>) => {
        state.user = null;
      }
    );
    builder.addMatcher(isRejectedAction(""), (state) => {
      // state.loading = false;
      // appSlice.caseReducers.setMessage(state, action)
    });
    builder.addMatcher(isPendingAction(""), (state) => {
      state.loading = true;
    });
  },
});

export const {
  setLoading,
  setMessage,
  setProcessing,
  setLoggedInUser,
  setAuthUserDetails,
  resetApp,
  setAccessToken,
  setDealers,
  setLenders,
  setMfaSetupData,
  setSmsMfaSetupData,
  setLoginProcessData,
  setLoginMethod,
  setSungageDisclosures,
  setSungageUtilities
} = appSlice.actions;
export const appUserSelector = (rootState: RootState) => rootState.app.user;
export const appSelector = (rootState: RootState) => rootState.app;
export const appAccessTokenSelector = (rootState: RootState) =>
  rootState.app.accessToken;
export const authAppMfaSetupDataSelector = (
  rootState: RootState
): IAuthAppMfaSetupData => rootState.app.authAppMfaSetupData;
export const smsMfaSetupDataSelector = (
  rootState: RootState
): ISmsMfaSetupData | null => rootState.app.smsMfaSetupData;
export const loginProcessDataSelector = (
  rootState: RootState
): ILoginProcessData | null => rootState.app.loginProcessData;
export const appLoadingSelector = (rootState: RootState): boolean =>
  !!rootState.app.loading;

export const appDealersSelector = (rootState: RootState) =>
  rootState.app.dealers;
export const appLendersSelector = (rootState: RootState) =>
  rootState.app.lenders;
export const sungageDisclosuresSelector = (
  rootState: RootState
): ISungageDisclosures => rootState.app.sungageDisclosures;
export const sungageUtilitiesSelector = (
  rootState: RootState
): string[] => rootState.app.sungageUtilities;
export const appLoading = (rootState: RootState) => rootState.app.loading;

export default appSlice.reducer;
