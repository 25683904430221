import { UseFormTextField } from "@shared/components/TextInput";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { appService } from "@services/app.service";
import { toasterService } from "@services/toaster.service";
import { addUserSchema } from "@shared/schema/request";
import { Link, useHistory } from "react-router-dom";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import {
  PRIVATE_PROGRAM_TYPE,
  PRODUCT_20_YEAR_RISK_BASED_PRICING,
  PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING,
  PRODUCT_25_YEAR_3_99_INTEREST,
  PRODUCT_25_YEAR_4_99_INTEREST,
  PRODUCT_25_YEAR_6_99_INTEREST,
  PRODUCT_20_YEAR_2_49_INTEREST,
  PRODUCT_20_YEAR_2_99_INTEREST,
  PRODUCT_20_YEAR_5_99_INTEREST,
  PRODUCT_20_YEAR_7_49_INTEREST,
  PRODUCT_20_YEAR_8_99_INTEREST,
  PRODUCT_20_YEAR_1_49_INTEREST,
  PRODUCT_20_YEAR_1_99_INTEREST,
  PRODUCT_20_YEAR_3_99_INTEREST,
  PRODUCT_20_YEAR_6_99_INTEREST,
  PRODUCT_10_YEAR_8_99_INTEREST,
  PRODUCT_15_YEAR_8_99_INTEREST,
  PRODUCT_15_YEAR_7_99_INTEREST,
  PRODUCT_25_YEAR_3_74_INTEREST,
  PRODUCT_25_YEAR_4_74_INTEREST,
  PRODUCT_25_YEAR_5_24_INTEREST,
  PRODUCT_SG_25_YEAR_3_99_INTEREST,
  PRODUCT_SG_25_YEAR_5_99_INTEREST,
  PRODUCT_SG_25_YEAR_6_99_INTEREST,
  PRODUCT_SG_25_YEAR_8_99_INTEREST,
  PRODUCT_SG_10_YEAR_7_99_INTEREST,
  PRODUCT_SG_10_YEAR_9_99_INTEREST,
  USER_ROLES,
  MESSAGES,
} from "@shared/constants/app.constant";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getRegionsAction, regionsSelector } from "@store/slices/region.slice";
import {
  appDealersSelector,
  appLendersSelector,
  getDealersAction,
  getLendersAction,
} from "@store/slices/app.slice";
import { useEffect, useMemo } from "react";
import { onSubmitError } from "@shared/helpers/global.helper";
import { UseFormCheck } from "@shared/components/UserFormCheck";
import { UserRole } from "@shared/interfaces/User";

const AddUser = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dealersData = useAppSelector(appDealersSelector);
  const lendersData = useAppSelector(appLendersSelector);
  const regionsData = useAppSelector(regionsSelector);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState,
    watch,
  } = useForm({
    resolver: yupResolver(addUserSchema),
  });
  const role = watch("role");
  const dealer = watch("dealer");

  const dealers = useMemo(
    () =>
      dealersData.map(({ internalClientId, name }) => ({
        text: name,
        value: internalClientId,
      })),
    [dealersData]
  );

  const lenders = useMemo(
    () =>
      lendersData.map(({ label, value }) => ({
        text: label,
        value: String(value),
      })),
    [lendersData]
  );

  const regions = useMemo(
    () =>
      regionsData
        .filter((item) => item.dealer === dealer)
        .map(({ id, name }) => ({
          text: name,
          value: id,
        })),
    [regionsData, dealer]
  );
  const isBusy = formState.isSubmitting;

  const onSubmit = async (event: any) => {
    try {
      if (isBusy) {
        return;
      }
      const productAccess: string[] = [];
      PRIVATE_PROGRAM_TYPE.forEach((element) => {
        event[element.id] === true && productAccess.push(element.id);
      });
      const isAdminOrFI =
        role === UserRole.providerAdmin ||
        role === UserRole.financialInstitution;
      const response: any = await appService.addUser({
        email: event.email,
        username: event.username,
        role: event.role,
        dealer: isAdminOrFI ? null : event.dealer,
        regionId: isAdminOrFI ? null : event.regionId,
        lenderId:
          role === UserRole.financialInstitution
            ? Number(event.lenderId)
            : null,
        productAccess: isAdminOrFI ? null : productAccess,
      });
      if (response?.data?.data?.isSuccess) {
        toasterService.success("User has been created successfully");
        history.push("/users");
        reset({
          email: "",
          username: "",
          role: "",
          dealer: "",
          regionId: "",
          lenderId: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      role === UserRole.providerAdmin ||
      role === UserRole.financialInstitution
    ) {
      setValue(PRODUCT_20_YEAR_2_49_INTEREST, false);
      setValue(PRODUCT_20_YEAR_2_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_5_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_7_49_INTEREST, false);
      setValue(PRODUCT_20_YEAR_8_99_INTEREST, false);
      setValue(PRODUCT_25_YEAR_3_99_INTEREST, false);
      setValue(PRODUCT_25_YEAR_4_99_INTEREST, false);
      setValue(PRODUCT_25_YEAR_6_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_RISK_BASED_PRICING, false);
      setValue(PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING, false);
      setValue(PRODUCT_20_YEAR_1_49_INTEREST, false);
      setValue(PRODUCT_20_YEAR_1_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_3_99_INTEREST, false);
      setValue(PRODUCT_20_YEAR_6_99_INTEREST, false);
      setValue(PRODUCT_10_YEAR_8_99_INTEREST, false);
      setValue(PRODUCT_15_YEAR_8_99_INTEREST, false);
      setValue(PRODUCT_15_YEAR_7_99_INTEREST, false);
      setValue(PRODUCT_25_YEAR_3_74_INTEREST, false);
      setValue(PRODUCT_25_YEAR_4_74_INTEREST, false);
      setValue(PRODUCT_25_YEAR_5_24_INTEREST, false);
      setValue(PRODUCT_SG_25_YEAR_3_99_INTEREST, false);
      setValue(PRODUCT_SG_25_YEAR_5_99_INTEREST, false);
      setValue(PRODUCT_SG_25_YEAR_6_99_INTEREST, false);
      setValue(PRODUCT_SG_25_YEAR_8_99_INTEREST, false);
      setValue(PRODUCT_SG_10_YEAR_7_99_INTEREST, false);
      setValue(PRODUCT_SG_10_YEAR_9_99_INTEREST, false);
    }
  }, [role, setValue]);

  useEffect(() => {
    dispatch(getDealersAction(null));
    dispatch(getRegionsAction(null));
    dispatch(getLendersAction(null));
  }, [dispatch]);

  useEffect(() => {
    setValue("role", USER_ROLES[0].value);
    setValue(PRODUCT_20_YEAR_2_49_INTEREST, false);
    setValue(PRODUCT_20_YEAR_2_99_INTEREST, false);
    setValue(PRODUCT_20_YEAR_5_99_INTEREST, false);
    setValue(PRODUCT_20_YEAR_7_49_INTEREST, false);
    setValue(PRODUCT_20_YEAR_8_99_INTEREST, false);
    setValue(PRODUCT_25_YEAR_3_99_INTEREST, false);
    setValue(PRODUCT_25_YEAR_4_99_INTEREST, false);
    setValue(PRODUCT_25_YEAR_6_99_INTEREST, false);
    setValue(PRODUCT_20_YEAR_RISK_BASED_PRICING, false);
    setValue(PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING, false);
    setValue(PRODUCT_20_YEAR_1_49_INTEREST, false);
    setValue(PRODUCT_20_YEAR_1_99_INTEREST, false);
    setValue(PRODUCT_20_YEAR_3_99_INTEREST, false);
    setValue(PRODUCT_20_YEAR_6_99_INTEREST, false);
    setValue(PRODUCT_10_YEAR_8_99_INTEREST, false);
    setValue(PRODUCT_15_YEAR_8_99_INTEREST, false);
    setValue(PRODUCT_15_YEAR_7_99_INTEREST, false);
    setValue(PRODUCT_25_YEAR_3_74_INTEREST, false);
    setValue(PRODUCT_25_YEAR_4_74_INTEREST, false);
    setValue(PRODUCT_25_YEAR_5_24_INTEREST, false);
    setValue(PRODUCT_SG_25_YEAR_3_99_INTEREST, false);
    setValue(PRODUCT_SG_25_YEAR_5_99_INTEREST, false);
    setValue(PRODUCT_SG_25_YEAR_6_99_INTEREST, false);
    setValue(PRODUCT_SG_25_YEAR_8_99_INTEREST, false);
    setValue(PRODUCT_SG_10_YEAR_7_99_INTEREST, false);
    setValue(PRODUCT_SG_10_YEAR_9_99_INTEREST, false);
  }, [setValue]);

  useEffect(() => {
    setValue("regionId", "");
  }, [dealer, setValue]);

  useEffect(() => {
    dealer && !regions.length
      ? setError("regionId", {
          message: MESSAGES.NO_REGION_IN_DEALER,
        })
      : clearErrors("regionId");
  }, [clearErrors, dealer, regions.length, setError]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="login-container form-container">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <div className="row">
            <div className="col-xs-12 col-md-8 offset-md-2">
              <div className="d-flex justify-content mt-3">
                <h3 className="h3 flex-grow-1">Add User</h3>
              </div>
              <h5>User Info</h5>
              <Row className="mb-3">
                <Col>
                  <UseFormTextField
                    placeholder="Full Name"
                    control={control}
                    name="username"
                  />
                </Col>
                <Col>
                  <UseFormTextField
                    placeholder="Email"
                    control={control}
                    name="email"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <UseFormSelect
                    list={USER_ROLES}
                    control={control}
                    name="role"
                  />
                </Col>
                <Col>
                  {role === UserRole.financialInstitution && (
                    <UseFormSelect
                      list={lenders}
                      control={control}
                      name="lenderId"
                      placeholder="Select Lender"
                      placeholderValue={""}
                    />
                  )}
                </Col>
              </Row>
              {role !== UserRole.providerAdmin &&
                role !== UserRole.financialInstitution && (
                  <>
                    <h5>Installer Info</h5>
                    <Row className="mb-3">
                      <Col>
                        <UseFormSelect
                          list={dealers}
                          control={control}
                          name="dealer"
                          placeholder="Select Dealer"
                          placeholderValue={""}
                        />
                      </Col>
                      <Col>
                        <UseFormSelect
                          list={regions}
                          control={control}
                          name="regionId"
                          placeholder="Select Region"
                          placeholderValue={""}
                          disabled={!regions.length}
                        />
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Link to="/add-region" className="text-primary">
                        Add Region
                      </Link>
                    </div>
                  </>
                )}
              {role !== UserRole.providerAdmin &&
                role !== UserRole.financialInstitution && (
                  <>
                    <h5>Product Access</h5>
                    <Row className="mb-3">
                      <Col xs={12}>
                        {PRIVATE_PROGRAM_TYPE.map((product) => (
                          <div className="my-3">
                            <UseFormCheck
                              content={product.text}
                              control={control}
                              elementName={product.text}
                              controlName={product.id}
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </>
                )}
              <Row className="justify-content-center mt-5">
                <Col xs={3} className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    Exit
                  </Button>
                </Col>
                <Col xs={3} className="d-grid">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={formState.isSubmitting}
                  >
                    Add User
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
