import SquareOpen from "@assets/images/square-open.svg";
import SquareDone from "@assets/images/square-done.svg";
import SquareUnavailable from "@assets/images/square-unavailable.svg";
import { ITask } from "@shared/interfaces/PortalApplicationTypes";

interface IStatusSquareProps {
  name: string;
  state: ITask["state"];
}

const statusClassMap: Record<
  IStatusSquareProps["state"],
  "active" | "inactive"
> = {
  active: "active",
  inactive: "inactive",
  completed: "inactive",
};

const statusImageMap: Record<IStatusSquareProps["state"], string> = {
  active: SquareOpen,
  inactive: SquareUnavailable,
  completed: SquareDone,
};

const TaskItem = ({ state, name }: IStatusSquareProps) => {
  return (
    <>
      <div className="status-list d-flex align-items-center">
        <img src={statusImageMap[state]} alt="" />
        <div className={`status-name ${statusClassMap[state]}`}>
          <p className="m-0">{name}</p>
        </div>
      </div>
    </>
  );
};

export default TaskItem;
