import { Redirect } from "react-router-dom";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

/**
 * This component redirects the user to the Pipelines page
 * if an Application is restricted from being modified
 */
const ConvertApplicationGuard = ({
  application,
}: {
  application?: IPortalApplication | null;
}) => {
  if (
    !!application &&
    !!!application?.portalAccessibility?.convertApplication
  ) {
    return <Redirect to="/pipelines" />;
  }
  return <></>;
};

export default ConvertApplicationGuard;
