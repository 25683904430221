import SquareDone from "@assets/images/square-done.svg";
import SquareUnavailable from "@assets/images/square-unavailable.svg";
import clsx from "clsx";

export const StatusSquare = ({
  status,
  statusName,
}: {
  status: "inactive" | "active" | "completed";
  statusName: string;
}) => {
  return (
    <>
      <div className="status-list d-flex align-items-center">
        <img
          src={status === "completed" ? SquareDone : SquareUnavailable}
          alt=""
        />
        <div
          className={clsx(
            `status-name`,
            status === "active" ? "active" : "inactive"
          )}
        >
          <p className="m-0">{statusName}</p>
        </div>
      </div>
    </>
  );
};
