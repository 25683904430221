import {
  IDocumentMetadata,
  IPortalApplication,
} from "@shared/interfaces/PortalApplicationTypes";
import { useAppSelector } from "@store/hooks";
import {
  DownloadDocumentPayload,
  documentDownloadingSelector,
  documentUploadingSelector,
} from "@store/slices/loan.slice";
import { Download, Search } from "react-bootstrap-icons";

export const DocumentCard = ({
  documentMetadata,
  requestDetail,
  downloadDocument,
}: {
  documentMetadata: IDocumentMetadata;
  requestDetail: IPortalApplication | undefined;
  downloadDocument: (value: DownloadDocumentPayload) => void;
}) => {
  const isDocumentDownloading = useAppSelector(documentDownloadingSelector);
  const isDocumentUploading = useAppSelector(documentUploadingSelector);
  return (
    <div className="d-flex flex-column flex-sm-row justify-content-between mt-3">
      <div>
        <div className="d-flex">
          <p>{documentMetadata.label}</p> &nbsp;&nbsp;&nbsp;
        </div>
        <p className="text-success">Received.</p>
      </div>
      <div className="text-center my-3 my-sm-0">
        {requestDetail?.losAppNumber &&
          documentMetadata.label &&
          !!requestDetail?.portalAccessibility?.downloadDocuments && (
            <>
              <button
                className="btn btn-light ms-2"
                onClick={() => {
                  requestDetail?.losAppNumber &&
                    downloadDocument({
                      appId: requestDetail?.losAppNumber,
                      documentIds: [documentMetadata.documentId],
                      case: "view",
                    });
                }}
                disabled={isDocumentDownloading || isDocumentUploading}
              >
                <Search />
              </button>
              <button
                className="btn btn-light ms-2"
                onClick={() => {
                  requestDetail?.losAppNumber &&
                    downloadDocument({
                      appId: requestDetail?.losAppNumber,
                      documentIds: [documentMetadata.documentId],
                      case: "download",
                    });
                }}
                disabled={isDocumentDownloading || isDocumentUploading}
              >
                <Download />
              </button>
            </>
          )}
      </div>
    </div>
  );
};
