import { ChangeEvent } from "react";
import { DownloadDocumentPayload } from "@store/slices/loan.slice";
import { isPending } from "@shared/helpers/global.helper";
import { StipulationCard } from "./StipulationCard";
import {
  IDocumentStipulation,
  IPortalApplication,
} from "@shared/interfaces/PortalApplicationTypes";

export const SolarContract = ({
  requestDetail,
  uploadingDocType,
  errorMessage,
  docId,
  onDocumentInputFileChange,
  solarContractStip,
  downloadDocument,
  canBeModified,
}: {
  requestDetail: IPortalApplication | undefined;
  uploadingDocType: string;
  errorMessage: string;
  docId: string;
  onDocumentInputFileChange: (
    event: ChangeEvent<HTMLInputElement>,
    docId: string,
    callback?: () => void
  ) => Promise<void>;
  solarContractStip: IDocumentStipulation | null;
  downloadDocument: (value: DownloadDocumentPayload) => void;
  canBeModified: boolean;
}) => {
  if (
    !requestDetail?.status?.decisionStatus ||
    isPending(requestDetail?.status?.decisionStatus)
  ) {
    return null;
  }

  return (
    <div>
      <h3 className="h3 text-primary-dark mt-4">Solar Contract</h3>
      <hr className="bg-primary-dark opacity-1 height-2px" />
      <div>
        {solarContractStip ? (
          <h4 className="font-bold-800 mt-4">
            Upload A Copy of the Fully Executed Solar Contract:
          </h4>
        ) : (
          <h4 className="font-bold-800 mt-4">
            There are no documents required for this loan
          </h4>
        )}

        {solarContractStip && (
          <StipulationCard
            requestDetail={requestDetail}
            stipulation={solarContractStip}
            onDocumentInputFileChange={onDocumentInputFileChange}
            docId={docId}
            errorMessage={errorMessage}
            uploadingDocType={uploadingDocType}
            downloadDocument={downloadDocument}
            canBeModified={canBeModified}
          />
        )}
      </div>
    </div>
  );
};
