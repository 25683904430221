import InlineLoader from "@shared/components/InlineLoader";
import { STIPULATION_ALLOWED } from "@shared/constants/app.constant";
import { isReviewerRole } from "@shared/helpers/global.helper";
import {
  IDocumentStipulation,
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import {
  DownloadDocumentPayload,
  documentDownloadingSelector,
  documentUploadingSelector,
} from "@store/slices/loan.slice";
import { ChangeEvent, useRef } from "react";
import { Download, Search } from "react-bootstrap-icons";
import { useParams, useLocation } from "react-router-dom";

export const StipulationCard = ({
  requestDetail,
  stipulation,
  onDocumentInputFileChange,
  docId,
  errorMessage,
  uploadingDocType,
  downloadDocument,
  canBeModified,
}: {
  requestDetail: IPortalApplication | undefined;
  stipulation: IDocumentStipulation | null;
  uploadingDocType: string;
  errorMessage: string;
  docId: string;
  onDocumentInputFileChange: (
    event: ChangeEvent<HTMLInputElement>,
    docId: string,
    callback?: () => void
  ) => Promise<void>;
  downloadDocument: (value: DownloadDocumentPayload) => void;
  canBeModified: boolean;
}) => {
  const uploadIdFile = useRef<HTMLInputElement>(null);
  const isDocumentUploading = useAppSelector(documentUploadingSelector);
  const isDocumentDownloading = useAppSelector(documentDownloadingSelector);
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const documentIds: string[] | undefined = stipulation?.documentIds;

  if (!stipulation) {
    return null;
  }

  const isSolarContract =
    requestDetail?.documents?.solarContract?.name === stipulation.name;

  if (isSolarContract) {
    stipulation.urlForDocumentUpload = `/issue-docs/${id}`;
  }

  return (
    <div>
      <div className="d-flex flex-column flex-sm-row justify-content-between mt-3">
        <div>
          <div className="d-flex">
            <p className="mb-0">{stipulation.name}</p> &nbsp;&nbsp;&nbsp;
            {stipulation?.type && (
              <p className="bg-primary status-padding text-light radius-16 ">
                {stipulation?.type}
              </p>
            )}
          </div>
          {stipulation?.description && (
            <p className="stip-description text-wrap ">
              {stipulation?.description}
            </p>
          )}
          {requestDetail && stipulation.isDocumentUploaded && (
            <p className="text-success">Submitted</p>
          )}
          {requestDetail && !stipulation.isDocumentUploaded && (
            <p className="text-warning">Required</p>
          )}
          {isDocumentUploading && uploadingDocType === stipulation.name && (
            <InlineLoader />
          )}
          {stipulation.isDocumentFailed && (
            <div className="text-danger">{stipulation.stipulationComment}</div>
          )}
          {errorMessage && stipulation.name === docId && (
            <div className="text-danger">{errorMessage}</div>
          )}
        </div>
        <div className="d-flex align-self-center text-center my-3 my-sm-0">
          {requestDetail?.los === ProductLos.SUNGAGE &&
          stipulation?.urlForDocumentUpload &&
          (isSolarContract ? !pathname.includes("issue-docs") : true) ? (
            <a
              target={!isSolarContract ? "_blank" : undefined}
              rel="noreferrer"
              className="btn btn-light"
              href={stipulation.urlForDocumentUpload as string}
            >
              Upload {isSolarContract ? "Contract" : "Docs"}
            </a>
          ) : (
            canBeModified && (
              <>
                <button
                  className="btn btn-light width-110px"
                  onClick={() => {
                    uploadIdFile?.current?.click();
                  }}
                  disabled={
                    isDocumentUploading && uploadingDocType === stipulation.name
                  }
                  type="button"
                >
                  Upload File
                </button>
                <input
                  accept={STIPULATION_ALLOWED}
                  className="d-none"
                  id={`upload-${stipulation.name.replace(/\s/g, "")}`}
                  type="file"
                  ref={uploadIdFile}
                  // ref={(el) => (uploadIdFile.current = el)}
                  onChange={(event) => {
                    onDocumentInputFileChange(event, stipulation.name, () => {
                      if (uploadIdFile?.current?.value)
                        uploadIdFile.current.value = "";
                    });
                  }}
                />
              </>
            )
          )}
          {requestDetail?.losAppNumber &&
            !!requestDetail.portalAccessibility?.downloadDocuments &&
            documentIds &&
            documentIds.length > 0 && (
              <>
                <button
                  className="btn btn-light ms-2"
                  onClick={() => {
                    requestDetail?.losAppNumber &&
                      downloadDocument({
                        appId: requestDetail?.losAppNumber,
                        documentIds: documentIds,
                        case: "view",
                      });
                  }}
                  disabled={isDocumentDownloading || isDocumentUploading}
                >
                  <Search />
                </button>
                <button
                  className="btn btn-light ms-2"
                  onClick={() => {
                    requestDetail?.losAppNumber &&
                      downloadDocument({
                        appId: requestDetail?.losAppNumber,
                        documentIds: documentIds,
                        case: "download",
                      });
                  }}
                  disabled={isDocumentDownloading || isDocumentUploading}
                >
                  <Download />
                </button>
              </>
            )}
        </div>
      </div>
      <hr className="bg-secondary" />
    </div>
  );
};
