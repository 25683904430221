import { DownloadDocumentPayload } from "@store/slices/loan.slice";
import { DocumentCard } from "./DocumentCard";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export const LoanDocPackage = ({
  requestDetail,
  downloadDocument,
}: {
  requestDetail: IPortalApplication | undefined;
  downloadDocument: (value: DownloadDocumentPayload) => void;
}) => {
  const loanDocuments = requestDetail?.documentsMetadata?.loanDocPackage || [];

  return (
    <div>
      <h4 className="font-bold-800">Doc Package</h4>
      <div>
        {loanDocuments.map((item, i) => (
          <DocumentCard
            documentMetadata={item}
            key={item.documentId}
            requestDetail={requestDetail}
            downloadDocument={downloadDocument}
          />
        ))}
      </div>
    </div>
  );
};
