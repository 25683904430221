import { EmploymentStatus } from "@shared/enums/EmploymentStatus";
import { toTimestamp } from "@shared/helpers/global.helper";
import { IXPrequalProductsWithDynamicFields } from "@shared/interfaces/Application";
import { UserRole, UserRoleLabel } from "@shared/interfaces/User";
import moment from "moment";

export const MESSAGES = {
  DEFAULT_ERROR: "We are unable to process the request. Try again later",
  REQUEST_FAILED:
    "Unfortunately, we are unable to pre-qualify you at this time. Thank you.",
  DECLINED: "Your Application has been declined.",
  SUBMITTED: "Your Application has been submitted successfully.",
  UPLOAD_DOC_FAILED:
    "Something went wrong while uploading the document. Please try again later",
  API_DATA_CRYPTOGRAPHY_FAILED: "Unable to process the response data.",
  NO_REGION_IN_DEALER: "No region found in the selected dealer.",
  UNABLE_TO_GET_OFFER: "We are unable to get the loan offer. Try again later",
  DL4_INCOME_PROMPT:
    "Please enter annual income, which may include any income that is verifiable and reported to the Government, such as employment income, social security, pensions, etc. Do not include household income from another borrower or non-applicant.",
  DL4_PREQUAL_INCOME_PROMPT:
    "Please enter annual income, which may include any income that is verifiable and reported to the Government, such as employment income, social security, pensions, etc.",
};
export const REGEX = {
  PASSWORD: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
  NUMBERS_ONLY_REGEX: /^\d+$/,
  GREATER_THAN_0: /^[1-9]\d*$/,
  ALPHABETS: /^[a-zA-Z]+$/g,
  SSN: /[^\d]/g,
  IP_ADDRESS:
    /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
} as const;

export const NA = "NA";

export const MONTHS_ARRAY = Array.from(Array(12).keys()).map((x) => ({
  value: x,
  text: x,
}));

export const YEARS_ARRAY = Array.from(Array(31).keys()).map((x) => ({
  value: x,
  text: x,
}));

export const CITIZENSHIP = [
  {
    value: "Yes",
    text: "Yes",
  },
  {
    value: "No",
    text: "No",
  },
];

export const SSN_MASK = "999 99 9999";
export const MOBILE_MASK = "(999) 999 9999";

export const isProducitonEnv =
  window.location.host === "flicportal.com" ||
  window.location.host === "www.flicportal.com";
export const isStagingEnv =
  window.location.host === "ui.staging.flicportal.com";

export const LOAN_PRODUCTS_LABEL = {
  2: "Flic A+ 2 YR No Payment (Risk Based Pricing, Battery Only OK, TX Only)",
  9: "Flic NASA 20 YR 5.99",
  10: "Flic NASA 20 YR 7.49",
  12: "Flic NASA 20 YR 8.99% (SAC)",
  101: "Flic A+ 20 YR (Risk Based Pricing, TX Only)",
  300: "Flic TDECU 20 YR 1.49% (TX Only)",
  301: "Flic TDECU 20 YR 1.99% (TX Only)",
  302: "Flic TDECU 20 YR 3.99% (TX Only)",
  303: "Flic TDECU 20 YR 6.99% (TX Only)",
  304: "Flic TDECU 15 YR 7.99% (Battery Only OK, TX Only)",
  401: "Flic Almika 25 YR 3.74% (TX Only)",
  402: "Flic Almika 25 YR 4.74% (TX Only)",
  403: "Flic Almika 25 YR 5.24% (TX Only)",
  1001: "Flic Sungage 25 YR 3.99%",
  1002: "Flic Sungage 25 YR 5.99%",
  1003: "Flic Sungage 25 YR 6.99%",
  1004: "Flic Sungage 25 YR 8.99%",
  1005: "Flic Sungage 10 YR 7.99% (Battery Only OK)",
  1006: "Flic Sungage 10 YR 9.99% (Battery Only OK)",
} as const;

export type ProgramTypeCode = keyof typeof LOAN_PRODUCTS_LABEL;

export const CORE_DL4_PROGRAM_TYPES: ProgramTypeCode[] = [
  2, 9, 10, 12, 101, 300, 301, 302, 303, 304,
];

export const DISCONTINUED_PRODUCTS = [
  {
    value: 1,
    text: "5 Year Interest Only (Texas Only)",
    visible: true,
  },
  {
    value: 5,
    text: "20 year fixed rate - .99% Interest",
    visible: true,
  },
  {
    value: 6,
    text: "20 year fixed rate - 1.49% Interest",
    visible: true,
  },
  {
    value: 7,
    text: "20 year fixed rate - 1.99% Interest",
    visible: true,
  },
  {
    value: 11,
    text: "20 year fixed rate - 2.49% Interest - NASA",
    visible: true,
  },
  {
    value: 8,
    text: "20 year fixed rate - 2.99% Interest - NASA",
    visible: true,
  },
  {
    value: 9,
    text: LOAN_PRODUCTS_LABEL[9],
    visible: true,
  },
  {
    value: 10,
    text: LOAN_PRODUCTS_LABEL[10],
    visible: true,
  },
  {
    value: 2,
    text: LOAN_PRODUCTS_LABEL[2],
    visible: true,
  },
];

export const PROGRAM_TYPE = [
  // { value: 3, text: "20 year fixed rate - 0% Interest", visible: false },
  // { value: 4, text: "20 year fixed rate - .49% Interest", visible: false },
  // { value: 201, text: "25 year fixed rate - 2.49% Interest", visible: false },
];

export const PRODUCT_10_YEAR_8_99_INTEREST = "product10yearFixed8p99interest";
export const PRODUCT_15_YEAR_8_99_INTEREST = "product15yearFixed8p99interest";
export const PRODUCT_25_YEAR_3_99_INTEREST = "product25yearFixed3p99interest";
export const PRODUCT_25_YEAR_4_99_INTEREST = "product25yearFixed4p99interest";
export const PRODUCT_25_YEAR_6_99_INTEREST = "product25yearFixed6p99interest";
export const PRODUCT_20_YEAR_2_49_INTEREST = "product20yearFixed2p49interest";
export const PRODUCT_20_YEAR_2_99_INTEREST = "product20yearFixed2p99interest";
export const PRODUCT_20_YEAR_5_99_INTEREST = "product20yearFixed5p99interest";
export const PRODUCT_20_YEAR_7_49_INTEREST = "product20yearFixed7p49interest";
export const PRODUCT_20_YEAR_8_99_INTEREST =
  "product20yearNoDealerFee8p99interest";
export const PRODUCT_20_YEAR_RISK_BASED_PRICING =
  "product20yearFixedRateRiskBasedPricing";
// TDECU products
export const PRODUCT_20_YEAR_1_49_INTEREST = "product20yearFixed1p49interest";
export const PRODUCT_20_YEAR_1_99_INTEREST = "product20yearFixed1p99interest";
export const PRODUCT_20_YEAR_3_99_INTEREST = "product20yearFixed3p99interest";
export const PRODUCT_20_YEAR_6_99_INTEREST = "product20yearFixed6p99interest";
export const PRODUCT_15_YEAR_7_99_INTEREST = "product15yearFixed7p99interest";
export const PRODUCT_2_YEAR_NO_PAYMENT_RISK_BASED_PRICING =
  "product2yearRiskBasedPricing";
// Almika products
export const PRODUCT_25_YEAR_3_74_INTEREST = "product25yearFixed3p74interest";
export const PRODUCT_25_YEAR_4_74_INTEREST = "product25yearFixed4p74interest";
export const PRODUCT_25_YEAR_5_24_INTEREST = "product25yearFixed5p24interest";

/**Sungage products portal id */
export const PRODUCT_SG_25_YEAR_3_99_INTEREST =
  "productSG25yearFixed3p99interest";
export const PRODUCT_SG_25_YEAR_5_99_INTEREST =
  "productSG25yearFixed5p99interest";
export const PRODUCT_SG_25_YEAR_6_99_INTEREST =
  "productSG25yearFixed6p99interest";
export const PRODUCT_SG_25_YEAR_8_99_INTEREST =
  "productSG25yearFixed8p99interest";
export const PRODUCT_SG_10_YEAR_7_99_INTEREST =
  "productSG10yearFixed7p99interest";
export const PRODUCT_SG_10_YEAR_9_99_INTEREST =
  "productSG10yearFixed9p99interest";

export const PRIVATE_PROGRAM_TYPE: Array<{
  id: string;
  text: string;
  value?: number;
  type: "EXTERNAL" | "DL4_PRODUCT" | "SUNGAGE_PRODUCT";
}> = [
  // NOTE: Kept this external product for reference
  // {
  //   id: PRODUCT_10_YEAR_8_99_INTEREST,
  //   text: "10 year fixed rate - 8.99% Interest - SPF",
  //   type: "EXTERNAL",
  // },
  {
    id: PRODUCT_15_YEAR_7_99_INTEREST,
    value: 304,
    text: LOAN_PRODUCTS_LABEL[304],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_20_YEAR_8_99_INTEREST,
    value: 12,
    text: LOAN_PRODUCTS_LABEL[12],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_20_YEAR_RISK_BASED_PRICING,
    value: 101,
    text: LOAN_PRODUCTS_LABEL[101],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_20_YEAR_1_49_INTEREST,
    value: 300,
    text: LOAN_PRODUCTS_LABEL[300],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_20_YEAR_1_99_INTEREST,
    value: 301,
    text: LOAN_PRODUCTS_LABEL[301],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_20_YEAR_3_99_INTEREST,
    value: 302,
    text: LOAN_PRODUCTS_LABEL[302],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_20_YEAR_6_99_INTEREST,
    value: 303,
    text: LOAN_PRODUCTS_LABEL[303],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_SG_25_YEAR_3_99_INTEREST,
    value: 1001,
    text: LOAN_PRODUCTS_LABEL[1001],
    type: "SUNGAGE_PRODUCT" as "SUNGAGE_PRODUCT",
  },
  {
    id: PRODUCT_SG_25_YEAR_5_99_INTEREST,
    value: 1002,
    text: LOAN_PRODUCTS_LABEL[1002],
    type: "SUNGAGE_PRODUCT" as "SUNGAGE_PRODUCT",
  },
  {
    id: PRODUCT_SG_25_YEAR_6_99_INTEREST,
    value: 1003,
    text: LOAN_PRODUCTS_LABEL[1003],
    type: "SUNGAGE_PRODUCT" as "SUNGAGE_PRODUCT",
  },
  {
    id: PRODUCT_SG_25_YEAR_8_99_INTEREST,
    value: 1004,
    text: LOAN_PRODUCTS_LABEL[1004],
    type: "SUNGAGE_PRODUCT" as "SUNGAGE_PRODUCT",
  },
  {
    id: PRODUCT_SG_10_YEAR_7_99_INTEREST,
    value: 1005,
    text: LOAN_PRODUCTS_LABEL[1005],
    type: "SUNGAGE_PRODUCT" as "SUNGAGE_PRODUCT",
  },
  {
    id: PRODUCT_SG_10_YEAR_9_99_INTEREST,
    value: 1006,
    text: LOAN_PRODUCTS_LABEL[1006],
    type: "SUNGAGE_PRODUCT" as "SUNGAGE_PRODUCT",
  },
  {
    id: PRODUCT_25_YEAR_3_74_INTEREST,
    value: 401,
    text: LOAN_PRODUCTS_LABEL[401],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_25_YEAR_4_74_INTEREST,
    value: 402,
    text: LOAN_PRODUCTS_LABEL[402],
    type: "DL4_PRODUCT",
  },
  {
    id: PRODUCT_25_YEAR_5_24_INTEREST,
    value: 403,
    text: LOAN_PRODUCTS_LABEL[403],
    type: "DL4_PRODUCT",
  },
  // NOTE: Kept this external product for reference
  ...(!isProducitonEnv
    ? [
        {
          id: PRODUCT_10_YEAR_8_99_INTEREST,
          text: "10 year fixed rate - 8.99% Interest - SPF",
          type: "EXTERNAL" as "EXTERNAL",
        },
      ]
    : []),
];

export const INSTALLATION_RESIDENCY = [
  { value: 1, text: "Primary Residence" },
  { value: 2, text: "Vacation Home" },
  { value: 3, text: "Investment Property" },
];
export const OWNERSHIP_LIST = [
  {
    value: 1,
    text: "The home is owned by myself or a coborrower on this application.",
  },
  {
    value: 2,
    text: "The home is held in a trust and myself or a coborrower are trustees.",
  },
  {
    value: 3,
    text: "The home is owned by a LLC that myself or a coborrower has ownership in.",
  },
  {
    value: 4,
    text: "The home is not owned by myself or a coborrower on this application.",
  },
];

export const HOME_OWNERSHIP_LIST = [
  {
    value: 1,
    text: "Property owned by primary applicant",
  },
  {
    value: 2,
    text: "Property owned by co-applicant",
  },
  {
    value: 3,
    text: "Property owned by applicant and co-applicant",
  },
  {
    value: 4,
    text: "Property held in living or revocable trust",
  },
  {
    value: 5,
    text: "Property held in irrevocable trust",
  },
  {
    value: 6,
    text: "Property owned by corporation or LLC",
  },
  {
    value: 7,
    text: "Other",
  },
];

export const DL4_HOME_OWNERSHIP = {
  1: 1, // primary applicant
  2: 1, // co-applicant
  3: 1, // owned by applicant and co-applicant
  4: 2, // held in living or revocable trust
  5: 2, // irrevocable trust
  6: 3, // corporation or LLC
  7: 4, // Other
} as const;

export type Dl4HomeOwnershipKeyType = keyof typeof DL4_HOME_OWNERSHIP;

export const PROPERTY_TYPE_LIST = [
  {
    value: 1,
    text: "Single-Family",
  },
  {
    value: 2,
    text: "Mobile Home",
  },
  {
    value: 3,
    text: "Manufactured Home",
  },
  {
    value: 4,
    text: "Multi-Family (2-4 Units)",
  },
  {
    value: 5,
    text: "Multi-Family (5+ Units)",
  },
  {
    value: 6,
    text: "Commercial",
  },
];

export const REVERSE_MORTGAGE_LIST = [
  {
    value: true,
    text: "Yes",
  },
  {
    value: false,
    text: "No",
  },
];

export enum EMPLOYMENT_STATUS_LABEL {
  EMPLOYED = "Employed",
  RETIRED = "Retired",
  SELF_EMPLOYED = "Self Employed",
  UNEMPLOYED = "Unemployed",
}

export const EMPLOYMENT_STATUS_LIST = [
  {
    value: EmploymentStatus.EMPLOYED,
    text: EMPLOYMENT_STATUS_LABEL.EMPLOYED,
  },
  {
    value: EmploymentStatus.RETIRED,
    text: EMPLOYMENT_STATUS_LABEL.RETIRED,
  },
  {
    value: EmploymentStatus.SELF_EMPLOYED,
    text: EMPLOYMENT_STATUS_LABEL.SELF_EMPLOYED,
  },
  {
    value: EmploymentStatus.UNEMPLOYED,
    text: EMPLOYMENT_STATUS_LABEL.UNEMPLOYED,
  },
];

export const TIME_ON_JOB_TEXT = {
  [EmploymentStatus.EMPLOYED]: "Time with Current Employer",
  [EmploymentStatus.RETIRED]: "Time Retired",
  [EmploymentStatus.UNEMPLOYED]: "Time Unemployed",
  [EmploymentStatus.SELF_EMPLOYED]: "Time in Business",
};

export const OWNERSHIP_SHORT_LIST = [
  { value: 1, text: "Owner" },
  { value: 2, text: "Trust" },
  { value: 3, text: "LLC" },
  { value: 4, text: "Non Owner" },
];

export const STIPULATION_ALLOWED =
  "image/jpg,image/jpeg,image/png,.pdf,.tiff,.tif,.bmp,.gif,.eps";

export const APPLICATION_STEPS = [
  "PreQual",
  "Full Application",
  "Submit Stipulations",
  "Upload ID",
  "Upload Contract",
  "Issue Docs",
];

export const DATE_FORMAT = "MMMM Do YYYY";

export const VISIBLE_RECENT_ITEM = 3;

export const USER_ROLES = [
  {
    value: UserRole.eneryConsultant,
    text: UserRoleLabel["energy-consultant"],
  },
  {
    value: UserRole.regionalManager,
    text: UserRoleLabel["regional-manager"],
  },
  {
    value: UserRole.providerAdmin,
    text: UserRoleLabel["provider-admin"],
  },
  // { value: "credit-union", text: "Credit Union" },
  {
    value: UserRole.financialInstitution,
    text: UserRoleLabel["financial-institution"],
  },
  {
    value: UserRole.enterpriseManager,
    text: UserRoleLabel["enterprise-manager"],
  },
];

export const STATES: Record<string, string> = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const DATE_FORMATS = [
  "MM/dd/yyyy",
  "MMddyy",
  "MMddyyyy",
  "Mddyy",
  "M-d.yy",
];

export const USER_STATUS_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Deactivated", value: "deactivated" },
  { label: "Inactive", value: "inactive" },
];

export const COUNTRY_CODE_OPTIONS = [
  { label: "US (+1)", value: "+1" },
  { label: "India (+91)", value: "+91" },
];

/**
 * API_PATHS_WITHOUT_LOADER_STATE returns the API paths which we do not need to maintain app.slice.loader state management while we make API calls.
 */
export const API_PATHS_WITHOUT_LOADER_STATE = [
  // GET Issue loan docs type API
  "application/loan/type",
  "regions",
  "dealers",
  "lenders",
  "awardco/balance",
  "application/sungage-disclosures",
];

export const API_SLICES_WITHOUT_LOADER_STATE = [
  "commonData/getTypes",
  "awardco/getAwardcoBalance",
  "region/getRegions",
  "app/dealers",
  "app/lenders",
  "app/disclosures",
];

export const CLIENT_CREDENTIALS_ACCESS_ROLES = [
  UserRole.financialInstitution,
  UserRole.enterpriseManager,
];

// The users who will be created after the below date will be enforced to setup the MFA on thier account
export const mfaEnforceDateForNewUser = new Date("2024-05-29");

export const DEFAULT_COUNTRY_CODE = `+1`;

export enum DEALER_LABEL {
  FREEDOM_SOLAR = "FREEDOM_SOLAR",
  ION_SOLAR = "ION_SOLAR",
  VENTURE_SOLAR = "VENTURE_SOLAR",
}

export const DEALERS = {
  FREEDOM_SOLAR: isProducitonEnv ? "2689812288120" : "2136015439160",
  ION_SOLAR: isProducitonEnv ? "3088039371910" : "2897881890914",
  VENTURE_SOLAR: isProducitonEnv ? "2939684132011" : "2857678360557",
} as const;

const PROGRAM_TYPE_2_RATES = {
  [DEALERS.FREEDOM_SOLAR]: {
    Rate: {
      1: 9.99,
      2: 9.99,
      3: 10.99,
      4: 11.99,
      5: 12.99,
      NA: 12.99,
    },
    IntroRate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      NA: 0,
    },
    FinalRate: {
      1: 9.99,
      2: 9.99,
      3: 10.99,
      4: 11.99,
      5: 12.99,
      NA: 12.99,
    },
  },
  [DEALERS.ION_SOLAR]: {
    Rate: {
      1: 8.99,
      2: 8.99,
      3: 9.99,
      4: 10.99,
      5: 11.99,
      NA: 11.99,
    },
    IntroRate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      NA: 0,
    },
    FinalRate: {
      1: 8.99,
      2: 8.99,
      3: 9.99,
      4: 10.99,
      5: 11.99,
      NA: 11.99,
    },
  },
  [DEALERS.VENTURE_SOLAR]: {
    Rate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      NA: 0,
    },
    IntroRate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      NA: 0,
    },
    FinalRate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      NA: 0,
    },
  },
};
const PROGRAM_TYPE_101_RATES = {
  [DEALERS.FREEDOM_SOLAR]: {
    Rate: {
      1: 7.99,
      2: 7.99,
      3: 8.99,
      4: 8.99,
      5: 8.99,
      NA: 8.99,
    },
    IntroRate: {
      1: 7.99,
      2: 7.99,
      3: 8.99,
      4: 8.99,
      5: 8.99,
      NA: 8.99,
    },
    FinalRate: {
      1: 7.99,
      2: 7.99,
      3: 8.99,
      4: 8.99,
      5: 8.99,
      NA: 8.99,
    },
  },
  [DEALERS.ION_SOLAR]: {
    Rate: {
      1: 7.99,
      2: 7.99,
      3: 8.99,
      4: 8.99,
      5: 8.99,
      NA: 8.99,
    },
    IntroRate: {
      1: 7.99,
      2: 7.99,
      3: 8.99,
      4: 8.99,
      5: 8.99,
      NA: 8.99,
    },
    FinalRate: {
      1: 7.99,
      2: 7.99,
      3: 8.99,
      4: 8.99,
      5: 8.99,
      NA: 8.99,
    },
  },
  [DEALERS.VENTURE_SOLAR]: {
    Rate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      NA: 0,
    },
    IntroRate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      NA: 0,
    },
    FinalRate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      NA: 0,
    },
  },
};

export const LOAN_PRODUCTS_CLASSIFICATION: Record<
  number,
  IXPrequalProductsWithDynamicFields
> = {
  2: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[2],
    xprogramtype: 2,
    requestedTerm: 180,
    term: 180,
    xinitialTerm: 24,
    xAmortizationTerm: 180,
    defaultCarletonDATFileName: "TX0APLUS0002YR01",
    xNumOfIntOnlyPmts: 24,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 1,
    firstPaymentDateStandard: toTimestamp(moment()),
    contractDateStandard: toTimestamp(moment()),
    xMembershipFee: 20,
    xFlicFlatFee: 0,
    userRate: (dealer) => null,
    xInitialRate: (dealer) => null,
    xFinalRate: (dealer) => null,
    xRiskBasedRateTable: (dealer) => {
      return PROGRAM_TYPE_2_RATES[dealer || DEALERS.FREEDOM_SOLAR].Rate;
    },
    xRisckBasedIntialRateTable: (dealer) => {
      return PROGRAM_TYPE_2_RATES[dealer || DEALERS.FREEDOM_SOLAR].IntroRate;
    },
    xFlicPlatformFeePrecent: (dealer) => {
      switch (dealer) {
        case DEALERS.FREEDOM_SOLAR:
        default:
          return 3;
        case DEALERS.ION_SOLAR:
          return 3.25;
      }
    },
    xSellerPointsPercent: (dealer) => {
      switch (dealer) {
        case DEALERS.FREEDOM_SOLAR:
        default:
          return 9;
        case DEALERS.ION_SOLAR:
          return 9.75;
      }
    },
  },
  9: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[9],
    xprogramtype: 9,
    requestedTerm: 240,
    term: 240,
    xinitialTerm: 17,
    xAmortizationTerm: 240,
    defaultCarletonDATFileName: "MDNASACU00000001",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 2,
    firstPaymentDateStandard: toTimestamp(moment().add(90, "d")),
    contractDateStandard: toTimestamp(moment().add(30, "d")),
    xFlicPlatformFeePrecent: () => 2.94,
    xSellerPointsPercent: () => 18.06,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 5.99,
    xInitialRate: () => 5.99,
    xFinalRate: () => 5.99,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  10: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[10],
    xprogramtype: 10,
    requestedTerm: 240,
    term: 240,
    xinitialTerm: 17,
    xAmortizationTerm: 240,
    defaultCarletonDATFileName: "MDNASACU00000001",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 2,
    firstPaymentDateStandard: toTimestamp(moment().add(90, "d")),
    contractDateStandard: toTimestamp(moment().add(30, "d")),
    xFlicPlatformFeePrecent: () => 3.37,
    xSellerPointsPercent: () => 9.13,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 7.49,
    xInitialRate: () => 7.49,
    xFinalRate: () => 7.49,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  12: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[12],
    xprogramtype: 12,
    requestedTerm: 240,
    term: 240,
    xinitialTerm: 17,
    xAmortizationTerm: 240,
    defaultCarletonDATFileName: "MDNASACU00000001",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 2,
    firstPaymentDateStandard: toTimestamp(moment().add(90, "d")),
    contractDateStandard: toTimestamp(moment().add(30, "d")),
    xFlicPlatformFeePrecent: () => 0,
    xSellerPointsPercent: () => 0,
    xMembershipFee: 0,
    xFlicFlatFee: 600,
    userRate: () => 8.99,
    xInitialRate: () => 8.99,
    xFinalRate: () => 8.99,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  101: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[101],
    xprogramtype: 101,
    requestedTerm: 240,
    term: 240,
    xinitialTerm: 18,
    xAmortizationTerm: 240,
    defaultCarletonDATFileName: "TX0APLUS0002YR01",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 1,
    firstPaymentDateStandard: toTimestamp(moment().add(60, "d")),
    contractDateStandard: toTimestamp(moment()),
    xMembershipFee: 20,
    xFlicFlatFee: 0,
    xFlicPlatformFeePrecent: (dealer) => {
      switch (dealer) {
        case DEALERS.FREEDOM_SOLAR:
        default:
          return 3;
        case DEALERS.ION_SOLAR:
          return 4.5;
      }
    },
    xSellerPointsPercent: (dealer) => {
      switch (dealer) {
        case DEALERS.FREEDOM_SOLAR:
        default:
          return 3.2;
        case DEALERS.ION_SOLAR:
          return 4.8;
      }
    },
    userRate: (dealer) => null,
    xInitialRate: (dealer) => null,
    xFinalRate: (dealer) => null,
    xRiskBasedRateTable: (dealer) => {
      return PROGRAM_TYPE_101_RATES[dealer || DEALERS.FREEDOM_SOLAR].Rate;
    },
    xRisckBasedIntialRateTable: (dealer) => {
      return PROGRAM_TYPE_101_RATES[dealer || DEALERS.FREEDOM_SOLAR].IntroRate;
    },
  },
  300: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[300],
    xprogramtype: 300,
    requestedTerm: 240,
    term: 240,
    xinitialTerm: 17,
    xAmortizationTerm: 240,
    defaultCarletonDATFileName: "SSTDECU000000001",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 3,
    firstPaymentDateStandard: toTimestamp(moment().add(120, "d")),
    contractDateStandard: toTimestamp(moment().add(90, "d")),
    xFlicPlatformFeePrecent: () => 1.91,
    xSellerPointsPercent: () => 40.59,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 1.49,
    xInitialRate: () => 1.49,
    xFinalRate: () => 1.49,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  301: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[301],
    xprogramtype: 301,
    requestedTerm: 240,
    term: 240,
    xinitialTerm: 17,
    xAmortizationTerm: 240,
    defaultCarletonDATFileName: "SSTDECU000000001",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 3,
    firstPaymentDateStandard: toTimestamp(moment().add(120, "d")),
    contractDateStandard: toTimestamp(moment().add(90, "d")),
    xFlicPlatformFeePrecent: () => 2,
    xSellerPointsPercent: () => 38,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 1.99,
    xInitialRate: () => 1.99,
    xFinalRate: () => 1.99,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  302: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[302],
    xprogramtype: 302,
    requestedTerm: 240,
    term: 240,
    xinitialTerm: 17,
    xAmortizationTerm: 240,
    defaultCarletonDATFileName: "SSTDECU000000001",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 3,
    firstPaymentDateStandard: toTimestamp(moment().add(120, "d")),
    contractDateStandard: toTimestamp(moment().add(90, "d")),
    xFlicPlatformFeePrecent: () => 2.61,
    xSellerPointsPercent: () => 26.39,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 3.99,
    xInitialRate: () => 3.99,
    xFinalRate: () => 3.99,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  303: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[303],
    xprogramtype: 303,
    requestedTerm: 240,
    term: 240,
    xinitialTerm: 17,
    xAmortizationTerm: 240,
    defaultCarletonDATFileName: "SSTDECU000000001",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 3,
    firstPaymentDateStandard: toTimestamp(moment().add(120, "d")),
    contractDateStandard: toTimestamp(moment().add(90, "d")),
    xFlicPlatformFeePrecent: () => 3.22,
    xSellerPointsPercent: () => 8.28,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 6.99,
    xInitialRate: () => 6.99,
    xFinalRate: () => 6.99,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  304: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[304],
    xprogramtype: 304,
    requestedTerm: 180,
    term: 180,
    xinitialTerm: 17,
    xAmortizationTerm: 180,
    defaultCarletonDATFileName: "SSTDECU000000001",
    xNumOfIntOnlyPmts: 0,
    xFinalPmtDiff: false,
    xHasIntroPeriod: true,
    xLender: 3,
    firstPaymentDateStandard: toTimestamp(moment().add(120, "d")),
    contractDateStandard: toTimestamp(moment().add(90, "d")),
    xFlicPlatformFeePrecent: () => 2.5,
    xSellerPointsPercent: () => 2.5,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 7.99,
    xInitialRate: () => 7.99,
    xFinalRate: () => 7.99,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  401: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[401],
    xprogramtype: 401,
    requestedTerm: 299,
    term: 299,
    xinitialTerm: 17,
    xAmortizationTerm: 299,
    defaultCarletonDATFileName: "TXSUNALM00000001",
    xNumOfIntOnlyPmts: 0, // Need to confirm this.
    xFinalPmtDiff: false,
    xHasIntroPeriod: true, // Need to confirm this
    xLender: 4,
    firstPaymentDateStandard: toTimestamp(moment().add(90, "d")),
    contractDateStandard: toTimestamp(moment().add(30, "d")),
    xFlicPlatformFeePrecent: () => 1.5,
    xSellerPointsPercent: () => 34.25,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 3.74,
    xInitialRate: () => 3.74,
    xFinalRate: () => 3.74,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  402: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[402],
    xprogramtype: 402,
    requestedTerm: 299,
    term: 299,
    xinitialTerm: 17,
    xAmortizationTerm: 299,
    defaultCarletonDATFileName: "TXSUNALM00000001",
    xNumOfIntOnlyPmts: 0, // Need to confirm this.
    xFinalPmtDiff: false,
    xHasIntroPeriod: true, // Need to confirm this
    xLender: 4,
    firstPaymentDateStandard: toTimestamp(moment().add(90, "d")),
    contractDateStandard: toTimestamp(moment().add(30, "d")),
    xFlicPlatformFeePrecent: () => 1.5,
    xSellerPointsPercent: () => 29.13,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 4.74,
    xInitialRate: () => 4.74,
    xFinalRate: () => 4.74,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
  403: {
    xProgramTypeName: LOAN_PRODUCTS_LABEL[403],
    xprogramtype: 403,
    requestedTerm: 299,
    term: 299,
    xinitialTerm: 17,
    xAmortizationTerm: 299,
    defaultCarletonDATFileName: "TXSUNALM00000001",
    xNumOfIntOnlyPmts: 0, // Need to confirm this.
    xFinalPmtDiff: false,
    xHasIntroPeriod: true, // Need to confirm this
    xLender: 4,
    firstPaymentDateStandard: toTimestamp(moment().add(90, "d")),
    contractDateStandard: toTimestamp(moment().add(30, "d")),
    xFlicPlatformFeePrecent: () => 1.5,
    xSellerPointsPercent: () => 26.76,
    xMembershipFee: 0,
    xFlicFlatFee: 0,
    userRate: () => 5.24,
    xInitialRate: () => 5.24,
    xFinalRate: () => 5.24,
    xRiskBasedRateTable: () => null,
    xRisckBasedIntialRateTable: () => null,
  },
};

export const QUERY_PARAMS_KEYS = {
  REQUEST_DETAILS_TAB: "t",
  REQUEST_DETAILS_SUB_TAB: "st",
};

export const LOAN_DOC_STATUS_NOT_AVAILABLE =
  "Loan docs status is not available for this loan.";
