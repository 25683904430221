import { REGEX, STATES, USER_ROLES } from "@shared/constants/app.constant";
import * as yup from "yup";
import trim from "lodash/trim";
import { UserRole } from "@shared/interfaces/User";
import { EmploymentStatus } from "@shared/enums/EmploymentStatus";
import { transformNaN } from "@shared/helpers/schema.helper";
import moment from "moment";
import { isSungageLoanProduct } from "@shared/helpers/application.helper";

export const newRequestSchema = yup.object().shape({
  isModify: yup.boolean().default(false),
  moduleType: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Module Number is required")
          .typeError("Module Number is required");

      return schema;
    }),
  panelCost: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .test(
      "panelCost-required-test",
      "Panel cost is required",
      (value, { parent }) => {
        return !(
          isSungageLoanProduct(parent.loanType) &&
          parent.moduleType !== 0 &&
          value === undefined
        );
      }
    ),
  panelQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .min(1, "Panel quantity must be greater than 0")
    .test(
      "panelQuantity-required-test",
      "Panel quantity is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.moduleType !== 0 &&
          value === undefined
        )
    ),
  batteryType: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Battery Type is required")
          .typeError("Battery Type is required");

      return schema;
    }),
  batteryCost: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .max(
      yup.ref("loanAmount"),
      ({ max }) =>
        `Battery cost cannot be greater than the loan amount ($${max})`
    )
    .test(
      "batteryCost-required-test",
      "Battery cost is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.batteryType !== 0 &&
          parent.batteryExtensionType !== 0 &&
          value === undefined
        )
    ),
  batteryQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .min(1, "Battery quantity must be greater than 0")
    .test(
      "batteryQuantity-required-test",
      "Battery quantity is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.batteryType !== 0 &&
          value === undefined
        )
    ),
  batteryExtensionType: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Battery Extension Model is required")
          .typeError("Battery Extension Model is required");

      return schema;
    }),
  batteryExtensionQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .min(1, "Battery extension quantity must be greater than 0")
    .test(
      "batteryExtensionQuantity-required-test",
      "Battery extension quantity is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.batteryExtensionType !== 0 &&
          value === undefined
        )
    ),
  totalBatterySize: yup.number().when("loanType", (loanType, schema) => {
    if (isSungageLoanProduct(loanType))
      return schema
        .transform(transformNaN)
        .nullable()
        .max(75, "Total battery size must be equal to or less than 75kWh");

    return schema;
  }),
  loanType: yup.string().required("Loan Product is required."),
  loanAmount: yup
    .number()
    .transform(transformNaN)
    .typeError("Loan Amount is required.")
    .required("Loan Amount is required.")
    .min(1, "Loan Amount amount must be greather than 0")
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .transform(transformNaN)
          .min(7500, "Mimumum loan amount is $7500")
          .max(150000, "Maximum loan amount is $150,000");

      return schema;
    }),
  installationHomeOwner: yup
    .number()
    .required("Home Ownership is required.")
    .typeError("Home Ownership is required."),
  installationAddressIsDifferent: yup.boolean(),
  installationAddressLine: yup.string().when("installationAddressIsDifferent", {
    is: true,
    then: yup.string().required("Street is required."),
  }),
  installationCity: yup.string().when("installationAddressIsDifferent", {
    is: true,
    then: yup.string().required("City is required."),
  }),
  installationState: yup.string().when("installationAddressIsDifferent", {
    is: true,
    then: yup
      .string()
      .required("State is required.")
      .max(2, "State is invalid")
      .matches(REGEX.ALPHABETS, "State is invalid."),
  }),
  installationZipCode: yup.string().when("installationAddressIsDifferent", {
    is: true,
    then: yup
      .string()
      .required("Zip Code is required.")
      .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
      .max(5, "Zip Code is invalid"),
  }),
  propertyType: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .required("Property Type is required")
    .typeError("Property Type is required"),
  reverseMortgage: yup
    .boolean()
    .transform((value) => (value === "" ? undefined : value))
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Reverse Mortgage is required")
          .typeError("Reverse mortgage is required");

      return schema;
    }),
  mortgagePayment: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Mortgage Payment is required.")
          .typeError("Mortgage Payment is required.");

      return schema;
    }),
  installationResidency: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("installationAddressIsDifferent", {
      is: true,
      then: yup
        .number()
        .required("Occupancy of the installation property is required.")
        .min(1, "Occupancy of the installation property is required."),
    }),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  SSN: yup.string().when("isModify", {
    is: false,
    then: yup
      .string()
      .required("SSN is required.")
      .transform((value) => value.replace(REGEX.SSN, ""))
      .min(9, "SSN should of length 9")
      .max(9, "SSN should of length 9"),
  }),
  dateOfBirth: yup.string().when("isModify", {
    is: false,
    then: yup
      .string()
      .required("Date of Birth is required.")
      .when("loanType", (loanType, schema) => {
        if (isSungageLoanProduct(loanType))
          return schema.test(
            "older-than-18-test",
            "Applicants must be over 18 years old.",
            (value: string) => moment().diff(value, "years") >= 18
          );

        return schema;
      }),
  }),

  email: yup
    .string()
    .required("Email is required.")
    .matches(REGEX.EMAIL, "Please enter a valid email"),
  mobilePhone: yup.string().required("Mobile Phone is required."),
  isUSCitizen: yup.string().required("Field is required."),
  xIsPermanentResident: yup.string().when("isUSCitizen", {
    is: "No",
    then: yup.string().required("Field is required."),
  }),

  addressLine1: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup
    .string()
    .required("State is required.")
    .max(2, "State is invalid")
    .matches(REGEX.ALPHABETS, "State is invalid."),
  postalCode: yup
    .string()
    .required("Zip Code is required.")
    .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
    .max(5, "Zip Code is invalid"),

  grossIncome: yup
    .number()
    .typeError("Annual Income is required.")
    .required("Annual Income is required.")
    .min(1, "Annual Income amount must be greather than 0"),
  employmentStatus: yup
    .number()
    .min(1, "Please select a valid option")
    .max(4, "Please select a valid option")
    .required("Employment Status is required.")
    .typeError("Employment Status is required."),
  employerName: yup
    .string()
    .label("Employer Name")
    .when("employmentStatus", {
      is: EmploymentStatus.EMPLOYED,
      then: yup.string().required("Employer Name is required."),
    }),
  title: yup
    .string()
    .test("employment-title-test", "Title is required", (value, { parent }) => {
      return !(
        parent.employmentStatus === EmploymentStatus.EMPLOYED &&
        isSungageLoanProduct(parent.loanType) &&
        value === ""
      );
    }),
  yearsEmployed: yup
    .string()
    .label("Years employed")
    .required("Years employed is required"),
  monthsEmployed: yup
    .string()
    .label("Months employed")
    .required("Months employed is required"),

  hasCoApplicant: yup.string(),

  coBorrowerFirstName: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup.string().required("First Name is required."),
  }),
  coBorrowerLastName: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup.string().required("Last Name is required."),
  }),
  coBorrowerssn: yup
    .string()
    .when(["hasCoApplicant", "initialCoBorrowerData"], {
      is: (hasCoApplicant: string, initialCoBorrowerData: boolean) =>
        hasCoApplicant === "Yes" && !initialCoBorrowerData,
      then: yup
        .string()
        .required("SSN is required.")
        .transform((value) => value.replace(REGEX.SSN, ""))
        .min(9, "SSN should of length 9")
        .max(9, "SSN should of length 9"),
    }),
  coBorrowerdob: yup
    .string()
    .when(["hasCoApplicant", "initialCoBorrowerData"], {
      is: (hasCoApplicant: string, initialCoBorrowerData: boolean) =>
        hasCoApplicant === "Yes" && !initialCoBorrowerData,
      then: yup.string().required("Date of Birth is required."),
    })
    .test(
      "older-than-18-test",
      "Applicants must be over 18 years old.",
      (value, { parent }) =>
        isSungageLoanProduct(parent.loanType) && parent.hasCoApplicant === "Yes"
          ? moment().diff(value, "years") >= 18
          : true
    ),
  coBorrowerMobilePhone: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup.string().required("Mobile Phone is required."),
  }),
  coBorrowerEmail: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup
      .string()
      .required("Email is required.")
      .notOneOf(
        [yup.ref("email")],
        "Co-Borrower email should be different than borrower's email"
      )
      .matches(REGEX.EMAIL, "Please enter a valid email"),
  }),
  coBorrowerusCitizen: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup.string().required("Field is required."),
  }),
  coBorrowerxIsPermanentResident: yup.string().when("coBorrowerusCitizen", {
    is: "No",
    then: yup.string().required("Field is required."),
  }),
  coBorrowerGrossIncome: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup
      .string()
      .required("Annual Income is required.")
      .matches(
        REGEX.GREATER_THAN_0,
        "Annual Income amount must be greather than 0"
      ),
  }),
  coBorrowerEmploymentStatus: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("hasCoApplicant", {
      is: "Yes",
      then: yup
        .number()
        .min(1, "Please select a valid option")
        .max(4, "Please select a valid option")
        .required("Employment Status is required.")
        .typeError("Employment Status is required."),
    }),
  coBorrowerEmployerName: yup
    .string()
    .label("Employer Name")
    .test(
      "co-borrower-employment-name-test",
      "Employer name is required",
      (value, { parent }) => {
        return !(
          parent.coBorrowerEmploymentStatus === EmploymentStatus.EMPLOYED &&
          parent.hasCoApplicant === "Yes" &&
          value === ""
        );
      }
    ),
  coBorrowerTitle: yup
    .string()
    .test(
      "co-borrower-employment-title-test",
      "Title is required",
      (value, { parent }) => {
        return !(
          parent.coBorrowerEmploymentStatus === EmploymentStatus.EMPLOYED &&
          isSungageLoanProduct(parent.loanType) &&
          parent.hasCoApplicant === "Yes" &&
          value === ""
        );
      }
    ),
  coBorrowerYear: yup
    .string()
    .label("Years employed")
    .when("hasCoApplicant", {
      is: "Yes",
      then: yup.string().required("Years employed is required"),
    }),
  coBorrowerMonths: yup
    .string()
    .label("Months employed")
    .when("hasCoApplicant", {
      is: "Yes",
      then: yup.string().required("Months employed is required"),
    }),
  isMailingAddressDifferent: yup.boolean(),
  mailingAddressLine1: yup.string().when("isMailingAddressDifferent", {
    is: true,
    then: yup.string().required("Street is required."),
  }),

  mailingCity: yup.string().when("isMailingAddressDifferent", {
    is: true,
    then: yup.string().required("City is required."),
  }),
  mailingState: yup.string().when("isMailingAddressDifferent", {
    is: true,
    then: yup
      .string()
      .required("State is required.")
      .max(2, "State is invalid")
      .matches(REGEX.ALPHABETS, "State is invalid."),
  }),
  mailingZipCode: yup.string().when("isMailingAddressDifferent", {
    is: true,
    then: yup
      .string()
      .required("Zip Code is required.")
      .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
      .max(5, "Zip Code is invalid"),
  }),

  isDiffAddressThanPrimary: yup.string(),

  coBorrowerAddressLine1: yup.string().when("isDiffAddressThanPrimary", {
    is: "No",
    then: yup.string().required("Street is required."),
  }),
  coBorrowerCity: yup.string().when("isDiffAddressThanPrimary", {
    is: "No",
    then: yup.string().required("City is required."),
  }),
  coBorrowerState: yup.string().when("isDiffAddressThanPrimary", {
    is: "No",
    then: yup
      .string()
      .required("State is required.")
      .max(2, "State is invalid")
      .matches(REGEX.ALPHABETS, "State is invalid."),
  }),

  coBorrowerPostalCode: yup.string().when("isDiffAddressThanPrimary", {
    is: "No",
    then: yup
      .string()
      .required("Zip Code is required.")
      .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
      .max(5, "Zip Code is invalid"),
  }),
  isCoBorrowerMailingAddressDifferrent: yup.boolean(),
  coBorrowerMailingAddressLine: yup
    .string()
    .when("isCoBorrowerMailingAddressDifferrent", {
      is: true,
      then: yup.string().required("Street is required."),
    }),
  coBorrowerMailingCity: yup
    .string()
    .when("isCoBorrowerMailingAddressDifferrent", {
      is: true,
      then: yup.string().required("City is required."),
    }),
  coBorrowerMailingState: yup
    .string()
    .when("isCoBorrowerMailingAddressDifferrent", {
      is: true,
      then: yup
        .string()
        .required("State is required.")
        .oneOf(Object.keys(STATES), "State is invalid."),
    }),
  coBorrowerMailingZipCode: yup
    .string()
    .when("isCoBorrowerMailingAddressDifferrent", {
      is: true,
      then: yup
        .string()
        .required("Zip Code is required.")
        .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
        .max(5, "Zip Code is invalid"),
    }),
  authorization: yup
    .string()
    .required("Please confirm the credit authorization.")
    .oneOf(["true"], "Please confirm the credit authorization."),
  consent: yup
    .string()
    .required("Please confirm the consent.")
    .oneOf(["true"], "Please confirm the consent"),
  coBorrowerAuthorization: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup
      .string()
      .required("Please confirm the credit authorization.")
      .oneOf(["true"], "Please confirm the credit authorization."),
  }),
  coBorrowerConsent: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup
      .string()
      .required("Please confirm the consent.")
      .oneOf(["true"], "Please confirm the consent"),
  }),
  yearsAtResidence: yup.string().required().label("Years with residence"),
  monthsAtResidence: yup.string().required().label("Months with residence"),

  coBorrowerYearsAtResidence: yup.string().when("isDiffAddressThanPrimary", {
    is: "No",
    then: yup.string().required().label("Years with residence"),
  }),
  coBorrowerMonthsAtResidence: yup.string().when("isDiffAddressThanPrimary", {
    is: "No",
    then: yup.string().required().label("Months with residence"),
  }),
});

export const preQualSchema = yup.object().shape({
  loanType: yup.string().required("Loan Product is required."),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  SSN: yup
    .string()
    .required("SSN is required.")
    .transform((value) => value.replace(REGEX.SSN, ""))
    .min(9, "SSN should of length 9")
    .max(9, "SSN should of length 9"),
  dateOfBirth: yup.string().required("Date of Birth is required."),
  addressLine1: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup
    .string()
    .required("State is required.")
    .oneOf(Object.keys(STATES), "State is invalid."),
  postalCode: yup
    .string()
    .required("Zip Code is required.")
    .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
    .max(5, "Zip Code is invalid"),
  grossIncome: yup
    .number()
    .typeError("Annual Income is required.")
    .required("Annual Income is required.")
    .min(1, "Annual Income amount must be greather than 0"),

  hasCoApplicant: yup.string(),

  coBorrowerFirstName: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup.string().required("First Name is required."),
  }),
  coBorrowerLastName: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup.string().required("Last Name is required."),
  }),
  coBorrowerssn: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup
      .string()
      .required("SSN is required.")
      .transform((value) => value.replace(REGEX.SSN, ""))
      .min(9, "SSN should of length 9")
      .max(9, "SSN should of length 9"),
  }),
  coBorrowerdob: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup.string().required("Date of Birth is required."),
  }),
  coBorrowerGrossIncome: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup
      .string()
      .required("Annual Income is required.")
      .matches(
        REGEX.GREATER_THAN_0,
        "Annual Income amount must be greather than 0"
      ),
  }),

  //if coborrower different address
  xDiffAddressThanApplicant: yup.string(),

  coBorrowerAddressLine1: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required("Street is required."),
  }),
  coBorrowerCity: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required("City is required."),
  }),
  coBorrowerState: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup
      .string()
      .required("State is required.")
      .oneOf(Object.keys(STATES), "State is invalid."),
  }),

  coBorrowerPostalCode: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup
      .string()
      .required("Zip Code is required.")
      .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
      .max(5, "Zip Code is invalid"),
  }),

  authorization: yup
    .string()
    .required("Please confirm the credit authorization.")
    .oneOf(["true"], "Please confirm the credit authorization."),
  consent: yup
    .string()
    .required("Please confirm the consent.")
    .oneOf(["true"], "Please confirm the consent"),
  coBorrowerAuthorization: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup
      .string()
      .required("Please confirm the credit authorization.")
      .oneOf(["true"], "Please confirm the credit authorization."),
  }),
  coBorrowerConsent: yup.string().when("hasCoApplicant", {
    is: "Yes",
    then: yup
      .string()
      .required("Please confirm the consent.")
      .oneOf(["true"], "Please confirm the consent"),
  }),
  yearsAtResidence: yup.string().required().label("Years with residence"),
  monthsAtResidence: yup.string().required().label("Months with residence"),

  coBorrowerYearsAtResidence: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required().label("Years with residence"),
  }),
  coBorrowerMonthsAtResidence: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required().label("Months with residence"),
  }),
  isMultiPrequal: yup.boolean().label("Multi Prequal"),
});

export const sendLinkPreQualSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup
    .string()
    .required("Email Address is required.")
    .matches(REGEX.EMAIL, "Please enter a valid email"),
  mobileNumber: yup.string().required("Mobile Number is required."),
  loanType: yup.string().required("Loan Product is required."),
  loanAmount: yup.string(),
  projectId: yup.string(),
});

export const sendLinkFullAppSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup
    .string()
    .required("Email Address is required.")
    .matches(REGEX.EMAIL, "Please enter a valid email"),
  mobileNumber: yup.string().required("Mobile Number is required."),
  loanType: yup.string().required("Loan Product is required."),
  installationAddressLine: yup.string().when("loanType", (loanType, schema) => {
    if (isSungageLoanProduct(loanType))
      return schema
        .required("Street is required")
        .typeError("Street is required");

    return schema;
  }),
  installationCity: yup.string().when("loanType", (loanType, schema) => {
    if (isSungageLoanProduct(loanType))
      return schema.required("City is required").typeError("City is required");

    return schema;
  }),
  installationState: yup.string().when("loanType", (loanType, schema) => {
    if (isSungageLoanProduct(loanType))
      return schema
        .required("State is required")
        .typeError("State is required");

    return schema;
  }),
  installationZipCode: yup.string().when("loanType", (loanType, schema) => {
    if (isSungageLoanProduct(loanType))
      return schema
        .required("Zip Code is required")
        .typeError("Zip Code is required");

    return schema;
  }),

  moduleType: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Module Number is required")
          .typeError("Module Number is required");

      return schema;
    }),
  panelCost: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .test(
      "panelCost-required-test",
      "Panel cost is required",
      (value, { parent }) => {
        return !(
          isSungageLoanProduct(parent.loanType) &&
          parent.moduleType !== 0 &&
          value === undefined
        );
      }
    ),
  panelQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .min(1, "Panel quantity must be greater than 0")
    .test(
      "panelQuantity-required-test",
      "Panel quantity is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.moduleType !== 0 &&
          value === undefined
        )
    ),
  batteryType: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Battery Type is required")
          .typeError("Battery Type is required");

      return schema;
    }),
  batteryCost: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .max(
      yup.ref("loanAmount"),
      ({ max }) =>
        `Battery cost cannot be greater than the loan amount ($${max})`
    )
    .test(
      "batteryCost-required-test",
      "Battery cost is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.batteryType !== 0 &&
          parent.batteryExtensionType !== 0 &&
          value === undefined
        )
    ),
  batteryQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .min(1, "Battery quantity must be greater than 0")
    .test(
      "batteryQuantity-required-test",
      "Battery quantity is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.batteryType !== 0 &&
          value === undefined
        )
    ),
  batteryExtensionType: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Battery Extension Model is required")
          .typeError("Battery Extension Model is required");

      return schema;
    }),
  batteryExtensionQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .min(1, "Battery extension quantity must be greater than 0")
    .test(
      "batteryExtensionQuantity-required-test",
      "Battery extension quantity is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.batteryExtensionType !== 0 &&
          value === undefined
        )
    ),
  totalBatterySize: yup.number().when("loanType", (loanType, schema) => {
    if (isSungageLoanProduct(loanType))
      return schema
        .transform(transformNaN)
        .nullable()
        .max(75, "Total battery size must be equal to or less than 75kWh");

    return schema;
  }),
  loanAmount: yup
    .number()
    .transform(transformNaN)
    .typeError("Loan Amount is required.")
    .required("Loan Amount is required.")
    .min(1, "Loan Amount amount must be greather than 0")
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .transform(transformNaN)
          .min(7500, "Mimumum loan amount is $7500")
          .max(150000, "Maximum loan amount is $150,000");

      return schema;
    }),
  projectId: yup.string(),
});

export const newAppAddCoBorrowerSchema = yup.object().shape({
  isModify: yup.boolean(),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  SSN: yup.string().when("isModify", {
    is: false,
    then: yup
      .string()
      .required("SSN is required.")
      .transform((value) => value.replace(REGEX.SSN, ""))
      .min(9, "SSN should of length 9")
      .max(9, "SSN should of length 9"),
  }),
  dateOfBirth: yup.string().when("isModify", {
    is: false,
    then: yup.string().required("Date of Birth is required."),
  }),
  email: yup
    .string()
    .required("Email is required.")
    .notOneOf(
      [yup.ref("primaryApplicantEmail")],
      "Co-Borrower email should be different than borrower's email"
    )
    .matches(REGEX.EMAIL, "Please enter a valid email"),
  mobilePhone: yup.string().required("Mobile Phone is required."),
  isUSCitizen: yup.string().required("Field is required."),
  xIsPermanentResident: yup.string().when("isUSCitizen", {
    is: "No",
    then: yup.string().required("Field is required."),
  }),
  coBorrowerGrossIncome: yup
    .number()
    .typeError("Annual Income is required.")
    .required("Annual Income is required.")
    .min(1, "Annual Income amount must be greather than 0"),
  coBorrowerEmployerName: yup
    .string()
    .label("Employer Name")
    .test(
      "co-borrower-employment-name-test",
      "Employer name is required",
      (value, { parent }) => {
        return !(
          parent.coBorrowerEmploymentStatus === EmploymentStatus.EMPLOYED &&
          value === ""
        );
      }
    ),
  coBorrowerYear: yup
    .string()
    .label("Years employed")
    .when("employerName", {
      is: (value: string) => trim(value).toLowerCase() !== "retired",
      then: yup.string().required(),
    }),
  coBorrowerMonths: yup
    .string()
    .label("Months employed")
    .when("employerName", {
      is: (value: string) => trim(value).toLowerCase() !== "retired",
      then: yup.string().required(),
    }),

  isMailingAdressSame: yup.boolean(),
  mailingAddressLine1: yup.string().when("isMailingAdressSame", {
    is: true,
    then: yup.string().required("Street is required."),
  }),

  mailingCity: yup.string().when("isMailingAdressSame", {
    is: true,
    then: yup.string().required("City is required."),
  }),
  mailingState: yup.string().when("isMailingAdressSame", {
    is: true,
    then: yup
      .string()
      .required("State is required.")
      .oneOf(Object.keys(STATES), "State is invalid."),
  }),
  mailingZipCode: yup.string().when("isMailingAdressSame", {
    is: true,
    then: yup
      .string()
      .required("Zip Code is required.")
      .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
      .max(5, "Zip Code is invalid"),
  }),

  //if coborrower different address
  xDiffAddressThanApplicant: yup.string(),

  coBorrowerAddressLine1: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required("Street is required."),
  }),
  coBorrowerCity: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required("City is required."),
  }),
  coBorrowerState: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup
      .string()
      .required("State is required.")
      .oneOf(Object.keys(STATES), "State is invalid."),
  }),
  coBorrowerPostalCode: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup
      .string()
      .required("Zip Code is required.")
      .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
      .max(5, "Zip Code is invalid"),
  }),
  coBorrowerYearsAtResidence: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required().label("Years with residence"),
  }),
  coBorrowerMonthsAtResidence: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required().label("Months with residence"),
  }),

  coBorrowerEmploymentStatus: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .min(1, "Please select a valid option")
    .max(4, "Please select a valid option")
    .required("Employment Status is required.")
    .typeError("Employment Status is required."),

  isCoBorrowerMailingAddressDifferrent: yup.boolean(),
  coBorrowerMailingAddressLine: yup
    .string()
    .when("isCoBorrowerMailingAddressDifferrent", {
      is: true,
      then: yup.string().required("Street is required."),
    }),
  coBorrowerMailingCity: yup
    .string()
    .when("isCoBorrowerMailingAddressDifferrent", {
      is: true,
      then: yup.string().required("City is required."),
    }),
  coBorrowerMailingState: yup
    .string()
    .when("isCoBorrowerMailingAddressDifferrent", {
      is: true,
      then: yup
        .string()
        .required("State is required.")
        .oneOf(Object.keys(STATES), "State is invalid."),
    }),
  coBorrowerMailingZipCode: yup
    .string()
    .when("isCoBorrowerMailingAddressDifferrent", {
      is: true,
      then: yup
        .string()
        .required("Zip Code is required.")
        .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
        .max(5, "Zip Code is invalid"),
    }),

  authorization: yup
    .string()
    .required("Please confirm the credit authorization.")
    .oneOf(["true"], "Please confirm the credit authorization."),
  consent: yup
    .string()
    .required("Please confirm the consent.")
    .oneOf(["true"], "Please confirm the consent"),
});

export const preQualAddcoBorrowerSchema = yup.object().shape({
  isModify: yup.boolean(),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  SSN: yup.string().when("isModify", {
    is: false,
    then: yup
      .string()
      .required("SSN is required.")
      .transform((value) => value.replace(REGEX.SSN, ""))
      .min(9, "SSN should of length 9")
      .max(9, "SSN should of length 9"),
  }),
  dateOfBirth: yup.string().when("isModify", {
    is: false,
    then: yup.string().required("Date of Birth is required."),
  }),
  grossIncome: yup
    .number()
    .typeError("Annual Income is required.")
    .required("Annual Income is required.")
    .min(1, "Annual Income amount must be greather than 0"),
  //if coborrower different address
  xDiffAddressThanApplicant: yup.string(),

  coBorrowerAddressLine1: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required("Street is required."),
  }),
  coBorrowerCity: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required("City is required."),
  }),
  coBorrowerState: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup
      .string()
      .required("State is required.")
      .oneOf(Object.keys(STATES), "State is invalid."),
  }),
  coBorrowerPostalCode: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup
      .string()
      .required("Zip Code is required.")
      .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
      .max(5, "Zip Code is invalid"),
  }),
  coBorrowerYearsAtResidence: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required().label("Years with residence"),
  }),
  coBorrowerMonthsAtResidence: yup.string().when("xDiffAddressThanApplicant", {
    is: "No",
    then: yup.string().required().label("Months with residence"),
  }),

  authorization: yup
    .string()
    .required("Please confirm the credit authorization.")
    .oneOf(["true"], "Please confirm the credit authorization."),
  consent: yup
    .string()
    .required("Please confirm the consent.")
    .oneOf(["true"], "Please confirm the consent"),
});

export const systemInfoSchema = yup.object().shape({
  loanType: yup.string(),
  loanAmount: yup.number(),
  moduleType: yup.string().required("Module Type is required."),
  panelQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("moduleType", {
      is: (value: string) =>
        value === "0" || value === undefined || value === "",
      then: (schema) => schema,
      otherwise: (schema) =>
        schema
          .min(1, "Quantity must be greater than 0")
          .required("Panel Quantity is required."),
    }),
  inverterType: yup.string().required("Inverter Type is required."),
  inverterQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("inverterType", {
      is: (value: string) =>
        value === "0" || value === undefined || value === "",
      then: (schema) => schema,
      otherwise: (schema) =>
        schema
          .min(1, "Quantity must be greater than 0")
          .required("Inverter Quantity is required."),
    }),
  batteryType: yup.string().required("Battery Type is required."),
  batteryQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("batteryType", {
      is: (value: string) =>
        value === "0" || value === undefined || value === "",
      then: (schema) => schema,
      otherwise: (schema) =>
        schema
          .min(1, "Quantity must be greater than 0")
          .required("Battery Quantity is required."),
    }),
  batteryExpansionType: yup
    .string()
    .required("Battery Expansion Type is required."),
  batteryExpansionQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("batteryExpansionType", {
      is: (value: string) =>
        value === "0" || value === undefined || value === "",
      then: (schema) => schema,
      otherwise: (schema) =>
        schema
          .min(1, "Quantity must be greater than 0")
          .required("Battery Expansion Quantity is required."),
    }),
  other: yup.string(),
  otherQuantity: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("other", {
      is: (value: string) => !value || value?.trim() === "",
      then: (schema) => schema,
      otherwise: (schema) =>
        schema
          .min(1, "Quantity must be greater than 0")
          .required("Other Quantity is required."),
    }),
  xVaSurvivorshipElection: yup.string(),
  panelCost: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .test(
      "panelCost-required-test",
      "Panel cost is required",
      (value, { parent }) => {
        return !(
          isSungageLoanProduct(parent.loanType) &&
          parent.moduleType !== 0 &&
          value === undefined
        );
      }
    ),
  batteryCost: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .max(
      yup.ref("loanAmount"),
      ({ max }) =>
        `Battery cost cannot be greater than the loan amount ($${max})`
    )
    .test(
      "batteryCost-required-test",
      "Battery cost is required",
      (value, { parent }) =>
        !(
          isSungageLoanProduct(parent.loanType) &&
          parent.batteryType !== 0 &&
          parent.batteryExtensionType !== 0 &&
          value === undefined
        )
    ),
  estimatedYear1Production: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Mortgage Payment is required.")
          .typeError("Mortgage Payment is required.")
          .positive();

      return schema;
    }),
  utilityCompany: yup.string().when("loanType", (loanType, schema) => {
    if (isSungageLoanProduct(loanType))
      return schema
        .required("Utility Company is required")
        .typeError("Utility Company is required");

    return schema;
  }),
  downPayment: yup
    .number()
    .transform(transformNaN)
    .nullable()
    .when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("Down Payment is required.")
          .typeError("Down Payment is required.");

      return schema;
    }),
    certifyIndustrialBestPractice: yup.boolean().when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("This must be checked.")
          .oneOf([true], "This must be checked.");

      return schema;
    }),
    certifyShareWithHomeOwner: yup.boolean().when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .required("This must be checked.")
          .oneOf([true], "This must be checked.");

      return schema;
    }),
    totalBatterySize: yup.number().when("loanType", (loanType, schema) => {
      if (isSungageLoanProduct(loanType))
        return schema
          .transform(transformNaN)
          .nullable()
          .max(75, "Total battery size must be equal to or less than 75kWh");
  
      return schema;
    }),
});

export const addUserSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required.")
    .matches(REGEX.EMAIL, "Please enter a valid email"),
  username: yup.string().required("Username is required."),
  employeeId: yup.string(),
  dealer: yup.string().when("role", {
    is: (value: UserRole) =>
      value !== UserRole.providerAdmin &&
      value !== UserRole.financialInstitution,
    then: yup.string().required("Dealer is required."),
  }),
  role: yup
    .string()
    .oneOf(USER_ROLES.map(({ value }) => value))
    .required("Role is required"),
  regionId: yup.string().when("role", {
    is: (value: UserRole) =>
      value !== UserRole.providerAdmin &&
      value !== UserRole.financialInstitution,
    then: yup.string().required("Region is required."),
  }),
  lenderId: yup.string().when("role", {
    is: UserRole.financialInstitution,
    then: yup.string().required("Lender is required."),
  }),
});

export const modifyUserSchema = yup.object().shape({
  username: yup.string().required("Username is required."),
  employeeId: yup.string(),
  dealer: yup.string().when("role", {
    is: (value: UserRole) =>
      value !== UserRole.providerAdmin &&
      value !== UserRole.financialInstitution,
    then: yup.string().required("Dealer is required."),
  }),
  role: yup
    .string()
    .oneOf(
      USER_ROLES.map(({ value }) => value),
      "Please select a role."
    )
    .required("Role is required."),
  regionId: yup.string().when("role", {
    is: (value: UserRole) =>
      value !== UserRole.providerAdmin &&
      value !== UserRole.financialInstitution,
    then: yup.string().required("Region is required."),
  }),
  lenderId: yup.string().when("role", {
    is: UserRole.financialInstitution,
    then: yup.string().required("Lender is required."),
  }),
});

export const addRegionSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  dealer: yup.string().required("Dealer is required."),
});

export const modifyLoanSchema = yup.object().shape({
  isFull: yup.boolean(),
  loanType: yup.string().required("Loan Type is required."),
  loanAmount: yup.number().when("isFull", {
    is: true,
    then: yup
      .number()
      .typeError("Loan is required.")
      .required("Loan is required.")
      .min(1, "Loan amount must be greather than 0"),
  }),
  xInstallationAddressLine: yup.string().when("isFull", {
    is: true,
    then: yup.string().required("Street is required"),
  }),
  xInstallationCity: yup.string().when("isFull", {
    is: true,
    then: yup.string().required("City is required"),
  }),
  xInstallationState: yup.string().when("isFull", {
    is: true,
    then: yup
      .string()
      .required("State is required")
      .oneOf(Object.keys(STATES), "State is invalid."),
  }),
  xInstallationZipCode: yup.string().when("isFull", {
    is: true,
    then: yup.string().required("Zip Code is required"),
  }),
  installationHomeOwner: yup.number().when("isFull", {
    is: true,
    then: yup
      .number()
      .required("Home Ownership is required.")
      .typeError("Home Ownership is required."),
  }),
  propertyType: yup.number().when("isFull", {
    is: true,
    then: yup
      .number()
      .transform(transformNaN)
      .nullable()
      .required("Property Type is required")
      .typeError("Property Type is required"),
  }),
  installationResidency: yup.number().when("isFull", {
    is: true,
    then: yup
      .number()
      .min(1, "Please select a valid option")
      .max(3, "Please select a valid option")
      .required("Installation address residency is required"),
  }),
});

export const modifyPreQualSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  addressLine1: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup
    .string()
    .required("State is required.")
    .oneOf(Object.keys(STATES), "State is invalid."),
  postalCode: yup
    .string()
    .required("Zip Code is required.")
    .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed."),
  grossIncome: yup
    .number()
    .typeError("Annual Income is required.")
    .required("Annual Income is required.")
    .min(1, "Annual Income amount must be greather than 0"),
  yearsAtResidence: yup.string().required().label("Years with residence"),
  monthsAtResidence: yup.string().required().label("Months with residence"),
});

export const modifyNewRequestSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup
    .string()
    .required("Email is required.")
    .notOneOf(
      [yup.ref("coApplicantEmail")],
      "Borrower email should be different than co-borrower's email"
    )
    .matches(REGEX.EMAIL, "Please enter a valid email"),
  mobilePhone: yup.string().required("Mobile Phone is required."),

  isUSCitizen: yup.string().required("Field is required."),
  xIsPermanentResident: yup.string().when("isUSCitizen", {
    is: "No",
    then: yup.string().required("Field is required."),
  }),
  addressLine1: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup
    .string()
    .required("State is required.")
    .oneOf(Object.keys(STATES), "State is invalid."),
  postalCode: yup
    .string()
    .required("Zip Code is required.")
    .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed."),
  yearsAtResidence: yup.string().required().label("Years with residence"),
  monthsAtResidence: yup.string().required().label("Months with residence"),

  isMailingAdressSame: yup.boolean(),
  mailingAddressLine1: yup.string().when("isMailingAdressSame", {
    is: true,
    then: yup.string().required("Street is required."),
  }),

  mailingCity: yup.string().when("isMailingAdressSame", {
    is: true,
    then: yup.string().required("City is required."),
  }),
  mailingState: yup.string().when("isMailingAdressSame", {
    is: true,
    then: yup
      .string()
      .required("State is required.")
      .oneOf(Object.keys(STATES), "State is invalid."),
  }),
  mailingZipCode: yup.string().when("isMailingAdressSame", {
    is: true,
    then: yup
      .string()
      .required("Zip Code is required.")
      .matches(REGEX.NUMBERS_ONLY_REGEX, "Only numbers are allowed.")
      .max(5, "Zip Code is invalid"),
  }),
  grossIncome: yup
    .number()
    .typeError("Annual Income is required.")
    .required("Annual Income is required.")
    .min(1, "Annual Income amount must be greather than 0"),
  employmentStatus: yup
    .number()
    .min(1, "Please select a valid option")
    .max(4, "Please select a valid option")
    .required("Employment Status is required.")
    .typeError("Employment Status is required."),
  employerName: yup
    .string()
    .label("Employer Name")
    .when("employmentStatus", {
      is: EmploymentStatus.EMPLOYED,
      then: yup.string().required("Employer Name is required."),
    }), // Here
  yearsEmployed: yup.string().required().label("Years employed"),
  monthsEmployed: yup.string().label("Months employed"),
});

export const addIpWhitelistSchema = yup.object().shape({
  label: yup.string().required("Label is required.").max(255),
  ipAddress: yup
    .string()
    .required("IP Address is required.")
    .matches(REGEX.IP_ADDRESS, "IP Address is invalid."),
});

export const authenticatorMfaSetupSchema = yup.object().shape({
  mfaCode: yup
    .string()
    .required("MFA Code is required.")
    .matches(REGEX.NUMBERS_ONLY_REGEX, "Only digits are allowed.")
    .min(6, "MFA should of length 6")
    .max(6, "MFA should of length 6"),
});

export const smsMfaPhoneNumberSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Phone number is required.")
    .test(
      "validate-10-digit-phone-number",
      (value: string | undefined, { createError, path }) => {
        const digits = value?.replace(/\D/g, "");
        const isValid = !!digits && digits.length === 10;
        if (isValid) return true;
        else
          return createError({
            message: `Invalid Phone number`,
            path,
          });
      }
    ),
});

export const smsMfaVerifySchema = yup.object().shape({
  mfaCode: yup
    .string()
    .required("MFA Code is required.")
    .matches(REGEX.NUMBERS_ONLY_REGEX, "Only digits are allowed.")
    .min(6, "MFA should of length 6")
    .max(6, "MFA should of length 6"),
});

export const addLenderNoteSchema = yup.object().shape({
  subject: yup
    .string()
    .required("Subject is required.")
    .max(255, "Maximum of 255 characters allowed."),
  noteContent: yup
    .string()
    .required("Note is required.")
    .max(1800, "Maximum of 1800 characters allowed."),
});

export const updateBookingConfirmationSchema = yup.object().shape({
  xMemberAccountNumber: yup
    .string()
    .required("Lender Member Number is required.")
    .max(255, "Maximum of 255 characters allowed."),
  xNewLoanNumber: yup
    .string()
    .required("Lender New Loan Number is required.")
    .max(255, "Maximum of 255 characters allowed."),
});
