import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  DL4_HOME_OWNERSHIP,
  Dl4HomeOwnershipKeyType,
} from "@shared/constants/app.constant";
import {
  maskMobile,
  removeSpaces,
  unmaskMobile,
  createUrl,
  getValueIfNotNull,
} from "@shared/helpers/global.helper";
import { switchPrequalToFullApp } from "@store/slices/loan.slice";
import { loanService } from "@services/loan.service";
import { newRequestSchema } from "@shared/schema/request";
import { formatDateForDl4Submission } from "@utils/date";
import {
  getEmployerNameForRequestPayloadToDl4,
  getPrequalifiedOfferFromAppData,
  getXPrequalProducts,
} from "@shared/helpers/application.helper";
import ConvertApplicationGuard from "@shared/components/ConvertApplicationGuard";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";
import FullApplicationForm from "@shared/components/FullApplicationForm";
import { appUserSelector } from "@store/slices/app.slice";
import { IXPrequalProductsTypeValues } from "@shared/interfaces/Application";
import useInstallationAddress from "@hooks/useInstallationAddress";

const ConvertToFullApplication = () => {
  const dispatch = useAppDispatch();
  const [requestDetail, setRequestDetail] = useState<IPortalApplication>();
  const history = useHistory();
  const location = useLocation() as {
    pathname: string;
    state: {
      prequalifiedOfferId: number;
    };
  };
  const user = useAppSelector(appUserSelector);

  const prequalifiedOfferId = location?.state?.prequalifiedOfferId;

  let { id } = useParams<{ id: string }>();

  const methods = useForm({
    resolver: yupResolver(newRequestSchema),
  });

  const { reset, watch } = methods;

  const redirectToApplicationSummary = useCallback(() => {
    history.push(createUrl(`/request-detail/${id}`));
  }, [history, id]);

  const getInstallationAddress = () => {
    return {
      xInstallationAddressLine: watch("installationAddressIsDifferent")
        ? watch("installationAddressLine")
        : watch("addressLine1"),
      xInstallationCity: watch("installationAddressIsDifferent")
        ? watch("installationCity")
        : watch("city"),
      xInstallationState: watch("installationAddressIsDifferent")
        ? watch("installationState")
        : watch("state"),
      xInstallationZipCode: watch("installationAddressIsDifferent")
        ? watch("installationZipCode")
        : watch("postalCode"),
    };
  };

  const { isInstallationAddressUpdated, setInitialInstAddressFields } =
    useInstallationAddress({
      currentAddressFields: {
        addressLine: getInstallationAddress().xInstallationAddressLine,
        city: getInstallationAddress().xInstallationCity,
        state: getInstallationAddress().xInstallationState,
        zipCode: getInstallationAddress().xInstallationZipCode,
      },
    });

  const handleOnSubmit = (event: any) => {
    if (!requestDetail?.programTypeCode) {
      return;
    }

    const applicants = [];
    const applicantAddresses = [];

    const xStatedInstallationHomeOwner =
      DL4_HOME_OWNERSHIP[
        Number(event.installationHomeOwner) as Dl4HomeOwnershipKeyType
      ];

    const currentAddress = {
      ownershipType: {
        name: "Other",
        label: "Other",
        value: 4,
      },
      addressType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      addressFormat: {
        name: "Postal Standard",
        label: "Postal Standard",
        value: 5,
      },
      postalCode: event.postalCode,
      addressLine1: event.addressLine1,
      city: event.city,
      state: event.state,
      yearsAtResidence: Number(event.yearsAtResidence),
      monthsAtResidence: Number(event.monthsAtResidence),
    };
    applicantAddresses.push(currentAddress);
    if (event.isMailingAddressDifferent) {
      applicantAddresses.push({
        ownershipType: {
          name: "Other",
          label: "Other",
          value: 4,
        },
        addressType: {
          name: "Mailing",
          label: "Mailing",
          value: 3,
        },
        addressFormat: {
          name: "Postal Standard",
          label: "Postal Standard",
          value: 5,
        },
        postalCode: event.mailingZipCode,
        addressLine1: event.mailingAddressLine1,
        city: event.mailingCity,
        state: event.mailingState,
      });
    }
    const applicanEmployment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.grossIncome),
      monthsEmployed: event.monthsEmployed ? Number(event.monthsEmployed) : 0,
      yearsEmployed: event.yearsEmployed ? Number(event.yearsEmployed) : 0,
      employerName: getEmployerNameForRequestPayloadToDl4({
        employmentStatus: event?.employmentStatus,
        employerName: event?.employerName || "",
      }),
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };
    const coBorrowerEmployment = {
      employmentType: {
        name: "Primary",
        label: "Primary",
        value: 1,
      },
      grossIncome: Number(event.coBorrowerGrossIncome),
      monthsEmployed: event.coBorrowerMonths
        ? Number(event.coBorrowerMonths)
        : 0,
      yearsEmployed: event.coBorrowerYear ? Number(event.coBorrowerYear) : 0,
      employerName: getEmployerNameForRequestPayloadToDl4({
        employmentStatus: event?.coBorrowerEmploymentStatus,
        employerName: event?.coBorrowerEmployerName || "",
      }),
      payPeriod: 5,
      employmentStatus: {
        name: "Undefined",
        label: "Undefined",
        value: 0,
      },
    };

    const primaryApplicantModel = {
      jsonType: "Applicant",
      applicantType: 1,
      lastName: event.lastName,
      firstName: event.firstName,
      email: event.email,
      isUSCitizen: event.isUSCitizen === "Yes" ? true : false,
      xIsPermanentResident:
        event.isUSCitizen === "Yes"
          ? null
          : event.xIsPermanentResident === "Yes"
          ? true
          : false,
      mobilePhone: unmaskMobile(event.mobilePhone),
      addresses: applicantAddresses,
      employments: [applicanEmployment],
      xInstallationHomeOwner: 1,

      internalClientId: requestDetail?.losInternalClientId,
      applicationId: requestDetail?.losApplicationId,
      applicantId: requestDetail?.primaryApplicant?.applicantId,
    };
    applicants.push(primaryApplicantModel);

    if (event.hasCoApplicant === "Yes") {
      let coBorrowerAddress = [];
      if (event.isDiffAddressThanPrimary === "No") {
        coBorrowerAddress.push({
          ownershipType: {
            name: "Other",
            label: "Other",
            value: 4,
          },
          addressType: {
            name: "Primary",
            label: "Primary",
            value: 1,
          },
          addressFormat: {
            name: "Postal Standard",
            label: "Postal Standard",
            value: 5,
          },
          postalCode: event.coBorrowerPostalCode,
          addressLine1: event.coBorrowerAddressLine1,
          city: event.coBorrowerCity,
          state: event.coBorrowerState,
          yearsAtResidence: Number(event.coBorrowerYearsAtResidence),
          monthsAtResidence: Number(event.coBorrowerMonthsAtResidence),
        });
      } else {
        coBorrowerAddress.push(
          ...applicantAddresses.filter(
            (item) => item.addressType.name !== "Mailing"
          )
        );
      }

      if (event.isCoBorrowerMailingAddressDifferrent) {
        coBorrowerAddress.push({
          ownershipType: {
            name: "Other",
            label: "Other",
            value: 4,
          },
          addressType: {
            name: "Mailing",
            label: "Mailing",
            value: 3,
          },
          addressFormat: {
            name: "Postal Standard",
            label: "Postal Standard",
            value: 5,
          },
          postalCode: event.coBorrowerMailingZipCode,
          addressLine1: event.coBorrowerMailingAddressLine,
          city: event.coBorrowerMailingCity,
          state: event.coBorrowerMailingState,
        });
      }
      const coApplicantModel = {
        applicantType: 2,
        firstName: event.coBorrowerFirstName,
        lastName: event.coBorrowerLastName,
        email: event.coBorrowerEmail,
        ...(!event.initialCoBorrowerData && {
          // As we will be appending values from backend for edit
          SSN: removeSpaces(event.coBorrowerssn),
          dateOfBirth: formatDateForDl4Submission(event.coBorrowerdob),
        }),
        isUSCitizen: event.coBorrowerusCitizen === "Yes" ? true : false,
        xIsPermanentResident:
          event.coBorrowerusCitizen === "Yes"
            ? null
            : event.coBorrowerxIsPermanentResident === "Yes"
            ? true
            : false,
        mobilePhone: unmaskMobile(event.coBorrowerMobilePhone),
        addresses: coBorrowerAddress,
        employments: [coBorrowerEmployment],
        xInstallationHomeOwner: 1,
        xDiffAddressThanApplicant:
          event.isDiffAddressThanPrimary === "Yes" ? false : true,

        internalClientId: requestDetail?.losInternalClientId,
        applicationId: requestDetail?.losApplicationId,
        applicantId: requestDetail?.coApplicant?.applicantId,
      };
      applicants.push(coApplicantModel);
    }

    const { xPrequalProductsSelected } = getXPrequalProducts(
      user,
      event?.loanType,
      true
    );
    const productInfo: IXPrequalProductsTypeValues | undefined =
      xPrequalProductsSelected?.[0];

    const installationAddress = getInstallationAddress();

    const loanModel = {
      applicants: applicants,
      xProgramTypeName: productInfo?.xProgramTypeName,
      decisionProcessPrefs: {
        defaultCarletonDATFileName: productInfo?.defaultCarletonDATFileName,
      },
      xLender: productInfo?.xLender,
      xInstallationAddressLine: installationAddress.xInstallationAddressLine,
      xInstallationCity: installationAddress.xInstallationCity,
      xInstallationState: installationAddress.xInstallationState,
      xInstallationZipCode: installationAddress.xInstallationZipCode,
      xInstalationAddressSame: !!!event.installationAddressIsDifferent,
      xStatedInstallationHomeOwner,
      installationResidency: event.installationResidency || 1,
      xPropertyType: Number(event.propertyType),
      loanInformation: {
        loanVersions: [
          {
            totalCashPrice: parseInt(event.loanAmount),
            requestedTerm: productInfo?.requestedTerm,
            firstPaymentDateStandard: productInfo?.firstPaymentDateStandard,
            contractDateStandard: productInfo?.contractDateStandard,
            userRate: productInfo?.userRate,
            xRiskBasedRateTable: productInfo?.xRisckBasedIntialRateTable,
            xRiskBasedInitialRateTable: productInfo?.xRisckBasedIntialRateTable,
            commonLoanInfo: {
              xprogramtype: Number(event?.loanType),

              xInstallationAddressLine:
                installationAddress.xInstallationAddressLine,
              xInstallationCity: installationAddress.xInstallationCity,
              xInstallationState: installationAddress.xInstallationState,
              xInstallationZipCode: installationAddress.xInstallationZipCode,

              xInstalationAddressSame: !!!event.installationAddressIsDifferent,
              xStatedInstallationHomeOwner,
              installationResidency: event.installationResidency || 1,
              xPropertyType: Number(event.propertyType),
              xinitialTerm: productInfo?.xinitialTerm,
              xAmortizationTerm: productInfo?.xAmortizationTerm,
              xFinalPmtDiff: productInfo?.xFinalPmtDiff,
              xMembershipFee: productInfo?.xMembershipFee,
              xFlicFlatFee: productInfo?.xFlicFlatFee,
              xFlicPlatformFeePercent: productInfo?.xFlicPlatformFeePrecent,
              xSellersPointsPercent: productInfo?.xSellerPointsPercent,
              xInitialRate: productInfo?.xInitialRate,
              xFinalRate: productInfo?.xFinalRate,
            },
          },
        ],
      },
    };

    dispatch(
      switchPrequalToFullApp({
        loanInfo: loanModel,
        appNumber: id,
        prequalifiedOfferId,
        history,
        path: "/new-application/confirmation",
        isEditOfCoborrower: event.initialCoBorrowerData,
        isInstallationAddressUpdated,
      })
    );
  };

  useEffect(() => {
    const getRequestData = async (id: string) => {
      const response = await loanService.getRequestById(id);

      const applicationData = response?.data?.data;
      setRequestDetail(applicationData);
      // Validate if prequalifiedOfferId is not in the prequalifiedOffers array then redirect to application summary page
      const prequalifiedOffer = getPrequalifiedOfferFromAppData(
        applicationData,
        prequalifiedOfferId
      );

      if (!prequalifiedOffer) {
        redirectToApplicationSummary();
      }

      const primaryData = applicationData?.primaryApplicant;
      const coBorrowerData = applicationData?.coApplicant;
      const primaryAddress = primaryData?.currentAddress;
      const mailingAddress = primaryData?.mailingAddress;
      const coBorrowerAddress = coBorrowerData?.currentAddress;
      const coBorrowerHaveDifferentAddress =
        coBorrowerData?.differentAddressThanPrimaryApplicant;

      // To check if installation address is updated or not
      setInitialInstAddressFields({
        xInstallationAddressLine: primaryAddress?.addressLine || "",
        xInstallationCity: primaryAddress?.city || "",
        xInstallationState: primaryAddress?.state || "",
        xInstallationZipCode: primaryAddress?.postalCode || "",
      });

      reset({
        isModify: true,
        loanAmount: prequalifiedOffer?.amount,
        loanType: prequalifiedOffer?.xprogramtype,
        firstName: primaryData?.firstName,
        lastName: primaryData?.lastName,
        email: primaryData?.email,
        mobilePhone: maskMobile(primaryData?.mobilePhone || ""),
        ...(typeof primaryData?.isUSCitizen == "boolean" &&
          !primaryData?.isUSCitizen && {
            xIsPermanentResident: primaryData?.isPermanentResident
              ? "Yes"
              : "No",
          }),

        addressLine1: primaryAddress?.addressLine,
        city: primaryAddress?.city,
        state: primaryAddress?.state,
        postalCode: primaryAddress?.postalCode,
        yearsAtResidence: primaryAddress?.yearsAtResidence,
        monthsAtResidence: primaryAddress?.monthsAtResidence,

        grossIncome: primaryData?.employment?.grossIncome,

        xInstalationAddressSame:
          !!!applicationData?.installationAddress
            ?.isInstallationAddressDifferent,
        installationAddressLine:
          applicationData?.installationAddress?.addressLine,
        installationAddressCity: applicationData?.installationAddress?.city,
        installationAddressState: applicationData?.installationAddress?.state,
        installationAddressZipCode:
          applicationData?.installationAddress?.zipCode,
        installationResidency:
          applicationData?.installationAddress?.installationResidency || 0,

        mailingAddressLine1: mailingAddress
          ? mailingAddress?.mailingAddress
          : "",
        mailingCity: mailingAddress ? mailingAddress?.mailingCity : "",
        mailingState: mailingAddress ? mailingAddress?.mailingState : "",
        mailingZipCode: mailingAddress ? mailingAddress?.mailingPostalCode : "",
        isMailingAddressDifferent:
          applicationData?.primaryApplicant
            ?.myMailingAddressIsDifferentThanCurrentAddress,

        employerName: primaryData?.employment?.employerName,
        yearsEmployed: primaryData?.employment?.yearsEmployed,
        monthsEmployed: primaryData?.employment?.monthsEmployed,

        coBorrowerFirstName: coBorrowerData ? coBorrowerData?.firstName : "",
        coBorrowerLastName: coBorrowerData ? coBorrowerData?.lastName : "",
        coBorrowerEmail: coBorrowerData ? coBorrowerData?.email : "",
        coBorrowerMobilePhone: coBorrowerData
          ? maskMobile(getValueIfNotNull(coBorrowerData?.mobilePhone))
          : "",
        coBorrowerusCitizen: coBorrowerData
          ? coBorrowerData?.isUSCitizen
            ? "Yes"
            : "No"
          : "",
        ...(typeof primaryData?.isUSCitizen == "boolean" &&
          !primaryData?.isUSCitizen && {
            xIsPermanentResident: primaryData?.isPermanentResident
              ? "Yes"
              : "No",
          }),

        coBorrowerGrossIncome: coBorrowerData
          ? coBorrowerData?.employment?.grossIncome
          : 0,
        hasCoApplicant: coBorrowerData ? "Yes" : "No",
        isDiffAddressThanPrimary: coBorrowerHaveDifferentAddress ? "No" : "Yes",
        coBorrowerAddressLine1: coBorrowerHaveDifferentAddress
          ? coBorrowerAddress?.addressLine
          : "",
        coBorrowerCity: coBorrowerHaveDifferentAddress
          ? coBorrowerAddress?.city
          : "",
        coBorrowerState: coBorrowerHaveDifferentAddress
          ? coBorrowerAddress?.state
          : "",
        coBorrowerPostalCode: coBorrowerHaveDifferentAddress
          ? coBorrowerAddress?.postalCode
          : "",
        coBorrowerYearsAtResidence: coBorrowerHaveDifferentAddress
          ? coBorrowerAddress?.yearsAtResidence
          : "",
        coBorrowerMonthsAtResidence: coBorrowerHaveDifferentAddress
          ? coBorrowerAddress?.monthsAtResidence
          : "",

        coBorrowerEmployerName: coBorrowerData
          ? coBorrowerData?.employment?.employerName
          : "",
        coBorrowerYear: coBorrowerData
          ? coBorrowerData?.employment?.yearsEmployed
          : 0,
        coBorrowerMonths: coBorrowerData
          ? coBorrowerData?.employment?.monthsEmployed
          : 0,
        initialCoBorrowerData: coBorrowerData ? true : false,
        // If there is no initialCoBorrowerData data, then we would be asking for DOB and SSN of co-borrower
        // If co-borrower data is already submitted, then we would not ask/display DOB and SSN
      });
    };
    reset({
      isMailingAddressDifferent: undefined,
      isDiffAddressThanPrimary: undefined,
    });
    if (id && prequalifiedOfferId) {
      getRequestData(id);
    } else {
      redirectToApplicationSummary();
      setTimeout(
        () =>
          reset({
            hasCoApplicant: "No",
            isDiffAddressThanPrimary: "No",
            xInstalationAddressSame: false,
            isMailingAddressDifferent: false,
            SSN: "",
          }),
        1000
      );
    }
  }, [
    location.pathname,
    id,
    reset,
    prequalifiedOfferId,
    redirectToApplicationSummary,
    setInitialInstAddressFields,
  ]);

  return (
    <FormProvider {...methods}>
      <ConvertApplicationGuard application={requestDetail} />
      <FullApplicationForm
        onSubmit={handleOnSubmit}
        isConvertingtoFullApp={true}
        prequalProduct={requestDetail?.programTypeCode}
      />
    </FormProvider>
  );
};

export default ConvertToFullApplication;
