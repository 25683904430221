import { ChangeEvent } from "react";
import { DownloadDocumentPayload } from "@store/slices/loan.slice";
import { StipulationCard } from "./documentCards/StipulationCard";
import {
  IDocumentStipulation,
  IPortalApplication,
} from "@shared/interfaces/PortalApplicationTypes";

const Funding = ({
  requestDetail,
  onDocumentInputFileChange,
  uploadingDocType,
  errorMessage,
  docId,
  installationPhotoStip,
  downloadDocument,
  canBeModified,
}: {
  requestDetail: IPortalApplication | undefined;
  onDocumentInputFileChange: (
    event: ChangeEvent<HTMLInputElement>,
    docId: string,
    callback?: () => void
  ) => Promise<void>;
  uploadingDocType: string;
  errorMessage: string;
  docId: string;
  installationPhotoStip: IDocumentStipulation | null;
  downloadDocument: (value: DownloadDocumentPayload) => void;
  canBeModified: boolean;
}) => {
  return (
    <div className="mt-5">
      <h3 className="h3 text-primary-dark">Installation Photos</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />

      <div>
        {installationPhotoStip ? (
          <h4 className="font-bold-800 mt-4">
            Please provide photos of all installed equipment:
          </h4>
        ) : (
          <h4 className="font-bold-800 mt-4">
            There are no photos of installed equipment required for this loan.
          </h4>
        )}
        {installationPhotoStip && (
          <StipulationCard
            requestDetail={requestDetail}
            stipulation={installationPhotoStip}
            onDocumentInputFileChange={onDocumentInputFileChange}
            docId={docId}
            errorMessage={errorMessage}
            uploadingDocType={uploadingDocType}
            downloadDocument={downloadDocument}
            canBeModified={canBeModified}
          />
        )}
      </div>
    </div>
  );
};

export default Funding;
