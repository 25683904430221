import {
  CLIENT_CREDENTIALS_ACCESS_ROLES,
  DISCONTINUED_PRODUCTS,
  MESSAGES,
  OWNERSHIP_SHORT_LIST,
  PRIVATE_PROGRAM_TYPE,
  PROGRAM_TYPE,
} from "@shared/constants/app.constant";
import { PortalDecisionStatus } from "@shared/enums/ApplicationStatus";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import isFunction from "lodash/isFunction";
import { SubmitErrorHandler } from "react-hook-form";
import { UserAuthStatus } from "@shared/enums/UserState";
import themeVariables from "../../../src/assets/scss/_exports.module.scss";
import { Theme } from "react-select";
import { AccessManagement } from "@store/slices/app.slice";
import { UserRole } from "@shared/interfaces/User";
import { IIssueDocType } from "@store/slices/loan.slice";
import { isSungageLoanProduct } from "./application.helper";
import {
  PortalApplicationStatus,
  PortalApplicationStatusLabel,
} from "@shared/interfaces/PortalApplicationTypes";

interface OnSubmitErrorOptions {
  focusField?: boolean;
  scrollIntoField?: boolean;
}

type ProductMenuType = Array<{
  value: number | string;
  text: string;
}>;

export const ExtractErrorMessage = (error: any) => {
  if (Array.isArray(error) && error.length > 0) {
    return error[0].message;
  } else if (error?.message) {
    return error.message;
  } else {
    return MESSAGES.DEFAULT_ERROR;
  }
};

export const isSalesRole = (role: UserRole | undefined): boolean =>
  !!role &&
  [
    UserRole.eneryConsultant,
    UserRole.enterpriseManager,
    UserRole.regionalManager,
  ].includes(role);

export const isReviewerRole = (role: UserRole | undefined): boolean =>
  !!role &&
  [UserRole.providerAdmin, UserRole.financialInstitution].includes(role);

export const parseJSON = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.log(e);
    } else {
      console.log(e);
    }
    return [];
  }
};

export const deepClone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const useQuery = () => {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const generateArrayOfYears = () => {
  var max = new Date().getFullYear();
  var min = max - 32;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

export const toTimestamp = (date: any) => {
  return Date.parse(date);
};

export const removeSpaces = (value: string) => {
  return value.replaceAll(" ", "");
};

export const insertAt = (str: string, index: number, value: any) => {
  return str.slice(0, index) + value + str.slice(index);
};

export const maskSSN = (ssn: string) => {
  if (!ssn) return;
  const v1 = insertAt(ssn, 3, " ");
  const v2 = insertAt(v1, 6, " ");
  return v2;
};

export const isPrequalApproved = (status: string | undefined) => {
  return status === PortalDecisionStatus.Prequalified;
};

export const isPrequalDeclined = (status: string | undefined) => {
  return status === PortalDecisionStatus.PrequalDeclined;
};
export const isApproved = (status: string | undefined) => {
  return status === PortalDecisionStatus.Approved;
};
export const isDeclined = (status: string) => {
  return status === PortalDecisionStatus.Declined;
};
export const isPending = (status: string) => {
  return (
    status === PortalDecisionStatus.Pending ||
    status === PortalDecisionStatus.Refer
  );
};
export const isNoDecisionRun = (status: string) => {
  return status === PortalDecisionStatus.NoDecisionRun;
};
export const isExpired = (status: string) => {
  return status === PortalDecisionStatus.Expired;
};

// export const isNonModifiable = (
//   application: IPortalApplication,
//   role: UserRole | null | undefined
// ) => {
//   if (!application?.status?.applicationStatus) {
//     return true;
//   }

//   // For clarity sake, since we need a filter for applications that cannot be modified,
//   // the function needs to make it clear that what it returns is if the application
//   // cannot be modified and not otherwise
//   if (!role || role === UserRole.financialInstitution) {
//     return true;
//   }

//   // If prequal application is old, then do not allow to modify that
//   if (application?.isLegacyPrequal) {
//     return true;
//   }

//   if (isPrequalApplication(application)) {
//     // For prequal apps, It won't be modifiable
//     return true;
//   } else {
//     // For Full apps
//     return (
//       !isApproved(application?.status?.decisionStatus) ||
//       [
//         PortalApplicationStatus.Funded,
//         PortalApplicationStatus.NTPReview,
//         PortalApplicationStatus.QCReview,
//         PortalApplicationStatus.NTPIssued,
//         PortalApplicationStatus.InstallReady,
//         PortalApplicationStatus.RFFReview,
//         PortalApplicationStatus.RFFIssued,
//         PortalApplicationStatus.PTOReview,
//         PortalApplicationStatus.PTOIssued,
//         PortalApplicationStatus.Cancelled,
//         PortalApplicationStatus.Duplicate,
//         PortalApplicationStatus.Expired,
//       ].includes(application?.status?.applicationStatus)
//       // (application?.status?.applicationStatus || 0) > 13 ||
//       // (application?.status?.applicationStatus || 0) === 9
//     );
//   }
// };

// export const isApplicationNotConvertable = (
//   application: IPortalApplication,
//   role: UserRole | null | undefined,
//   authUserStatus: UserAuthStatus
// ) => {
//   if (
//     authUserStatus === UserAuthStatus.Authorized &&
//     (!role || role === UserRole.financialInstitution)
//   ) {
//     return true;
//   }

//   // If prequal application is old, then do not allow to modify that
//   if (application.isLegacyPrequal) {
//     return true;
//   }

//   return (
//     !isPrequalApplication(application) &&
//     isPrequalDeclined(application?.status?.decisionStatus || "")
//   );
// };

export const getProgramType = (id: string | number) => {
  const data = [
    ...PROGRAM_TYPE,
    ...DISCONTINUED_PRODUCTS,
    ...PRIVATE_PROGRAM_TYPE.filter((product) => product.type !== "EXTERNAL"),
  ].find((x) => x.value === Number(id));
  if (data) return data.text;
};

export const getDiscontinuedProduct = (value: number) => {
  return DISCONTINUED_PRODUCTS.find((x) => x.value === Number(value));
};

export const fileSizeValid = (file: any) => {
  const fileSize = file.size / 1024 / 1024;
  return fileSize < 60;
};

export const readFileAsDataURL = async (file: any) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
  return result_base64;
};

export const valueBetween = (field: number, min: number, max: number) => {
  return field >= min && field <= max;
};
export const valueExists = (field: number, array: number[]) => {
  return array.some((x) => x === field);
};

export const getValueIfNotNull = (value: any) =>
  value !== null ? value : undefined;

export const unmaskMobile = (value: string) => {
  return value
    .replaceAll(" ", "")
    .replace("(", "")
    .replace(")", "")
    .replaceAll("_", "");
};

export const maskMobile = (mobile: string) => {
  if (!mobile || mobile.length < 10) return;
  var v1 = insertAt(mobile, 0, "(");
  var v2 = insertAt(v1, 4, ")");
  var v3 = insertAt(v2, 5, " ");
  var v4 = insertAt(v3, 9, " ");
  return v4;
};

export const getStatusBackground = (status: string) => {
  switch (status) {
    case PortalDecisionStatus.Declined:
    case PortalDecisionStatus.PrequalDeclined:
      return "text-danger";
    case PortalDecisionStatus.Approved:
    case PortalDecisionStatus.Prequalified:
      return "text-success";
    case PortalDecisionStatus.Refer:
    case PortalDecisionStatus.Pending:
    case PortalDecisionStatus.Expired:
      return "text-warning";
    case PortalDecisionStatus.NoDecisionRun:
      return "text-secondary";
    default:
      return "text-default";
  }
};

export const getYear = (value: number) => {
  return Math.round(value / 12);
};

export const createUrl = (url: string) => {
  const params = new URLSearchParams(window.location.search);
  if (params.get("token")) {
    const token = params.get("token");
    return `${url}?token=${token}`;
  }
  return url;
};

export const getHomeOwnerShip = (id: number | undefined) => {
  return OWNERSHIP_SHORT_LIST.find((x) => x.value === id)?.text;
};

export const onSubmitError = ({
  focusField = true,
  scrollIntoField = true,
}: OnSubmitErrorOptions = {}): SubmitErrorHandler<Record<string, any>> => {
  return (errors, event) => {
    // Wait until next render
    window.requestAnimationFrame(() => {
      const form = event?.target as HTMLFormElement;
      if (form) {
        const element: HTMLElement | null = form.querySelector(
          ".is-invalid, .is-invalid-input"
        );
        if (element) {
          if (focusField && isFunction(element.focus)) {
            element.focus();
          }
          if (scrollIntoField && isFunction(element.scrollIntoView)) {
            element.scrollIntoView({
              behavior: "smooth",
            });
          }
        }
      }
    });
  };
};

export const getProductsList = (
  authUserStatus: UserAuthStatus,
  currentAuthRole: string | undefined,
  accessManagement: AccessManagement | null | undefined,
  useCase: "new" | "modify",
  filterSungageProducts: boolean,
  productValueToInclude?: number
): Array<{
  value: number | string;
  text: string;
}> => {
  const visibleProducts = PROGRAM_TYPE.filter(({ visible }) => visible);

  if (
    authUserStatus === UserAuthStatus.Public &&
    productValueToInclude &&
    (useCase === "new" || useCase === "modify")
  ) {
    // For Customer coming through email application link,
    // they will have access to General products and a private product for which they are invited.
    return [
      ...visibleProducts,
      ...PRIVATE_PROGRAM_TYPE.filter(
        (product) =>
          product.type !== "EXTERNAL" && productValueToInclude === product.value
      ).map((privateProduct) => ({
        value: privateProduct.value ?? "", // Internal product will have value anyway
        text: privateProduct.text,
      })),
    ].filter((i) =>
      filterSungageProducts ? !isSungageLoanProduct(Number(i.value)) : true
    );
  }

  if (authUserStatus === UserAuthStatus.Authorized && useCase === "modify") {
    const products: ProductMenuType = [...visibleProducts];
    const discontinuedProduct =
      productValueToInclude && getDiscontinuedProduct(productValueToInclude);
    if (discontinuedProduct) {
      products.push(discontinuedProduct);
    }
    const privateProduct = PRIVATE_PROGRAM_TYPE.filter(
      (product) =>
        product.type !== "EXTERNAL" &&
        (productValueToInclude === product.value ||
          (accessManagement?.productAccess &&
            accessManagement?.productAccess.includes(product.id)) ||
          currentAuthRole === UserRole.providerAdmin)
    );
    if (privateProduct.length > 0) {
      privateProduct.forEach((product) => {
        products.push({
          value: product.value || product.id, // Internal product will have value anyway
          text: product.text,
        });
      });
    }
    return products.filter((i) =>
      filterSungageProducts ? !isSungageLoanProduct(Number(i.value)) : true
    );
  }

  if (
    currentAuthRole !== UserRole.providerAdmin &&
    !accessManagement?.productAccess
  ) {
    // Product Access is not recorded
    return visibleProducts;
  }
  if (currentAuthRole === UserRole.providerAdmin) {
    // All product access to Provider admin
    return [
      ...visibleProducts,
      ...PRIVATE_PROGRAM_TYPE.map((privateProduct) => ({
        value: privateProduct.value || privateProduct.id,
        text: privateProduct.text,
      })),
    ].filter((i) =>
      filterSungageProducts ? !isSungageLoanProduct(Number(i.value)) : true
    );
  }
  if (
    currentAuthRole !== UserRole.providerAdmin &&
    accessManagement?.productAccess &&
    accessManagement?.productAccess.length > 0
  ) {
    const filteredAPrivateProducts = PRIVATE_PROGRAM_TYPE.filter((product) =>
      accessManagement?.productAccess.includes(product.id)
    );
    return [
      ...visibleProducts,
      ...filteredAPrivateProducts.map((privateProduct) => ({
        value: privateProduct.value || privateProduct.id,
        text: privateProduct.text,
      })),
    ].filter((i) =>
      filterSungageProducts ? !isSungageLoanProduct(Number(i.value)) : true
    );
  }
  return visibleProducts;
};

export const getInternalProductsList = (
  currentAuthRole: string | undefined,
  accessManagement: AccessManagement | null | undefined,
  isPrequalForm: boolean
): ProductMenuType => {
  const visibleProducts = PROGRAM_TYPE.filter(({ visible }) => visible);

  if (
    currentAuthRole !== UserRole.providerAdmin &&
    !accessManagement?.productAccess
  ) {
    // Product Access is not recorded
    return visibleProducts;
  }
  if (currentAuthRole === UserRole.providerAdmin) {
    // All product access to Provider admin
    return [
      ...visibleProducts,
      ...PRIVATE_PROGRAM_TYPE.filter((product) => product.type !== "EXTERNAL")
        .map((privateProduct) => ({
          value: privateProduct.value ?? "", // Internal product will have value anyway
          text: privateProduct.text,
        }))
        .filter((i) =>
          isPrequalForm ? !isSungageLoanProduct(Number(i.value)) : true
        ),
    ];
  }
  if (
    currentAuthRole !== UserRole.providerAdmin &&
    accessManagement?.productAccess &&
    accessManagement?.productAccess.length > 0
  ) {
    const filteredAPrivateProducts = PRIVATE_PROGRAM_TYPE.filter(
      (product) =>
        accessManagement?.productAccess.includes(product.id) &&
        product.type !== "EXTERNAL"
    );
    return [
      ...visibleProducts,
      ...filteredAPrivateProducts.map((privateProduct) => ({
        value: privateProduct.value ?? "", // Internal product will have value anyway
        text: privateProduct.text,
      })),
    ].filter((i) =>
      isPrequalForm ? !isSungageLoanProduct(Number(i.value)) : true
    );
  }
  return visibleProducts;
};

export const getThemeForReactSelect = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: themeVariables.primary,
    primary75: themeVariables.primaryDark,
    primary50: themeVariables.primaryLight,
    primary25: themeVariables.primaryLight,
  },
});

export const getIncomeInsightValue = (
  value: number | undefined
): number | undefined => {
  if (!value) {
    return undefined;
  }
  return (value * 1000) / 12;
};

// export const showNoticeToProceedTab = (
//   requestDetail: IPortalApplication | undefined
// ): boolean => {
//   if (
//     !requestDetail ||
//     !requestDetail?.status?.applicationStatus ||
//     !requestDetail?.status?.decisionStatus
//   ) {
//     return false;
//   }

//   return (
//     [
//       PortalApplicationStatus.Application,
//       PortalApplicationStatus.WaitingOnDocs,
//     ].includes(requestDetail?.status?.applicationStatus) &&
//     [PortalDecisionStatus.Approved, PortalDecisionStatus.Prequalified].includes(
//       requestDetail?.status?.decisionStatus
//     )
//   );
//   /**
//    * Note:
//    * We are missing the following DL4 application status for enabling NTP tab: Underwriting, Processing,Final_Approved, Rescission, Incomplete, Funding, Unable_To_Fund, No_Action_Needed
//    * We are missing the following DL4 decision status for enabling NTP tab: Conditional Approval, Conditional Counter Offer, Counter Offered
//    */
//   // return (
//   //   (valueBetween(requestDetail?.applicationStatus?.value, 1, 8) ||
//   //     valueBetween(requestDetail?.applicationStatus?.value, 10, 12) ||
//   //     requestDetail?.applicationStatus?.value === 14) &&
//   //   valueBetween(requestDetail?.decisionStatus?.value, 100, 120)
//   // );
// };

// export const enableFundingTab = (
//   requestDetail: IPortalApplication | undefined
// ): boolean => {
//   return (
//     !!requestDetail &&
//     (requestDetail?.status?.applicationStatus ===
//       PortalApplicationStatus.NTPIssued ||
//       requestDetail?.status?.applicationStatus ===
//         PortalApplicationStatus.RFFReview ||
//       requestDetail?.status?.applicationStatus ===
//         PortalApplicationStatus.InstallReady)
//   );

//   // return (
//   //   !!requestDetail &&
//   //   (requestDetail?.applicationStatus?.value === 17 ||
//   //     requestDetail?.applicationStatus?.value === 15 ||
//   //     requestDetail?.applicationStatus?.value === 30)
//   // ); // Ntp Issued OR RFF Review
// };

// export const enableLoanTab = (
//   requestDetail: IPortalApplication | undefined
// ): boolean => {
//   return !isPrequalApplication(requestDetail);
// };

// export const enableInspectionsTab = (
//   requestDetail: IPortalApplication | undefined
// ): boolean => {
//   return (
//     !!requestDetail &&
//     (requestDetail?.status?.applicationStatus ===
//       PortalApplicationStatus.Funded ||
//       requestDetail?.status?.applicationStatus ===
//         PortalApplicationStatus.PTOReview)
//   );
//   // return (
//   //   !!requestDetail &&
//   //   (requestDetail?.applicationStatus?.value === 9 ||
//   //     requestDetail?.applicationStatus?.value === 21)
//   // ); // Funded OR PTO Review
// };

// export const enableIssueLoanDocs = (
//   application: IPortalApplication,
//   userRole: UserRole | null | undefined
// ): boolean => {
//   return (
//     !isNonModifiable(application, userRole) &&
//     isApproved(application?.status?.decisionStatus) &&
//     !isPrequalApplication(application)
//   );
// };

export const getDropdownValueForIssueDoc = (
  data: IIssueDocType[]
): Array<{
  text: string;
  value: number;
}> => {
  return (
    data?.map((x) => ({
      text: x.label,
      ...x,
    })) || []
  );
};

export const hasAccessToClientCredentials = (role: UserRole): boolean =>
  CLIENT_CREDENTIALS_ACCESS_ROLES.includes(role);

export const getPortalApplicationStatusLabel = (
  portalApplicationStatus: PortalApplicationStatus | undefined
): string =>
  portalApplicationStatus
    ? PortalApplicationStatusLabel[portalApplicationStatus]
    : "";
