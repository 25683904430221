import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { networkService } from "@services/network-service";
import { RootState } from "@store/store";
import { RouteComponentProps } from "react-router-dom";
import { toasterService } from "@services/toaster.service";
import { MESSAGES } from "@shared/constants/app.constant";
import { setProcessing } from "./app.slice";
import { IPrequalUpdate, loanService } from "@services/loan.service";
import { createUrl } from "@shared/helpers/global.helper";
import {
  IStipulation,
  IModifyLoanPayload,
  IXPrequalProductsTypeValues,
} from "@shared/interfaces/Application";
import { downloadDocumentBlob } from "@shared/helpers/documents.helper";
import { RequestStatus } from "@shared/enums/RequestType";
import {
  IEmailApplicationRequestPayload,
  IModifyApplicantFullApplicationPortal,
  IModifyPortalFullApplication,
  IPortalApplication,
  PortalApplicationStatus,
  PortalModifyApplicationResponseType,
  PortalSubmitApplicationResponseType,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import { AxiosResponse } from "axios";

export interface IIssueDocType {
  label: string;
  value: number;
}

export type ApplicationErrorType = {
  type?: RequestStatus;
  message?: string;
  requestDetail?: IPortalApplication;
};

export interface IPipelineApplicationResponse {
  applicationNumber: string; // appNumber: string
  applicationStatus: PortalApplicationStatus; // applicationStatus obj
  decisionStatus: number; // decisionStatus obj
  decisionStatusString: string; // decisionStatus obj
  name: string[]; // primaryApplicant obj
  requestedAmount: number; // response?.loanInformation?.currentLoanVersion?.amountFinanced
  stipulations: IStipulation[]; // appStipulations
  createdOn: number;
  inReviewWithSublender: boolean; // Set by Flip portal backend
}
export interface IPipelineData {
  total: number;
  results: IPipelineApplicationResponse[];
  newApplication: Array<any>;
  newSubmittedApplication: Array<any>;
}

const initialState: any = {
  request: null, // as Partial<IPortalApplication>,
  pipelineData: {} as Partial<IPipelineData>,
  documents: [] as any[],
  error: {} as ApplicationErrorType | null,
  requestDraft: null,
  draftDetail: null,
  IsDraftValidated: false,
  IsDocumentUploading: false,
  isDocumentDownloading: false,
  duplicateApps: [] as any[],
  currentApplicationSubmissionData: undefined,
  isMultiPrequal: false,
};

export interface DownloadDocumentPayload {
  appId: string;
  documentIds: string[];
  case: "view" | "download";
}

export const postRequestAction = createAsyncThunk(
  "loan/postRequest",
  async (
    {
      model,
      history,
      successUrl,
      force,
      isMultiPrequal,
      xPrequalProducts,
      programTypeCode,
    }: {
      model: any;
      successUrl: string;
      history: RouteComponentProps["history"];
      force?: boolean;
      isMultiPrequal?: boolean;
      xPrequalProducts?: IXPrequalProductsTypeValues[];
      programTypeCode: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      dispatch(setDuplicateApps([]));

      const response = await networkService.post<
        any,
        AxiosResponse<PortalSubmitApplicationResponseType>
      >(`/application${force ? "?force=1" : ""}`, model);

      // There are duplicates of the submission
      if (
        response.data?.data?.duplicates &&
        response.data?.data?.duplicates?.length > 0
      ) {
        dispatch(
          setDuplicateAppsandCurrentAppSubmissionData({
            duplicateApps: response.data.data.duplicates,
            currentApplicationSubmissionData: {
              model,
              successUrl,
              xPrequalProducts: xPrequalProducts ?? undefined,
              isMultiPrequal,
              programTypeCode,
            },
          })
        );
        dispatch(setProcessing(false));
        return;
      }

      if (response.data?.data?.losAppNumber) {
        const actionData = await loanService.validateRequestStatus(
          response.data?.data?.losAppNumber
        );

        // Apply for loans if multi-prequal selected
        if (!actionData?.data?.requestDetail) {
          console.error("Not able to get Application data");
          dispatch(setProcessing(false));
          actionData?.data && dispatch(setError(actionData?.data));
          history.push(createUrl("/request-failed"));
          return;
        }
        if (actionData?.decision === "approved") {
          history.push(
            createUrl(`${successUrl}/${response.data?.data?.losAppNumber}`)
          );
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else if (
          actionData?.decision === "declined" &&
          isMultiPrequal &&
          xPrequalProducts
        ) {
          // Apply for Multi Prequal
          dispatch(
            updatePrequalForMultiPrequalAction({
              appNumber: response.data?.data?.losAppNumber,
              history,
              loanInfo: {
                losAppNumber: response.data?.data?.losAppNumber,
                prequalProducts: xPrequalProducts,
                productLos: ProductLos.DL4,
                programTypeCode,
              },
              // loanInfo: generateLoanObjForMultiPrequal(
              //   actionData?.data?.requestDetail,
              //   {
              //     xPrequalProducts,
              //   }
              // ),
              path: successUrl,
              isMultiPrequal,
            })
          );
          return;
        } else {
          dispatch(setProcessing(false));
          dispatch(setError(actionData?.data));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(MESSAGES.REQUEST_FAILED);
      }

      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const prequalModifyLoanAction = createAsyncThunk(
  "loan/prequalModifyLoanAction",
  async (
    {
      appNumber,
      loanInfo,
      path,
      isMultiPrequal,
      xPrequalProducts,
      history,
      xprogramtype,
    }: {
      loanInfo: IPrequalUpdate;
      appNumber: string;
      path: string;
      isMultiPrequal?: boolean;
      xPrequalProducts?: IXPrequalProductsTypeValues[];
      history: RouteComponentProps["history"];
      xprogramtype: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let isFailed = false;
      dispatch(setProcessing(true));

      const updatePrequalResponse =
        await loanService.updatePrequalForMultiPrequal(appNumber, loanInfo);

      if (!updatePrequalResponse) {
        isFailed = true;
      }

      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (!actionData?.data?.requestDetail) {
          console.error("Not able to get Application data");
          return;
        }
        if (actionData?.decision === "approved") {
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else if (
          actionData?.decision === "declined" &&
          isMultiPrequal &&
          xPrequalProducts
        ) {
          // Apply for Multi Prequal
          dispatch(
            updatePrequalForMultiPrequalAction({
              appNumber,
              history,
              loanInfo: {
                losAppNumber: appNumber,
                programTypeCode: xprogramtype,
                productLos: ProductLos.DL4,
                prequalProducts: xPrequalProducts,
              },
              // loanInfo: generateLoanObjForMultiPrequal(
              //   actionData?.data?.requestDetail,
              //   {
              //     xPrequalProducts,
              //     xprogramtype,
              //   }
              // ),
              path: path,
              isMultiPrequal,
            })
          );
          return;
        } else {
          dispatch(setProcessing(false));
          dispatch(setError(actionData?.data));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(MESSAGES.REQUEST_FAILED);
      }

      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const updatePrequalForMultiPrequalAction = createAsyncThunk(
  "loan/updatePrequal",
  async (
    {
      loanInfo,
      appNumber,
      history,
      path,
      isMultiPrequal,
    }: {
      loanInfo: IPrequalUpdate;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
      isMultiPrequal: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      let isFailed = false;
      let message;

      if (!isFailed && loanInfo) {
        const updatePrequalResponse =
          await loanService.updatePrequalForMultiPrequal(appNumber, loanInfo);

        if (!updatePrequalResponse) {
          isFailed = true;
          message = MESSAGES.DEFAULT_ERROR;
        }
      }
      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        dispatch(setIsMultiPrequal(isMultiPrequal));
        if (actionData?.decision === "approved") {
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

/**
 * The convertRequestAction is deprecated. Currently, utilizing the switchPrequalToFullApp of loan.slice.ts
 */
/**
 * 
export const convertRequestAction = createAsyncThunk(
  "loan/convertRequest",
  async (
    {
      primaryApplicant,
      coApplicant,
      loanInfo,
      appNumber,
      history,
      path,
      isEditOfCoborrower,
    }: {
      primaryApplicant: any;
      coApplicant: any;
      loanInfo: any;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
      isEditOfCoborrower: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      let isFailed = false,
        message;
      const response1: any = await networkService.put(
        `/application/${appNumber}/applicants`,
        primaryApplicant,
        {
          params: {
            isEdit: 1,
          },
        }
      );
      if (response1.data?.data?.response?.taskStatusLabel === "Failed") {
        isFailed = true;
        message =
          response1?.data?.response?.ex || response1.data?.data?.response?.ex;
      }
      if (!isFailed && coApplicant) {
        const response2: any = await networkService.put(
          `/application/${appNumber}/applicants`,
          coApplicant,
          {
            params: {
              isEdit: isEditOfCoborrower ? 1 : undefined,
            },
          }
        );
        if (response2.data?.data?.response?.taskStatusLabel === "Failed") {
          isFailed = true;
          message =
            response2?.data?.response?.ex || response2.data?.data?.response?.ex;
        }
      }
      if (!isFailed && loanInfo) {
        const response3 = await networkService.put(
          `/application/${appNumber}/modify-loan`,
          loanInfo
        );
        if (!response3.data?.data?.success) {
          isFailed = true;
          message = response3.data?.data?.message;
        }
      }
      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (actionData?.decision === "approved") {
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);
 *
 */

export const switchPrequalToFullApp = createAsyncThunk(
  "loan/switchPrequalToFullApp",
  async (
    {
      loanInfo,
      appNumber,
      prequalifiedOfferId,
      history,
      path,
      isEditOfCoborrower,
      isInstallationAddressUpdated,
    }: {
      loanInfo: any;
      appNumber: string;
      prequalifiedOfferId: number;
      path: string;
      history: RouteComponentProps["history"];
      isEditOfCoborrower: boolean;
      isInstallationAddressUpdated: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      let isFailed = false,
        message;

      const switchPrequalToFullAppResponse =
        await loanService.switchPrequalToFullApp(
          appNumber,
          prequalifiedOfferId,
          loanInfo,
          isInstallationAddressUpdated
        );

      if (!switchPrequalToFullAppResponse) {
        isFailed = true;
        message = MESSAGES.DEFAULT_ERROR;
      }

      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (actionData?.decision === "approved") {
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const resubmitApplicationAction = createAsyncThunk(
  "loan/resubmitApplication",
  async (
    {
      loanInfo,
      appNumber,
      history,
      path,
    }: {
      loanInfo: IModifyLoanPayload;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      let isFailed = false;
      let message: string = "";
      const response = await networkService.put<any>(
        `/application/${appNumber}/modify-loan`,
        loanInfo,
        {
          params: {
            isInstallationAddressUpdated: 1,
          },
        }
      );
      if (!response.data?.data?.success) {
        isFailed = true;
        message = response.data?.data?.message;
      }

      if (!isFailed) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (actionData?.decision === "approved") {
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

export const addApplicantAction = createAsyncThunk(
  "loan/addApplicant",
  async (
    {
      model,
      loanInfo,
      appNumber,
      history,
      path,
      isEdit,
    }: {
      model: any;
      loanInfo: any;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
      isEdit: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      const response1: any = await networkService.post(
        `/application/${appNumber}/applicants`,
        model,
        {
          params: {
            isEdit: !!isEdit ? 1 : undefined,
          },
        }
      );
      let isFailed = false,
        message;
      if (response1.data?.data?.response?.taskStatusLabel === "Failed") {
        isFailed = true;
        message =
          response1?.data?.response?.ex || response1.data?.data?.response?.ex;
      }

      if (!isFailed) {
        const response2 = await networkService.put(
          `/application/${appNumber}/modify-loan`,
          loanInfo,
          {
            params: {
              isInstallationAddressUpdated: 0,
            },
          }
        );
        if (!response2.data?.data?.success) {
          isFailed = true;
          message = response2.data?.data?.message;
        }
      }
      if (!isFailed) {
        const requestStatus = await loanService.processRequest(response1);
        if (!requestStatus) {
          const actionData = await loanService.validateRequestStatus(appNumber);
          if (actionData?.decision === "approved") {
            history.push(createUrl(`${path}/${appNumber}`));
          } else if (actionData?.decision === "pending") {
            dispatch(setError(actionData?.data));
            history.push(createUrl(`/no-decision`));
          } else {
            dispatch(setError(actionData?.data || null));
            history.push(createUrl("/request-failed"));
          }
        } else {
          toasterService.error(MESSAGES.REQUEST_FAILED);
        }
      } else {
        toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);

// Add or Modify Applicants Info
export const editApplicantAction = createAsyncThunk(
  "loan/modifyApplicant",
  async (
    {
      model,
      appNumber,
      history,
      path,
      isInstallationAddressUpdated,
    }: {
      model: IModifyApplicantFullApplicationPortal;
      appNumber: string;
      path: string;
      history: RouteComponentProps["history"];
      isInstallationAddressUpdated: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      const response = await networkService.put<
        IModifyApplicantFullApplicationPortal,
        AxiosResponse<PortalModifyApplicationResponseType>
      >(`/application/${appNumber}/modify-applicant`, model, {
        params: {
          ...(isInstallationAddressUpdated !== undefined && {
            isInstallationAddressUpdated: isInstallationAddressUpdated ? 1 : 0,
          }),
        },
      });

      if (response?.data?.data?.success) {
        const actionData = await loanService.validateRequestStatus(appNumber);
        if (actionData?.decision === "approved") {
          history.push(createUrl(`${path}/${appNumber}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        const messge = response?.data?.message;
        toasterService.error(messge ? messge : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
    // try {
    //   dispatch(setProcessing(true));
    //   const response1: any = await networkService.put(
    //     `/application/${appNumber}/applicants`,
    //     model,
    //     {
    //       params: {
    //         isEdit: 1,
    //       },
    //     }
    //   );
    //   let isFailed = false,
    //     message;
    //   if (response1.data?.data?.response?.taskStatusLabel === "Failed") {
    //     isFailed = true;
    //     message =
    //       response1?.data?.response?.ex || response1.data?.data?.response?.ex;
    //   }

    //   if (!isFailed) {
    //     const response2 = await networkService.put(
    //       `/application/${appNumber}/modify-loan`,
    //       loanInfo,
    //       {
    //         params: {
    //           ...(isInstallationAddressUpdated !== undefined && {
    //             isInstallationAddressUpdated: isInstallationAddressUpdated
    //               ? 1
    //               : 0,
    //           }),
    //         },
    //       }
    //     );
    //     if (!response2.data?.data?.success) {
    //       isFailed = true;
    //       message = response2.data?.data?.message;
    //     }
    //   }

    //   if (!isFailed) {
    //     const requestStatus = await loanService.processRequest(response1);
    //     if (!requestStatus) {
    //       const actionData = await loanService.validateRequestStatus(appNumber);
    //       if (actionData?.decision === "approved") {
    //         history.push(createUrl(`${path}/${appNumber}`));
    //       } else if (actionData?.decision === "pending") {
    //         dispatch(setError(actionData?.data));
    //         history.push(createUrl(`/no-decision`));
    //       } else {
    //         dispatch(setError(actionData?.data || null));
    //         history.push(createUrl("/request-failed"));
    //       }
    //     } else {
    //       toasterService.error(MESSAGES.REQUEST_FAILED);
    //     }
    //   } else {
    //     toasterService.error(message ? message : MESSAGES.REQUEST_FAILED);
    //   }

    //   dispatch(setProcessing(false));
    // } catch (error) {
    //   dispatch(setProcessing(false));
    //   return rejectWithValue(error);
    // }
  }
);

//rehashv2 endpoint
export const modifyLoanAction = createAsyncThunk(
  "loan/modifyLoan",
  async (
    {
      model,
      history,
      id,
      path,
      isInstallationAddressUpdated,
    }: {
      model: IModifyPortalFullApplication;
      history: RouteComponentProps["history"];
      id: string;
      path: string;
      isInstallationAddressUpdated?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProcessing(true));
      const response = await networkService.put<
        IModifyPortalFullApplication,
        AxiosResponse<PortalModifyApplicationResponseType>
      >(`/application/${id}/loan`, model, {
        params: {
          ...(isInstallationAddressUpdated !== undefined && {
            isInstallationAddressUpdated: isInstallationAddressUpdated ? 1 : 0,
          }),
        },
      });

      if (response?.data?.data?.success) {
        const actionData = await loanService.validateRequestStatus(id);
        if (actionData?.decision === "approved") {
          history.push(createUrl(`${path}/${id}`));
        } else if (actionData?.decision === "pending") {
          dispatch(setError(actionData?.data));
          history.push(createUrl(`/no-decision`));
        } else {
          dispatch(setError(actionData?.data || null));
          history.push(createUrl("/request-failed"));
        }
      } else {
        const messge = response?.data?.message;
        toasterService.error(messge ? messge : MESSAGES.REQUEST_FAILED);
      }
      dispatch(setProcessing(false));
    } catch (error) {
      dispatch(setProcessing(false));
      return rejectWithValue(error);
    }
  }
);
export const getRequestAction = createAsyncThunk(
  "loan/getRequest",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await loanService.getRequestById(id);
      dispatch(setRequestData(response?.data?.data));
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadDocumentAction = createAsyncThunk(
  "loan/downloadDocumentAction",
  async (payload: DownloadDocumentPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setIsDocumentDownloading(true));

      const promises = await Promise.all(
        payload.documentIds.map((documentId) =>
          loanService.downloadDocument(payload.appId, documentId)
        )
      );

      promises.forEach(({ data, headers, config }) =>
        downloadDocumentBlob(data, headers, config, payload)
      );
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    } finally {
      dispatch(setIsDocumentDownloading(false));
    }
  }
);

export const emailLinkAction = createAsyncThunk(
  "loan/emailLink",
  async (
    {
      model,
      history,
      type,
    }: {
      model: IEmailApplicationRequestPayload;
      type: string;
      history: RouteComponentProps["history"];
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await networkService.post(
        `/application/email-application`,
        model
      );
      history.push(`/email-link/success/${type}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPipelinesAction = createAsyncThunk(
  "loan/getPipeline",
  async (
    {
      page,
      limit,
      searchCriteria,
      search,
      tab,
    }: {
      page: number;
      limit: any;
      searchCriteria?: string;
      search?: string;
      tab?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response: any = await networkService.get(`/application/v2`, {
        params: {
          page,
          maxApps: limit,
          searchCriteria: !!searchCriteria ? searchCriteria : undefined,
          search: !!search ? search : undefined,
          tab: !!tab ? tab : undefined,
        },
      });
      dispatch(setPipelineData(response?.data?.data));
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    resetLoanData(state) {
      Object.assign(state, initialState);
    },
    setRequestData: (
      state: any,
      { payload }: PayloadAction<IPortalApplication | null>
    ) => {
      state.request = payload;
    },
    setIsMultiPrequal: (state: any, { payload }: PayloadAction<boolean>) => {
      state.isMultiPrequal = payload;
    },
    setError: (
      state: any,
      { payload }: PayloadAction<ApplicationErrorType | null>
    ) => {
      state.error = payload;
    },
    setDocumentDetail: (state: any, { payload }: PayloadAction<any>) => {
      state.documents = payload;
    },
    setIsDocumentUploading: (state: any, { payload }: PayloadAction<any>) => {
      state.IsDocumentUploading = payload;
    },
    setIsDocumentDownloading: (
      state: any,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDocumentDownloading = payload;
    },
    setRequestDraft: (state: any, { payload }: PayloadAction<any>) => {
      state.requestDraft = payload;
    },
    setIsDraftValidated: (state: any, { payload }: PayloadAction<any>) => {
      state.IsDraftValidated = payload;
    },
    setDraftDetail: (state: any, { payload }: PayloadAction<any>) => {
      state.draftDetail = payload;
    },
    setPipelineData: (state: any, { payload }: PayloadAction<any>) => {
      state.pipelineData = payload;
    },
    setDuplicateAppsandCurrentAppSubmissionData: (
      state: any,
      {
        payload,
      }: PayloadAction<{
        duplicateApps: any[];
        currentApplicationSubmissionData:
          | {
              model: object;
              successUrl: string;
              xPrequalProducts?: IXPrequalProductsTypeValues[];
              isMultiPrequal?: boolean;
              programTypeCode: number;
            }
          | undefined;
      }>
    ) => {
      state.duplicateApps = payload.duplicateApps;
      state.currentApplicationSubmissionData =
        payload.currentApplicationSubmissionData;
    },
    setDuplicateApps: (state: any, { payload }: PayloadAction<any[]>) => {
      state.duplicateApps = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { reducer: loanReducer } = loanSlice;
export const {
  setRequestData,
  setDraftDetail,
  setPipelineData,
  setRequestDraft,
  setIsDraftValidated,
  setIsDocumentUploading,
  setIsDocumentDownloading,
  setError,
  setDocumentDetail,
  setDuplicateApps,
  setDuplicateAppsandCurrentAppSubmissionData,
  resetLoanData,
  setIsMultiPrequal,
} = loanSlice.actions;

export const loanRequestSelector = (
  rootState: RootState
): Partial<IPortalApplication> => rootState.loan.request;
export const pipelineDataSelector = (
  rootState: RootState
): Partial<IPipelineData> => rootState.loan.pipelineData;
export const loanOfferelector = (rootState: RootState) =>
  rootState.loan.loanOffer;
export const isMultiPrequalSelector = (rootState: RootState): boolean =>
  rootState.loan.isMultiPrequal;
export const errorDetailSelector = (
  rootState: RootState
): ApplicationErrorType | null => rootState.loan.error;
export const documentsSelector = (rootState: RootState) =>
  rootState.loan.documents;
export const loanSliceSelector = (rootState: RootState) => rootState.loan;
export const documentDownloadingSelector = (rootState: RootState): boolean =>
  rootState.loan.isDocumentDownloading;
export const documentUploadingSelector = (rootState: RootState): boolean =>
  rootState.loan.IsDocumentUploading;
