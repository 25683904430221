import { Redirect } from "react-router-dom";

import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

/**
 * This component redirects the user to the Application details page
 * if an Application is restricted from Issue loan docs
 */
const IssueLoanDocsGuard = ({
  application,
}: {
  application?: IPortalApplication | null;
}) => {
  if (!!application && !!!application?.portalAccessibility?.issueLoanDocs) {
    return <Redirect to={`/request-detail/${application.losAppNumber}`} />;
  }
  return <></>;
};

export default IssueLoanDocsGuard;
