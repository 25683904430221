import { MESSAGES } from "@shared/constants/app.constant";
import { useAppSelector } from "@store/hooks";
import { sungageDisclosuresSelector } from "@store/slices/app.slice";

interface IIncomePromptProps {
  isSungageProduct: boolean;
  isPrequal?: boolean;
}

const IncomePrompt = ({
  isSungageProduct,
  isPrequal = false,
}: IIncomePromptProps) => {
  const sungageDisclosures = useAppSelector(sungageDisclosuresSelector);

  return (
    <p className="subdetails">
      {isSungageProduct
        ? sungageDisclosures?.incomePrompt || ""
        : isPrequal
        ? MESSAGES.DL4_PREQUAL_INCOME_PROMPT
        : MESSAGES.DL4_INCOME_PROMPT}
    </p>
  );
};

export default IncomePrompt;
