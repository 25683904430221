import { Redirect } from "react-router-dom";
import {
  IPortalApplication,
  PortalAccessibilityKeys,
} from "@shared/interfaces/PortalApplicationTypes";

/**
 * This component redirects the user to the Pipelines page
 * if an Application is restricted from being modified
 */
const ApplicationGuard = ({
  application,
  useCase,
}: {
  application?: IPortalApplication | null;
  useCase: PortalAccessibilityKeys;
}) => {
  if (!!application && !!!application?.portalAccessibility?.[useCase]) {
    return <Redirect to="/pipelines" />;
  }
  return <></>;
};

export default ApplicationGuard;
